import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import classes from '../Business/Business.module.scss';
import clsx from 'clsx';
import { DISABLE_TRANSLATION } from 'constants/languages';
import { Type } from 'components/shared/Type';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'contexts/UserContext';
import { BusinessUnit } from '@type/user';

interface BusinessUnitsProps {}

export const BusinessUnits = ({}: BusinessUnitsProps) => {
  const { businessUnits } = useUserStore();
  const { t } = useTranslation();

  return (
    <>
      <TitleAndDescription pageName="Account/business-units" />

      <div className={clsx(classes.wrapper)} translate={DISABLE_TRANSLATION}>
        <div className={classes.header}>
          <div className={classes.helperInfo}>
            <div className={classes.titleWrap}>
              <Type tag={'h2'} className={classes.title}>
                {t('Business-units')}
              </Type>
            </div>

            <p className={classes['info']}>
              {t(
                'List of business units whose Solutions and Projects are accessible'
              )}
            </p>
          </div>
        </div>
        <div className={classes.bUnits}>
          {businessUnits?.map((bUnit: BusinessUnit, index) => (
            <div key={index} className={classes.bUnits__unit}>
              <div className={classes.bUnits__unit__imgWrapper}>
                {/*<img
                  src={bUnit.image}
                  alt={bUnit.name}
                  className={classes.bUnits__unit__imgWrapper__item}
                />*/}
              </div>
              <span className={classes.bUnits__unit__name}>{bUnit?.name}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
