import React, { FC, useEffect } from 'react';
import { FinancesContainer } from 'containers/Finances';
import '../styles/main.scss';
import { useNavigate } from 'react-router-dom';
import { PARTNER_ROLE } from 'types/user';
import { userStore } from '../stores/user-store';
import { useUserStore } from '../contexts/UserContext';

// todo добавить функцию локализации
export const Finances: FC = () => {
  const navigate = useNavigate();

  const partnerPermission = userStore?.partners?.results?.[0]?.permission?.type;
  const hasNoPermission = userStore?.partners?.role === null;
  const { isSupervisor } = useUserStore();

  const isManager =
    partnerPermission === PARTNER_ROLE.MANAGER ||
    partnerPermission === PARTNER_ROLE.VIEWER;

  useEffect(() => {
    if (isManager || hasNoPermission || isSupervisor) {
      navigate('/solutions');
    }
  }, []);
  return <FinancesContainer />;
};
