import React, { FC } from 'react';
import classes from './LinksPolitic.module.scss';
import { useTranslation } from 'react-i18next';
import { defaultLanguage } from 'i18n/i18n';
import { RUSSIAN_LOCALE } from 'utils';
import { useUserStore } from '../../../contexts/UserContext';
import clsx from 'clsx';

interface LinksPoliticProps {
  isAuth?: boolean;
}

export const LinksPolitic: FC<LinksPoliticProps> = ({ isAuth }) => {
  const { t, i18n } = useTranslation();
  const { isSupervisor } = useUserStore();

  const helpLink = isSupervisor
    ? process.env.REACT_APP_HELP_SUPERVISOR_URL
    : process.env.REACT_APP_HELP_PARTNER_URL;

  return (
    <ul
      className={clsx(
        classes['politic-links'],
        RUSSIAN_LOCALE && classes['politic-links__ru']
      )}
    >
      <li>
        <a
          href={
            RUSSIAN_LOCALE
              ? process.env.REACT_APP_RU_PARTNER_OFFER_URL || ''
              : `https://kit.global/${
                  i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
                }terms/`
          }
          target="_blank"
          className={clsx(
            classes['politic-links__link'],
            isAuth && classes['politic-links__link__auth']
          )}
          rel="noreferrer"
        >
          {t('Terms of service')}
        </a>
      </li>
      {RUSSIAN_LOCALE && (
        <li>
          <a
            href={
              RUSSIAN_LOCALE
                ? helpLink || ''
                : `https://kit.global/${
                    i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
                  }help/`
            }
            target="_blank"
            className={clsx(
              classes['politic-links__link'],
              isAuth && classes['politic-links__link__auth']
            )}
            rel="noreferrer"
          >
            {t('Help')}
          </a>
        </li>
      )}
      <li>
        <a
          href={
            RUSSIAN_LOCALE
              ? process.env.REACT_APP_RU_COOKIES_CONCENT_URL || ''
              : `https://kit.global/${
                  i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
                }cookie-policy/`
          }
          target="_blank"
          className={clsx(
            classes['politic-links__link'],
            isAuth && classes['politic-links__link__auth']
          )}
          rel="noreferrer"
        >
          {t('Cookie Policy')}
        </a>
      </li>
      <li>
        <a
          href={
            RUSSIAN_LOCALE
              ? process.env.REACT_APP_PRIVACY_POLICY_URL || ''
              : `https://kit.global/${
                  i18n.language !== defaultLanguage ? `${i18n.language}/` : ''
                }privacy-policy/`
          }
          target="_blank"
          className={clsx(
            classes['politic-links__link'],
            isAuth && classes['politic-links__link__auth']
          )}
          rel="noreferrer"
        >
          {t('Privacy Policy')}
        </a>
      </li>
    </ul>
  );
};
