import { useFormContext } from 'react-hook-form';
import { FormValidationProps } from '../FormBlock.props';
import { usePartnerStore } from 'contexts/PartnerContext';
import { useNavigate } from 'react-router-dom';
import { Status } from 'constants/enum';

export const useFourthStep = (
  setOpenSubmitModal: (val: boolean) => void,
  currentStep: number,
  handleSubmit: (e: Event, serviceToChange?: number) => void
) => {
  const { trigger } = useFormContext<FormValidationProps>();
  const navigate = useNavigate();

  const {
    isCompletedFormSteps,
    serviceToActivate,
    activateService,
    serviceToChange,
    singleService,
  } = usePartnerStore();
  isCompletedFormSteps.stepFour = currentStep === 4;

  const onValidateClick = async () => {
    const scrollToTop = window?.scrollTo({ top: 0, behavior: 'smooth' });
    if (trigger) {
      const isValid = await trigger();

      if (isValid) {
        setOpenSubmitModal(true);
      } else {
        return scrollToTop;
      }
    }
  };

  const handleActivateSolution = async (e: Event, isChange?: boolean) => {
    if (isChange) {
      if (handleSubmit && serviceToChange) {
        if (
          serviceToActivate &&
          singleService?.status?.toLowerCase() === Status.rejected.toLowerCase()
        ) {
          await activateService(serviceToActivate).then(() => {
            navigate('/solutions');
          });
        }
        handleSubmit(e, serviceToChange);
      }
    } else {
      if (serviceToActivate) {
        await activateService(serviceToActivate).then(() => {
          navigate('/solutions');
        });
      }
    }
  };

  return {
    onValidateClick,
    handleActivateSolution,
  };
};
