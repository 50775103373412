import SolutionDetailContainer from 'containers/SolutionDetail';
import { FC } from 'react';
import '../styles/main.scss';
import { NotFoundPage } from './NotFoundPage';
import { usePartnerStore } from '../contexts/PartnerContext';

export const SolutionDetail: FC = () => {
  const { isErrorServices } = usePartnerStore() || {};

  return isErrorServices ? <NotFoundPage /> : <SolutionDetailContainer />;
};
