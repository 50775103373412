import React from 'react';
import classes from '../MenuCell.module.scss';
import { Button } from 'components/shared/Button';
import { ChevronIcon } from 'components/shared/Icons/ChevronIcon';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';

interface HeaderButtonsProps {
  name: string;
  handleCloseModal: () => void;
  handleBackToMenu: () => void;
}

const HeaderButtons = ({
  name,
  handleCloseModal,
  handleBackToMenu,
}: HeaderButtonsProps) => {
  return (
    <div className={classes.supervisor}>
      <Button className={classes.supervisor__btn} onClick={handleBackToMenu}>
        <span className={classes.supervisor__chevronIcon}>
          <ChevronIcon />
        </span>
        <span className={classes.supervisor__title}>{name}</span>
      </Button>
      <span
        className={classes.supervisor__crossIcon}
        onClick={handleCloseModal}
      >
        <CrossIcon />
      </span>
    </div>
  );
};

export default HeaderButtons;
