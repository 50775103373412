import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LABELS } from 'constants/onboarding';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/shared/Button';
import UiIcon from 'components/shared/Icon';
import { Pagination } from 'components/shared/Pagination';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { PartnerCard } from './Card';
import EmptyBlock from './EmptyBlock/EmptyBlock';
import { Filters } from './Filters';
import ModerateBlock from './ModerateBlock/ModerateBlock';
import classes from './PartnerContainer.module.scss';
import { PARTNER_COUNTER, usePartnerContainer } from './usePartnerContainer';
import 'intro.js/introjs.css';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';
import { DISABLE_TRANSLATION } from 'constants/languages';
import MultiSelect from 'components/shared/MultiSelect';
import { multiFieldValues } from '../AddSolution/utils';
import MobileModal from './Mobile';
import { FilterIcon } from '../../components/shared/Icons/FilterIcon';
import { RUSSIAN_LOCALE } from 'utils';
import { AttentionIcon2 } from 'components/shared/Icons/Attention2';
import clsx from 'clsx';
import { userStore } from 'stores/user-store';

export const PartnerContainer = observer(({ title }: { title: string }) => {
  const {
    t,
    services,
    servicesCount,
    clearCopyOfService,
    statusCount,
    filter,
    setFilter,
    isOnModerate,
    cardInfo,
    currentPage,
    changeChunks,
    setCurrentPage,
    onPaginate,
    goToTop,
    isPartnerViewer,
    isSupervisor,
    internalBUnitOptions,
    callbacks,
    selectedBUnits,
    setSelectedBUnits,
    i18n,
  } = usePartnerContainer();

  const isEmpty = services?.length === 0;
  const totalPages = servicesCount / PARTNER_COUNTER;
  const activeCount = statusCount.active;
  const inReviewCount = statusCount.in_review;
  const archivedCount = statusCount.archived;
  const rejectedCount = statusCount.rejected;
  const navigate = useNavigate();

  const { GOT_IT, NEXT, BACK } = LABELS(t);
  const isMobile = useWindowWidth().isMediaTablet;
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);
  const [openMobileFilterBUnits, setOpenMobileFilterBUnits] = useState(false);

  const onboardingPath = isEmpty ? 'EMPTY_SOLUTIONS_P' : 'SOLUTIONS_P';
  const hasCompletedOnboarding = useRef<
    'EMPTY_SOLUTIONS_P' | 'SOLUTIONS_P' | null
  >(null);
  const isButtonDisabled = isOnModerate || isPartnerViewer;

  useEffect(() => {
    if (!isButtonDisabled) {
      findOnboardingSteps(onboardingPath).then((res) => {
        setOnboarding(res);
      });
    }
  }, [onboardingPath, isButtonDisabled]);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current === onboardingPath) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = onboardingPath;
  }, [onboarding?.id, onboardingPath]);

  const buttonTextOptions = {
    onCancel: () => {
      callbacks.onDeleteBunits('all');
    },
    cancel: t('Reset business units'),
    submit: t('Show solutions'),
    onSubmit: () => {},
  };

  
  const hasSolutionsWithoutCrmProduct = services?.some(
    (service) => !(service as any).product_crm_id
  );
  
  const solutionsWithoutCrm = services?.filter(
    (service) => !(service as any).product_crm_id
  );


  const partnerLevel = userStore?.partners?.results?.[0]?.level;
  const isExternal = partnerLevel === 'EXTERNAL';

  return (
    <div className={classes.container} translate={DISABLE_TRANSLATION}>
      {!isMobile && onboarding && !isButtonDisabled ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}

      <TitleAndDescription pageName="Solutions" />

      {RUSSIAN_LOCALE && hasSolutionsWithoutCrmProduct && !isExternal && (
        <div className={classes.attention}>
          <AttentionIcon2 style={{ flexShrink: 0 }} />

          <ul className={classes.attention__list} translate="no">
            <li className={classes.attention__title}>
              {t('You have solutions that are not tied to a CRM Product. This may block ')}
              <span className={classes.lineBreak}>
                {t('this may block the process of transferring client projects to work. Check the solutions on the links:')}
              </span>
            </li>
            <ul className={clsx(classes.attention__links, classes.attention__description)}>
              {solutionsWithoutCrm.map((solution: any) => (
                <li key={solution.id}>
                  <a
                    href={`/solutions/${solution.slug}`}
                    className={classes.attention__link}
                  >
                    {solution.name}
                  </a>
                </li>
              ))}
            </ul>
          </ul>
        </div>
      )}

      <div className={classes.titleContainer}>
        <div className={classes.title}>
          <span>{title}</span>
          <span className={classes.title__counter}>{servicesCount}</span>
        </div>
        {isMobile && isSupervisor && (
          <div
            className={classes.filterIcon}
            onClick={() => setOpenMobileFilterBUnits(true)}
          >
            <FilterIcon />
          </div>
        )}
      </div>

      <div className={classes.filterBlock}>
        <Filters
          all={servicesCount}
          active={activeCount}
          inReview={inReviewCount}
          archived={archivedCount}
          rejected={rejectedCount}
          filter={isEmpty || isOnModerate ? null : filter}
          setFilter={setFilter}
        />

        {isSupervisor ? (
          !isMobile ? (
            <MultiSelect
              selected={multiFieldValues(selectedBUnits, [], i18n, false)}
              options={internalBUnitOptions}
              isSolutionForm
              title={
                multiFieldValues(selectedBUnits, [], i18n, false)?.length
                  ? ''
                  : t('All business-units')
              }
              placeholder=""
              additionalClassName={classes.multiselectWrapper}
              onSelect={(id) => callbacks.onSelectBUnits(id)}
              onDeleteSelected={(id) => callbacks.onDeleteBunits(id)}
              contentHeight="50px"
              contentWidth={isMobile ? '103%' : 'unset'}
              mainInputClassName={classes.multiselectWrapper__input}
              isSearch
              hideSearchInput
              dropdownClassName={classes.multiselectWrapper__dropdown}
              basicRowValue
            />
          ) : null
        ) : (
          <Button
            disabled={isOnModerate || isPartnerViewer}
            theme="primary"
            className={classes.mainButton}
            onClick={() => {
              clearCopyOfService();
              navigate('/solutions/add-solution/');
            }}
          >
            {(isEmpty || isOnModerate) && (
              <UiIcon name="PlusIcon" additionalClassName={classes.plusIcon} />
            )}
            {t('Add solution')}
          </Button>
        )}
      </div>

      <ul className={classes.cardBlock}>
        {isOnModerate ? (
          <ModerateBlock />
        ) : isEmpty ? (
          <EmptyBlock />
        ) : (
          cardInfo?.map((card: any, index: number) => (
            <PartnerCard
              cardHit={index === 0 ? 'data-step-solutions_p-0' : ''}
              key={index}
              solution={card}
            />
          ))
        )}

        {!isOnModerate && !isEmpty && (
          <div className={classes.pagination}>
            {statusCount.current_filter > PARTNER_COUNTER && (
              <Pagination
                currentPage={currentPage}
                totalPages={changeChunks()?.length}
                setPageNumber={(page: number) => {
                  setCurrentPage(page);
                  onPaginate(page);
                  goToTop();
                }}
                nextPage={() =>
                  currentPage > 0 &&
                  currentPage < totalPages &&
                  setCurrentPage(currentPage + 1)
                }
                prevPage={() =>
                  currentPage !== 0 && setCurrentPage(currentPage - 1)
                }
              />
            )}
          </div>
        )}
      </ul>
      {openMobileFilterBUnits && (
        <MobileModal
          title={t('Filters')}
          subtitle={t('Business-unit')}
          open={openMobileFilterBUnits}
          setOpen={setOpenMobileFilterBUnits}
          selected={multiFieldValues(selectedBUnits, [], i18n, false)}
          data={internalBUnitOptions}
          onMultiSelect={(id) => callbacks.onSelectBUnits(id as any)}
          onMultiDelete={(id) => callbacks.onDeleteBunits(id as any)}
          isMultiSelect
          buttonTextOptions={buttonTextOptions}
          isReversedBtns
        />
      )}
    </div>
  );
});
