import { TFunction, i18n } from 'i18next';
import { MultiSelectProps } from './FormBlock/FirstStep/FirsStep.props';
import { MutableRefObject } from 'react';
import { getLangName } from 'tools/utils';

export const onSelect = (
  id: number | string,
  options: any,
  setSelect: React.Dispatch<React.SetStateAction<MultiSelectProps[]>>,
  onChange?: any
) => {
  const selectedElement = options?.find((product: any) => product.value === id);

  if (id === 'all') {
    setSelect(
      options.map((item: any) => ({
        id: item.value || item.id,
        name: item.label || item.name,
      }))
    );

    if (onChange) {
      onChange(
        options.map((item: any) => ({
          id: item.value || item.id,
          name: item.label || item.name,
        }))
      );
    }
    return;
  }

  if (selectedElement) {
    setSelect((prevSelected: any) => {
      if (onChange) {
        onChange(
          [...prevSelected, selectedElement].map((item: any) => ({
            id: item.value || item.id,
            name: item.label || item.name,
          }))
        );
      }

      return [
        ...prevSelected,
        {
          id: selectedElement.value,
          name: selectedElement.label,
        },
      ];
    });
  }
};

export const onDelete = (
  id: number | string,
  setSelect: React.Dispatch<React.SetStateAction<MultiSelectProps[]>>,
  onChange?: any
) => {
  if (id === 'all') {
    setSelect([]);
    return;
  }

  setSelect((prevSelected: any) => {
    if (onChange) {
      onChange(
        prevSelected.filter(
          (product: any) => (product?.id || product?.type) !== id
        )
      );
    }

    return prevSelected.filter(
      (product: any) => (product?.id || product?.type) !== id
    );
  });
};

export const convertFileToBase64 = (file: any) => {
  if (file) {
    if (file?.id) return false;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        resolve(event.target.result.split(',')[1]);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }
};

export const initialSteps = (t: any) => [
  {
    step: 1,
    title: t('First step'),
    value: '',
  },
  // {
  // 	step: 2,
  // 	title: t("Second step"),
  // 	value:
  // 		"",
  // },
  // {
  // 	step: 3,
  // 	title: t("Third step"),
  // 	value:
  // 		"",
  // },
  // {
  // 	step: 4,
  // 	title: t("Fourth step"),
  // 	value:
  // 		"",
  // },
  // {
  // 	step: 5,
  // 	title: t("Fifth step"),
  // 	value:
  // 		"",
  // },
];

export const materialsData = (t: TFunction) => [
  {
    name: t('Add brief'),
    description: t('Maximum file size 10 Mb (pdf, doc, docx)'),
    controlName: 'useCases-2',
  },
];

export const stepTitles = (t: TFunction) => [
  t('First'),
  t('Second'),
  t('Third'),
  t('Fourth'),
  t('Fifth'),
  t('Sixth'),
  t('Seventh'),
  t('Eighth'),
  t('Ninth'),
  t('Tenth'),
];

export const multiFieldValues = (
  selected: any,
  field?: any,
  i18n?: i18n,
  hideOrder?: boolean
) => {
  const filledArray =
    field?.value || (selected.length ? selected : field?.value) || [];
  if (!i18n) return;
  return filledArray?.map(
    (selected: { id: number | string; type: string }, index: number) => ({
      value: selected?.id || selected?.type,
      label: getLangName(selected, 'name', i18n),
      ...(!hideOrder && { order: index + 1 }),
    })
  );
};

export const writeRef = (
  hasError: boolean,
  refContainer: MutableRefObject<Record<string, HTMLDivElement | null>>,
  element: HTMLDivElement | null,
  index: string
) => {
  if (hasError && !refContainer.current[index]) {
    refContainer.current[index] = element;
  } else if (!hasError && refContainer.current[index]) {
    refContainer.current[index] = null;
  }
};
