import clsx from 'clsx';
import { Grid, GridItem } from 'components/common/Grid';
import { LABELS } from 'constants/onboarding';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';
import classes from './Account.module.scss';
import { Business } from './Business';
import { ChangePassword } from './ChangePassword';
import { Personal } from './Personal';
import { Switch } from './Switch';
import { useAccountContainer } from './useAccountContainer';
import { useUserStore } from 'contexts/UserContext';
import { useCommonStore } from 'contexts/CommonContext';
import { RUSSIAN_LOCALE } from 'utils';
import Employees from './Employees/Employees';
import { BusinessUnits } from './BusinessUnits';

export const AccountContainer = observer(({ section }: { section: string }) => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const commonStore = useCommonStore();
  const user = userStore.user;
  const { GOT_IT, NEXT, BACK } = LABELS(t);
  const { callbacks, getParams } = useAccountContainer();

  const isMobile = useWindowWidth().isMediaTablet;
  const [currentStep, setCurrentStep] = useState(0);
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);
  const onboardingPath = 'PROFILE_P';

  const hasCompletedOnboarding = useRef(false);

  useEffect(() => {
    findOnboardingSteps('PROFILE_P').then((res) => setOnboarding(res));
  }, []);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = true;
  }, [onboarding?.id]);

  const partner = userStore.partners?.results?.[0];

  const personalData = {
    fullName: user?.full_name,
    email: user?.email,
    phone: user?.phone_number ? user.phone_number.substring(1) : '',
    jobTitle: user?.user_role_in_company || '',
  };

  const businessData = {
    companyName: partner?.name || user?.company_name,
    companyLink: partner?.link || user?.company_link,
    companyLocation: partner?.country || user?.country?.id || null,
    companyProducts:
      (partner?.product_types?.length && partner?.product_types) ||
      user?.user_products,
    companyId:
      (RUSSIAN_LOCALE ? partner?.bitrix_id : partner?.zoho_vendor_id) || '-',
  };

  return (
    <>
      <div className={classes.wrapper}>
        {!isMobile && onboarding ? (
          <Steps
            enabled={true}
            steps={formatSteps(onboardingPath, onboarding.steps)}
            options={{
              tooltipClass: 'customTooltip',
              showBullets: true,
              doneLabel: GOT_IT,
              nextLabel: NEXT,
              prevLabel: BACK,
            }}
            initialStep={0}
            onChange={(step: number) => {
              if (
                onboardingPath.toLowerCase() === 'profile_p' &&
                RUSSIAN_LOCALE &&
                step === 2
              ) {
                setCurrentStep(2);
              } else {
                setCurrentStep(step + 1);
              }
            }}
            onExit={completeCurrentOnboarding}
          />
        ) : null}
        <Grid direction={'row'} className={classes.container}>
          <GridItem col={{ default: 12, xsup: 3, md: 3 }}>
            <Switch
              className={clsx(section && 'chosen')}
              section={currentStep ? '' : section}
              currentStep={currentStep}
            />
          </GridItem>

          <GridItem col={{ default: 12, xsup: 9, md: 9 }}>
            {section && (
              <div
                className={classes.innerBlock}
                data-step-profile_p-0
                data-step-profile_p-1
                data-step-profile_p-2
                data-step-profile_p-3
              >
                {(currentStep === 1 ||
                  (section === 'personal' && personalData && !currentStep)) && (
                  <Personal
                    onSubmit={callbacks.onSubmitPersonal}
                    defaultValue={personalData}
                    getParams={getParams}
                  />
                )}
                {(currentStep === 2 ||
                  (section === 'business' && businessData && !currentStep)) && (
                  <Business
                    onSubmit={callbacks.onSubmitBusiness}
                    products={commonStore?.products}
                    defaultValue={businessData}
                    getParams={getParams}
                    disabled
                  />
                )}
                {userStore?.isSupervisor &&
                  (currentStep === 2 ||
                    (section === 'business-units' && !currentStep)) && (
                    <BusinessUnits />
                  )}
                {(currentStep === 5 ||
                  (section === 'employees' && !currentStep)) && <Employees />}
                {!RUSSIAN_LOCALE &&
                  (currentStep === 3 ||
                    currentStep === 4 ||
                    (section === 'change-password' && !currentStep)) && (
                    <ChangePassword />
                  )}
              </div>
            )}
          </GridItem>
        </Grid>
      </div>
    </>
  );
});
