import React, { FC, useEffect, useRef, useState } from 'react';
import classes from 'containers/AddSolution/FormBlock/FormBlock.module.scss';
import selectClasses from './Select.module.scss';
import clsx from 'classnames';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';
import { SelectData } from '../SelectComponent';
import UiIcon from '../Icon';
import { useTranslation } from 'react-i18next';
import FormInput, { EndIconProps } from '../NewInput';
import { TooltipContainer } from '../Tooltip/TooltipContainer';
import { debounce } from 'lodash';
import { capitalizeFirstLetter, getLangName } from 'tools/utils';
import { Radio } from '../Radio';
import { RUSSIAN_LOCALE } from '../../../utils';

interface SelectProps {
  options?: SelectData[];
  placeholder?: string;
  setSelect?: (val: any) => void;
  select: any;
  title?: string;
  open?: boolean;
  additionalValue?: string;
  setOpen?: (val: boolean) => void;
  defaultValue?: any;
  onClick?: () => void;
  onSelect?: (item: SelectData) => void;
  error?: string;
  className?: string;
  disabled?: boolean;
  isSolutionForm?: boolean;
  endIcon?: EndIconProps;
  isSearch?: boolean;
  searchPlaceholder?: string;
  onSearch?: (value: string) => void;
  debounceCount?: number;
  isNotHoverEffect?: boolean;
  usePortal?: boolean;
  classNameRadioDescription?: string;
  isType?: boolean;
  small?: boolean;
  classNameTitle?: string;
  isInputFocused?: boolean;
  isInInput?: boolean;
  isRadio?: boolean;
  isAccountForm?: boolean;
  dropdownClassName?: string;
  noOptionsRender?: React.ReactNode;
  showWarning?: boolean;
}

export const Select: FC<SelectProps> = ({
  title,
  onClick,
  options,
  select,
  setSelect,
  defaultValue,
  onSelect,
  error,
  className,
  placeholder,
  disabled,
  isSolutionForm = false,
  endIcon,
  isSearch,
  searchPlaceholder,
  onSearch,
  debounceCount,
  isNotHoverEffect,
  usePortal,
  classNameRadioDescription,
  isType,
  small,
  classNameTitle,
  isInputFocused,
  isInInput,
  isRadio,
  isAccountForm,
  dropdownClassName,
  noOptionsRender,
  showWarning,
}) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const dropdownRef = useRef<HTMLDivElement>(null);

  const startIconOptions = {
    startIconName: 'SearchIconAlt',
    startIconClassName: classes.startIcon,
  };

  const selectRef = useRef<HTMLDivElement>(null);
  const {
    endIconName,
    endIconTooltipText,
    endIconTooltipPosition,
    endIconTooltipClassName,
    endIconClassName,
    tooltipInner,
  } = endIcon || {};

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef?.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (open && usePortal && selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  }, [open, usePortal]);

  const renderDescription = (description: string) => {
    if (description) {
      return <span className={classes.additionalValue}> {description}</span>;
    }
  };

  const callbacks = {
    onSearch: (value: string) => {
      setSearch(value);

      if (debounce) {
        debounce(() => onSearch?.(value), debounceCount)();
      } else {
        onSearch?.(value);
      }
    },
    onExpandChange: (value?: boolean) => {
      if (typeof value === 'boolean') {
        setOpen(value);
      } else {
        setOpen((prev) => !prev);
      }
    },
    onKeyboardExpandChange: (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        callbacks.onExpandChange(true);
      }
    },
  };

  const dropdownContent = (
    <div
      ref={dropdownRef}
      className={clsx(
        classes.dropdown,
        !isType && selectClasses.dropdownRadio,
        small && !isType && selectClasses.dropdownRadioSmall,
        dropdownClassName
      )}
      style={{
        position: usePortal ? 'fixed' : 'absolute',
        top: usePortal ? dropdownPosition.top : undefined,
        left: usePortal ? dropdownPosition.left : undefined,
        width: usePortal ? dropdownPosition.width : undefined,
        zIndex: 9999,
      }}
    >
      {isSearch ? (
        <div
          className={clsx(
            classes['enter-source'],
            isSolutionForm && classes['enter-source__form']
          )}
        >
          <FormInput
            value={search}
            placeholder={searchPlaceholder || ''}
            onChange={callbacks.onSearch}
            startIcon={startIconOptions}
            classNames={classes['enter-source__input']}
            isSearch
          />
          <div className={clsx(classes.list, selectClasses.list)}>
          {options?.length === 0
              ? noOptionsRender || null
              : options?.map((item: SelectData, index: number) => {
                return (
                  <div
                    key={`${item.id}${index}`}
                    onClick={(e) => {
                      if (setOpen) setOpen(false);
                      if (item?.id !== select?.id) {
                        if (onSelect) onSelect(item);
                        if (setSelect) setSelect(item);
                      }
                    }}
                    className={clsx(
                      classes.item,
                      item?.id && item?.id === select?.id && classes.active,
                      item?.type &&
                        item?.type === select?.type &&
                        classes.active
                    )}
                  >
                    <span>{getLangName(item, 'name', i18n)}</span>
                    {item?.description && (
                      <span className={classes.additionalValue}>
                        {item?.description}
                      </span>
                    )}
                    {item?.id === select?.id && !isSolutionForm && (
                      <UiIcon
                        name="CheckIcon"
                        additionalClassName={classes.checkIcon}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className={clsx(classes.list, selectClasses.list)}>
          {options?.length === 0
            ? noOptionsRender || null
            : options?.map((item: any, index: number) => {
              if (!isRadio && (item?.type || item?.id)) {
                return (
                  <div
                    key={`${item.id}${index}`}
                    onClick={(e) => {
                      if (setOpen) setOpen(false);
                      if (
                        item?.id !== select?.id ||
                        item?.type !== select?.type
                      ) {
                        if (onSelect) onSelect(item);
                        if (setSelect) setSelect(item);
                      }
                    }}
                    className={clsx(
                      classes.item,
                      item?.id && item?.id === select?.id && classes.active,
                      item?.type &&
                        item?.type === select?.type &&
                        classes.active
                    )}
                  >
                    <span>{getLangName(item, 'name', i18n)}</span>
                    {item?.description && (
                      <span className={classes.additionalValue}>
                        {item?.description}
                      </span>
                    )}
                    {item?.id === select?.id && !isSolutionForm && (
                      <UiIcon
                        name="CheckIcon"
                        additionalClassName={classes.checkIcon}
                      />
                    )}
                  </div>
                );
              } else {
                return (
                  <Radio
                    key={`${item.id}${index}`}
                    name="item"
                    value={item}
                    checked={item?.id === select?.id}
                    onChange={() => {
                      setOpen(false);
                      if (item?.id !== select?.id) {
                        onSelect?.(item);
                        setSelect?.(item);
                      }
                    }}
                    label={
                      isSolutionForm
                        ? item?.name
                        : capitalizeFirstLetter(item?.name ? t(item.name) : '')
                    }
                    description={item?.description ? t(item.description) : ''}
                    classNameDescription={clsx(
                      classes.radioDescription,
                      !RUSSIAN_LOCALE && classes.radioDescriptionRU,
                      classNameRadioDescription
                    )}
                    classNameTitle={clsx(
                      classes.radioTitle,
                      !RUSSIAN_LOCALE && classes.radioTitleRU,
                      classNameTitle
                    )}
                  />
                );
              }
            })}
        </div>
      )}
    </div>
  );

  return (
    <div className={className}>
      <div
        className={clsx(
          classes.selectBox,
          isRadio && selectClasses.selectBox,
          isSolutionForm && disabled && classes.selectBox__disabled,
          disabled && selectClasses.selectBox__disabled
        )}
        ref={selectRef}
        onClick={() => !disabled && onClick && onClick()}
      >
        <div
          className={clsx(classes.wrapper, isRadio && selectClasses.wrapper)}
        >
          <div
            onClick={() => {
              if (setOpen && !disabled) setOpen(!open);
            }}
            className={clsx(
              classes['main-input'],
              isRadio && selectClasses['main-input'],
              open && classes.dropActive,
              open && isRadio && selectClasses.dropActive,
              error && classes.error,
              isSolutionForm && classes['main-input__solutionForm'],
              isAccountForm && classes['main-input__accountForm'],
              isInInput && selectClasses['inInput'],
              open && classes['main-input-focused'],
              isRadio &&
                (isInputFocused || open) &&
                selectClasses['main-input-focused'],
              isRadio &&
                open &&
                isNotHoverEffect &&
                selectClasses['notHoverEffect'],
              disabled && classes['main-input__disabled'],
              small && selectClasses.small,
              showWarning && classes.warning
            )}
          >
            <div
              className={clsx(
                classes.inputBlock,
                disabled && classes.inputBlock__disabled
              )}
            >
              <span
                className={clsx(
                  classes.inputTitle,
                  select ? classes.inputTitleSelected : ''
                )}
              >
                {title}
              </span>
              <div className={classes.drop}>
                {getLangName(select || defaultValue, 'name', i18n)}
                {!isRadio &&
                  renderDescription(
                    select?.description || defaultValue?.description
                  )}
              </div>
            </div>
            {endIconName && (
              <TooltipContainer
                text={endIconTooltipText}
                customClasses={'kit-ui-block'}
                position={endIconTooltipPosition}
                className={clsx(endIconTooltipClassName, selectClasses.endIcon)}
                tooltipInner={tooltipInner}
              >
                <UiIcon
                  name={endIconName}
                  additionalClassName={clsx(
                    classes.endIcon,
                    endIconClassName,
                    error && classes.errorIcon
                  )}
                />
              </TooltipContainer>
            )}
            <div className={clsx(classes.arrow, open && classes.arrowActive)}>
              <ArrowDownIcon />
            </div>
          </div>
          {open && dropdownContent}
        </div>
      </div>
      {error && (
        <div className={classes.errorText}>
          {error || t('Fill in the field')}
        </div>
      )}
    </div>
  );
};
