import { i18n } from 'i18next';

export const langSelector = (name: string, i18n: i18n) =>
  `${name}_${i18n.language}`?.length ? `${name}_${i18n.language}` : name;

export const getLangName = (parent: any, child: string, i18n: i18n) =>
  parent?.[langSelector(child, i18n)] || parent?.[child];

export const getLangNameNew = (parent: any, child: string, i18n: i18n) =>
  parent?.[langSelector(child, i18n)] || '';

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const decodeHtmlEntities = (html: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const handleCopy = (text: string) => {
  navigator.clipboard
    .writeText(text)

    .catch((error) => {
      console.error('Ошибка копирования:', error);
    });
};

export const cleanEditorValue = (value: string): string => {
  const text = value.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').trim();
  return !text || value === '<p></p>' ? '' : value;
};