import { FILTERS, FILTERS_LABELS, Status } from 'constants/enum';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTypes } from 'types/partner';
import { priceTextContent } from 'components/shared/PriceText';
import { usePartnerStore } from 'contexts/PartnerContext';
import { useUserStore } from 'contexts/UserContext';
import { getLangName } from 'tools/utils';
import { queryParamsHandler } from './utils';
import { BusinessUnit, PARTNER_ROLE } from 'types/user';
import { MultiSelectOption } from '../../components/shared/MultiSelect';
import { onDelete, onSelect } from '../AddSolution/utils';
import { ShowNotification } from 'tools/showNotification';
import { partnerStore } from 'stores/partnerStore';

export const PARTNER_COUNTER = 8;

export const usePartnerContainer = () => {
  const { t, i18n } = useTranslation();
  const initialRender = useRef(true);
  const userStore = useUserStore();
  const { currency } = userStore?.user;
  const isSupervisor = userStore?.isSupervisor;
  const businessUnits = userStore?.businessUnits;
  const {
    services,
    servicesCount,
    statusCount,
    clearCopyOfService,
    fetchPartnerServices,
    nextServices,
    prevServices,
  } = usePartnerStore() || {};

  const [filter, setFilter] = useState<FilterTypes>(FILTERS.ALL as FilterTypes);
  const [currentPage, setCurrentPage] = useState(1);
  const [internalBUnitOptions, setInternalBUnitOptions] = useState<
    MultiSelectOption[]
  >([]);
  const [selectedBUnits, setSelectedBUnits] = useState([]);

  const isOnModerate = !userStore?.user?.moderated;
  const isPartnerViewer =
    userStore?.partners?.results?.[0]?.permission?.type === PARTNER_ROLE.VIEWER;

  const changeChunks = () => {
    let newArray: any = [];
    let servicesLength = 0;

    switch (filter) {
      case 'all':
        servicesLength = servicesCount;
        break;
      case 'is_active':
        servicesLength = statusCount.active;
        break;
      case 'is_archived':
        servicesLength = statusCount.archived;
        break;
      case 'is_review':
        servicesLength = statusCount.in_review;
        break;
      case 'is_rejected':
        servicesLength = statusCount.rejected;
        break;
      default:
        break;
    }

    for (let i = 0; i < servicesLength; i += PARTNER_COUNTER) {
      const chunk = services?.slice(i, i + PARTNER_COUNTER);
      newArray = [...newArray, chunk];
    }

    return newArray;
  };

  const getStatus = (value: Status) => {
    switch (value) {
      case Status.active: {
        return FILTERS_LABELS.ACTIVE;
      }
      case Status.reviewed: {
        return FILTERS_LABELS.IN_REVIEW;
      }
      case Status.archived: {
        return FILTERS_LABELS.ARCHIVED;
      }
      case Status.rejected: {
        return FILTERS_LABELS.REJECTED;
      }
      case Status.moderated: {
        return FILTERS_LABELS.IN_REVIEW;
      }
    }
  };

  const internalBusinessUnits = useMemo(
    () =>
      businessUnits?.map((item: BusinessUnit) => ({
        value: item.id,
        label: getLangName(item, 'name', i18n),
      })),
    [businessUnits, i18n]
  );

  useEffect(() => {
    clearCopyOfService();
    if (partnerStore?.isServiceActivated) {
      ShowNotification({
        type: 'success',
        title: t('The solution is active on the platform'),
        children: t(
          'Now clients can order the Solution in their personal account, and for Partners the Solution status will change to “Active”'
        ),
        isNewToast: true,
      });
      partnerStore.isServiceActivated = false;
    }

    if (partnerStore?.isServiceChanged) {
      ShowNotification({
        type: 'success',
        title: t('The solution is changed successfully'),
        children: t(
          'Now the updated content will be displayed on the client showcase, the status of the solution in the partner’s account will change to “Active”'
        ),
        isNewToast: true,
      });
      partnerStore.isServiceChanged = false;
    }
  }, []);

  useEffect(() => {
    internalBusinessUnits && setInternalBUnitOptions(internalBusinessUnits);
  }, [internalBusinessUnits]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const params = queryParamsHandler({
      statusFilter: filter === 'all' ? '' : filter,
      analytics: true,
      page: 1,
      page_size: PARTNER_COUNTER,
      partner_ids: selectedBUnits?.map((item: any) => item?.id),
    });

    fetchPartnerServices(params);
    setCurrentPage(1);
  }, [filter, selectedBUnits]);

  useEffect(() => {
    const section = document.getElementsByClassName('app__section')[0];
    if (section) {
      if (services?.length === 0) {
        section.classList.add('app__section_gradient');
      } else {
        section.classList.remove('app__section_gradient');
      }
    }
  }, [services?.length]);

  const cardInfo = useMemo(() => {
    return services?.map((singleService: any) => {
      const status: Status = singleService.status;
      return {
        status: getStatus(status),
        name: getLangName(singleService, 'name', i18n),
        id: singleService?.id,
        currency: currency,
        source: {
          name: getLangName(singleService?.product, 'name', i18n),
          link: singleService?.slug,
        },
        description: getLangName(singleService, 'description', i18n),
        full_description: getLangName(singleService, 'description', i18n),
        short_description: getLangName(
          singleService,
          'short_description',
          i18n
        ),
        period_comment: getLangName(singleService, 'period_comment', i18n),
        product: singleService?.product,
        product_crm_id: singleService?.product_crm_id,
        stages: singleService?.stages,
        cases: singleService?.case,
        company_size: singleService?.company_size,
        business_type: singleService?.business_type,
        goals: singleService?.goals,
        cost: singleService?.cost_with_discounts,
        isFree: singleService?.is_free,
        price_per: singleService?.price_per,
        slug: singleService?.slug,
        partner_name: singleService?.partner?.name,
        data: {
          impressions: {
            name: t('Impressions'),
            description: t('Compared to last week'),
            count:
              singleService?.service_analytics?.[0]?.total_impressions_weekly ||
              0,
            percentage:
              singleService?.service_analytics?.[0]?.impressions_weekly_diff ||
              '0%',
          },
          favorites: {
            name: t('Added to favorites'),
            description: t('Compared to last week'),
            count:
              singleService?.service_analytics?.[0]
                ?.total_added_to_favorites_weekly || 0,
            percentage:
              singleService?.service_analytics?.[0]
                ?.added_to_favorites_weekly_diff || '0%',
          },
          cart: {
            name: t('Added to cart'),
            description: t('Compared to last week'),
            count:
              singleService?.service_analytics?.[0]
                ?.total_added_to_cart_weekly || 0,
            percentage:
              singleService?.service_analytics?.[0]
                ?.added_to_cart_weekly_diff || '0%',
          },
          price: {
            name: t('Price'),
            discountCost: singleService?.is_free
              ? 0
              : singleService?.cost_with_discounts,
            cost: singleService?.is_free ? 0 : singleService.cost,
            description: '',
            period: singleService.cost
              ? priceTextContent(singleService, t, false, false, true)
              : '',
          },
        },
      };
    });
  }, [services, i18n.language]);

  const onPaginate = (page: number) => {
    const params = queryParamsHandler({
      statusFilter: filter === 'all' ? '' : filter,
      analytics: true,
      page: page,
      page_size: PARTNER_COUNTER,
      next: nextServices,
      prev: prevServices,
    });

    fetchPartnerServices(params);
  };

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const callbacks = {
    onSelectBUnits: (id: number | string, onChange?: any) =>
      onSelect(id, internalBusinessUnits, setSelectedBUnits as any, onChange),
    onDeleteBunits: (id: number | string, onChange?: any) =>
      onDelete(id, setSelectedBUnits as any, onChange),
  };

  return {
    t,
    services,
    servicesCount,
    clearCopyOfService,
    statusCount,
    filter,
    setFilter,
    isOnModerate,
    cardInfo,
    currentPage,
    changeChunks,
    setCurrentPage,
    onPaginate,
    goToTop,
    isPartnerViewer,
    isSupervisor,
    internalBUnitOptions,
    callbacks,
    selectedBUnits,
    setSelectedBUnits,
    i18n,
  };
};
