import clsx from 'classnames';
import UiIcon from 'components/shared/Icon';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { usePartnerStore } from 'contexts/PartnerContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classes from './AddSolution.module.scss';
import { FormBlock } from './FormBlock';
import { toJS } from 'mobx';
import { Preview } from '../../components/Preview';
import { observer } from 'mobx-react-lite';
import { useFormBlock } from './FormBlock/useFormBlock';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';
import { Steps } from 'intro.js-react';
import { LABELS } from 'constants/onboarding';
import { DISABLE_TRANSLATION } from 'constants/languages';
import { userStore } from 'stores/user-store';
import { useLocation } from 'react-router-dom';

interface AddSolutionProps {
  title: string;
  isModeratePage?: boolean;
  isChangePage?: boolean;
}

export const AddSolution = observer(
  ({ title, isModeratePage, isChangePage }: AddSolutionProps) => {
    const {
      copiedService,
      clearCopyOfService,
      isCompletedFormSteps,
      clearCompletedFormSteps,
      getPartnerCases,
      fetchSingleService,
    } = usePartnerStore() || {};
    const { isSupervisor, isAdmin } = userStore;
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [openCasesModal, setOpenCasesModal] = useState<boolean>(false);
    const [disableCases, setDisableCases] = useState<boolean>(true);
    const partnerId = userStore.partners?.results[0]?.id;
    const location = useLocation();

    const serviceSlug = localStorage.getItem('serviceSlugToModerate');
    const isModerateSolutionPage =
      location?.pathname?.includes('moderate-solution');
    const isChangeSolutionPage =
      location?.pathname?.includes('change-solution');

    useEffect(() => {
      if (
        serviceSlug &&
        (isSupervisor || isAdmin) &&
        (isModerateSolutionPage || isChangeSolutionPage)
      ) {
        fetchSingleService(serviceSlug, true).then(() => {
          setDisableCases(false);
        });
      }
      if (partnerId) {
        getPartnerCases(partnerId).then(() => setDisableCases(false));
      } else {
        setDisableCases(false);
      }
    }, []);

    const { t } = useTranslation();
    const { isMediaTablet } = useWindowWidth();

    const { GOT_IT, NEXT, BACK } = LABELS(t);
    const isMobile = useWindowWidth().isMediaTablet;
    const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);

    const onboardingPath = useMemo(() => {
      if (currentStep === 1) {
        return 'ADD_SOLUTION_P';
      }
      if (currentStep === 3) {
        return 'PRICE_SOLUTION_P';
      }
      if (currentStep === 4) {
        return 'PREVIEW_SOLUTION_P';
      }

      return '';
    }, [currentStep]);

    const completedOnboarding = useRef<string[]>([]);

    useEffect(() => {
      findOnboardingSteps(onboardingPath).then((res) => {
        setOnboarding(res);
      });
    }, [onboardingPath]);

    const completeCurrentOnboarding = useCallback(() => {
      if (completedOnboarding.current.includes(onboardingPath)) {
        return;
      }

      completeOnboarding(onboarding?.id);
      setOnboarding(null);
      completedOnboarding.current.push(onboardingPath);
    }, [onboarding?.id, onboardingPath]);

    const { stepOne, stepTwo, stepThree } = isCompletedFormSteps || {};

    const { methods } = useFormBlock(copiedService, currentStep);

    useEffect(() => {
      return () => {
        clearCopyOfService();
        clearCompletedFormSteps();
      };
    }, []);

    useEffect(() => {
      const layoutContainer = document.querySelector(
        '.layout-container'
      ) as HTMLDivElement;

      if (isMediaTablet && layoutContainer) {
        layoutContainer.style.paddingLeft = '0';
        layoutContainer.style.paddingRight = '0';
      } else {
        layoutContainer.style.paddingLeft = '20px';
        layoutContainer.style.paddingRight = '20px';
      }

      return () => {
        if (layoutContainer) {
          layoutContainer.style.paddingLeft = '20px';
          layoutContainer.style.paddingRight = '20px';
        }
      };
    }, [isMediaTablet]);

    const nextStep = currentStep + 1;

    const isValid = methods.formState.isValid;

    const isAllowedStep = (step: number) => {
      if (stepOne && stepTwo && stepThree && isValid) return true;
      if (stepOne && stepTwo && step !== 4) return true;
      if (step <= nextStep) {
        return true;
      }
    };

    const handleChangeStep = async (step: number) => {
      if (!isAllowedStep(step)) return;
      const isValid = await methods.trigger();
      const scrollToTop = () =>
        window?.scrollTo({ top: 0, behavior: 'smooth' });

      switch (step) {
        case 1:
          setCurrentStep(1);
          scrollToTop();
          break;
        case 2:
          if (currentStep > step && !disableCases) {
            setCurrentStep(2);
            scrollToTop();
            break;
          } else if (isValid) {
            isCompletedFormSteps.stepOne = true;
            setCurrentStep(2);
            break;
          }
          break;
        case 3:
          if (!isValid) break;
          if (methods.getValues()?.cases?.length !== 0) {
            isCompletedFormSteps.stepTwo = true;
            setCurrentStep(3);
            break;
          } else {
            setOpenCasesModal(true);
            break;
          }
        case 4:
          if (!isValid) break;
          isCompletedFormSteps.stepThree = true;
          setCurrentStep(4);
          break;
      }
    };

    return (
      <div
        className={classes.addSolutionContainer}
        translate={DISABLE_TRANSLATION}
      >
        {!isMobile && onboarding ? (
          <Steps
            enabled={true}
            steps={formatSteps(onboardingPath, onboarding.steps)}
            options={{
              tooltipClass: 'customTooltip',
              showBullets: true,
              doneLabel: GOT_IT,
              nextLabel: NEXT,
              prevLabel: BACK,
              scrollToElement: currentStep !== 1 && currentStep !== 4,
            }}
            initialStep={0}
            onExit={completeCurrentOnboarding}
          />
        ) : null}

        <div className={classes.title}>{title}</div>
        <div
          className={clsx(
            classes.formBlockContainer,
            currentStep === 4 && classes.previewBlock,
            currentStep === 2 && classes.stepTwo
          )}
        >
          <div className={classes.stepsBlock}>
            <TitleAndDescription
              pageName={isChangePage ? 'ChangeSolution' : 'AddSolution'}
            />

            <div
              className={clsx(
                classes.step,
                currentStep === 1 && classes.active,
                stepOne && currentStep !== 1 && classes.done
              )}
              onClick={() => handleChangeStep(1)}
            >
              {stepOne && currentStep !== 1 ? (
                <UiIcon
                  name="CheckIconSecond"
                  additionalClassName={classes.stepNumber__icon}
                />
              ) : (
                <div className={classes.stepNumber}>
                  <div className={classes.stepNumber__inner}>1</div>
                </div>
              )}
              <div className={classes.stepTitle}>{t('Main information')}</div>
            </div>

            <span className={classes.stepLine} />

            <div
              className={clsx(
                classes.step,
                currentStep === 2 && classes.active,
                stepTwo && currentStep !== 2 && classes.done
              )}
              onClick={() => handleChangeStep(2)}
            >
              {stepTwo && currentStep !== 2 ? (
                <UiIcon
                  name="CheckIconSecond"
                  additionalClassName={classes.stepNumber__icon}
                />
              ) : (
                <div className={classes.stepNumber}>
                  <div className={classes.stepNumber__inner}>2</div>
                </div>
              )}
              <div className={classes.stepTitle}>{t('Cases')}</div>
            </div>

            <span className={classes.stepLine} />

            <div
              className={clsx(
                classes.step,
                currentStep === 3 && classes.active,
                stepThree && currentStep !== 3 && classes.done,
                !isAllowedStep(3) && classes.notAllowed
              )}
              onClick={() => handleChangeStep(3)}
            >
              {stepThree && currentStep !== 3 ? (
                <UiIcon
                  name="CheckIconSecond"
                  additionalClassName={classes.stepNumber__icon}
                />
              ) : (
                <div className={classes.stepNumber}>
                  <div className={classes.stepNumber__inner}>3</div>
                </div>
              )}
              <div className={classes.stepTitle}>{t('Price')}</div>
            </div>

            <span className={classes.stepLine} />

            <div
              className={clsx(
                classes.step,
                currentStep === 4 && classes.active,
                currentStep > 4 && classes.done,
                !isAllowedStep(4) && classes.notAllowed
              )}
              onClick={() => handleChangeStep(4)}
            >
              <div className={classes.stepNumber}>
                <div className={classes.stepNumber__inner}>4</div>
              </div>
              <div className={classes.stepTitle}>{t('Preview')}</div>
            </div>
          </div>

          {currentStep === 4 && <Preview data={toJS(copiedService)} />}

          <FormProvider {...methods}>
            <FormBlock
              title={t('General overview')}
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              copiedService={copiedService}
              isFormBlock
              openCaseModal={openCasesModal}
              setOpenCaseModal={setOpenCasesModal}
              isModeratePage={isModeratePage}
              isChangePage={isChangePage}
            />
          </FormProvider>
        </div>
      </div>
    );
  }
);
