import { FILTERS_LABELS } from 'constants/enum';
import { usePartnerStore } from 'contexts/PartnerContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Partner from 'services/partner';
import { percentNumber, queryParamsHandler } from '../utils';
import classes from './Card.module.scss';
import { useUserStore } from 'contexts/UserContext';
import { partnerStore } from '../../../stores/partnerStore';
import { ShowNotification } from '../../../tools/showNotification';

export const useCard = (project: any) => {
  const { t } = useTranslation();
  const { ACTIVE, IN_REVIEW, ARCHIVED, REJECTED } = FILTERS_LABELS;
  const active_tags = [ACTIVE, IN_REVIEW, ARCHIVED, REJECTED];
  const [open, setOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const isTablet = useWindowWidth().isMediaTablet;
  const { isSupervisor, isAdmin } = useUserStore();

  const {
    setCopyOfService,
    fetchPartnerServices,
    fetchSingleService,
    archiveService,
    unarchiveService,
  } = usePartnerStore();

  useEffect(() => {
    if (!isTablet) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef?.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setOpen && setOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [setOpen, isTablet]);

  const status = String(project?.status).toLowerCase();
  const firstCapitalStatus =
    project?.status?.charAt(0)?.toUpperCase() +
    project?.status?.slice(1)?.toLowerCase();
  let tagClass = '';
  let tagText = '';

  const currency = project?.currency?.symbol;

  switch (status) {
    case IN_REVIEW:
      tagClass = classes.card__tag_yellow;
      break;
    case ARCHIVED:
      tagClass = classes.card__tag_grey;
      break;
    case ACTIVE:
      tagClass = classes.card__tag_green;
      break;
    case REJECTED:
      tagClass = classes.card__tag_red;
      break;
  }

  tagText = t(firstCapitalStatus);

  const mobileModalData =
    // TODO: Add permission to admin (isSupervisor || isAdmin)
    isSupervisor
      ? [{ id: 1, name: status === ARCHIVED ? t('Unarchive') : t('Archive') }]
          .concat(
            status === IN_REVIEW
              ? { id: 4, name: t('Moderate') }
              : status === ACTIVE || status === REJECTED
                ? { id: 3, name: t('Change') }
                : []
          )
          .sort((a, b) => b.id - a.id)
      : [{ id: 2, name: t('Copy') }];

  const mobileModalDataPrice = [
    {
      id: 1,
      value: `${currency} ${project.data?.price?.cost}`,
      name: t('Before discount'),
    },
    {
      id: 2,
      value: `${currency} ${project.data?.price?.discountCost}`,
      name: t('After discount'),
    },
  ];

  const percentDifference = Math.round(
    ((project.data?.price?.cost - project.data?.price?.discountCost) /
      project.data?.price?.cost) *
      100
  );

  const onArchive = async (slug: string) => {
    const serviceParams = queryParamsHandler({ analytics: true });
    if (slug) {
      if (status === ARCHIVED) {
        await unarchiveService(slug);
        await fetchPartnerServices(serviceParams);
      } else {
        await archiveService(slug);
        await fetchPartnerServices(serviceParams);
      }
    }
    setOpen(!open);
  };

  const onCopy = async (
    project: any,
    isModerate?: boolean,
    isChange?: boolean
  ) => {
    setCopyOfService(project);
    await fetchSingleService(project?.slug);
    isSupervisor &&
      localStorage.setItem('serviceSlugToModerate', project?.slug);
    const navigatePath =
      isChange && (isAdmin || isSupervisor)
        ? '/solutions/change-solution/'
        : isModerate && isSupervisor
          ? '/solutions/moderate-solution/'
          : '/solutions/add-solution/';
    navigate(navigatePath);
    setOpen(!open);
  };

  const isNoneImpressions = percentNumber({
    isNone: project.data.impressions?.percentage,
  });

  const isNoneFavorites = percentNumber({
    isNone: project.data.favorites?.percentage,
  });

  const isNoneCart = percentNumber({ isNone: project.data.cart?.percentage });

  return {
    mobileModalDataPrice,
    isTablet,
    tagClass,
    tagText,
    t,
    dropdownRef,
    open,
    setOpen,
    onCopy,
    isNoneImpressions,
    isNoneFavorites,
    isNoneCart,
    percentDifference,
    mobileModalData,
    currency,
    isSupervisor,
    onArchive,
    isAdmin,
  };
};
