import { FC, useEffect, useRef, useState } from 'react';
import Footer from 'components/common/Footer';
import { Header } from 'components/common/Header';
import { useLocation, useNavigate, useOutlet } from 'react-router-dom';
import clsx from 'clsx';
import { PageLoader } from '../shared/PageLoader';
import { observer } from 'mobx-react-lite';
import { userStore } from 'stores/user-store';
import ErrorBoundary from './ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from 'contexts/AuthContext';
import { useCommonStore } from 'contexts/CommonContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { RUSSIAN_LOCALE } from '../../utils';

interface Props {
  children?: any;
}

export const BaseTemplate: FC<Props> = observer(() => {
  const outlet = useOutlet();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { isDataLoading, isPermissionError, isLocaleError, user } =
    userStore || {};
  const { isServerError, isAuth } = useAuthStore() || {};
  const { isSearchResults } = useCommonStore() || {};
  const { isMediaTablet } = useWindowWidth() || {};

  const firstRender = useRef(true);

  const [isSearchResultsPage, setIsSearchResultsPage] = useState(false);

  const isSolutionPage = location.pathname === '/solutions';
  const chat = document.getElementById('fsw-btn');

  useEffect(() => {
    if (chat && isMediaTablet) {
      if (isSolutionPage) {
        chat.style.setProperty('bottom', '120px', 'important');
      } else {
        chat.style.setProperty('bottom', '70px', 'important');
      }
    }
  }, [isMediaTablet, isSolutionPage, chat]);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  useEffect(() => {
    setIsSearchResultsPage(
      location.pathname.startsWith('/search-results') && isSearchResults
    );
  }, [location.pathname, isSearchResults]);

  useEffect(() => {
    if (isServerError && isAuth) {
      navigate('/error', { replace: true });
	    sessionStorage.setItem('prevPath', location?.pathname || 'error');
    } else {
      const prevPath = sessionStorage.getItem('prevPath');
      const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');

      if (location.pathname === '/login' && !RUSSIAN_LOCALE) {
        navigate(`${process.env.REACT_APP_LOGIN_SUCCESS_URL}`, {
          replace: true,
        });
        return;
      } else {
        if (prevPath && !redirectAfterLogin && !isServerError) {
          navigate(
            `${
              prevPath !== location.pathname
                ? prevPath
                : process.env.REACT_APP_LOGIN_SUCCESS_URL
            }`,
            { replace: true }
          );
          sessionStorage.removeItem('prevPath');
          return;
        }
      }
    }
  }, [isServerError]);

  const isLoader =
    isDataLoading && !isServerError && !isPermissionError && !isLocaleError;
  const showHeader = userStore?.isComplete && !isLocaleError;
  const showFooter = !isServerError && !isPermissionError && !isLocaleError;
  const isAccountPage = location.pathname?.includes('/account');
  const isOnModerate = !user?.moderated && !isAccountPage;

  const renderBaseTemplate = (
    <section
      className={clsx(
        'app__section',
        (isSearchResultsPage || isOnModerate) && 'app__section_gradient'
      )}
    >
      <ErrorBoundary t={t} navigate={navigate} isServerError={isServerError}>
        {showHeader && <Header />}
        <div
          className={clsx(
            'layout-container',
            firstRender.current && 'layout-container--initial'
          )}
        >
          {!isLoader && outlet}
          {showFooter && <Footer />}
        </div>
      </ErrorBoundary>
    </section>
  );

  return isLoader ? (
    <PageLoader children={renderBaseTemplate} />
  ) : (
    renderBaseTemplate
  );
});
