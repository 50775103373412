import {
  fullNameReg,
  lettersCheckReg,
  noSpacesCheckReg,
  numbersCheckReg,
  onlyLetters,
  symbolsCheckReg,
  urlCheckReg,
} from 'constants/matches';
import { t, TFunction } from 'i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { REGEX_EMAIL_254 } from 'constants/matches';
import * as yup from 'yup';

export const personalSchema = yup.object().shape({
  fullName: yup
    .string()
    .matches(fullNameReg, t('Field contains invalid characters'))
    .required(t('This field is required')),
  email: yup.string().email(t('Invalid Email')),
  jobTitle: yup
    .string()
    .matches(onlyLetters, t('Field contains invalid characters'))
    .max(254, t('Invalid field length'))
    .required(t('This field is required')),
  phone: yup.string().required(t('This field is required')),
});

export const passwordSchema = (errorMessage: string, t: I18next) =>
  yup.object().shape({
    oldPass: yup.string().min(1).required(t('This field is required')),
    newPass: yup
      .string()
      .min(8)
      .matches(symbolsCheckReg)
      .matches(numbersCheckReg)
      .matches(noSpacesCheckReg, t('Password must not contain spaces'))
      .matches(lettersCheckReg, t('Password must contain at least 1 letter'))
      .required(t('This field is required')),
    newPassConfirmation: yup
      .string()
      .oneOf([yup.ref('newPass'), undefined], errorMessage)
      .required(t('This field is required')),
  });

export const newPasswordSchema = (errorMessage: string) =>
  yup.object().shape({
    newPass: yup
      .string()
      .min(8)
      .matches(symbolsCheckReg)
      .matches(numbersCheckReg)
      .matches(noSpacesCheckReg, t('Password must not contain spaces'))
      .matches(lettersCheckReg, t('Password must contain at least 1 letter'))
      .required(t('This field is required')),
    newPassConfirmation: yup
      .string()
      .oneOf([yup.ref('newPass'), undefined], errorMessage)
      .required(t('This field is required')),
  });

export const registrationStepOneSchema = (t: I18next) =>
  yup.object().shape({
    fullName: yup
      .string()
      .matches(fullNameReg, t('Field contains invalid characters'))
      .required(t('This field is required')),
    email: yup
      .string()
      .matches(REGEX_EMAIL_254, t('Invalid Email'))
      .required(t('This field is required')),
    phone: yup
      .string()
      .test(
        'isValidPhoneNumber',
        t('Phone number is incorrect'),
        (value: any) => isValidPhoneNumber('+' + value)
      )
      .required(t('This field is required')),
    password: yup
      .string()
      .min(8, t('Password must be at least 8 characters long'))
      .matches(
        symbolsCheckReg,
        t('Password must contain at least one special character')
      )
      .matches(numbersCheckReg, t('Password must contain at least one number'))
      .matches(lettersCheckReg, t('Password must contain at least 1 letter'))
      .matches(noSpacesCheckReg, t('Password must not contain spaces'))
      .required(t('This field is required')),
    iAgree: yup
      .boolean()
      .oneOf([true], t('You must agree to the terms and conditions'))
      .required(t('This field is required')),
  });

export const registrationStepTwoSchema = (t: I18next) =>
  yup.object().shape({
    companyName: yup
      .string()
      .max(120, t('Invalid field length'))
      .required(t('This field is required')),
    companyUrl: yup
      .string()
      .matches(urlCheckReg, t('Invalid link'))
      .max(50, t('Invalid field length'))
      .required(t('This field is required')),
    jobTitle: yup
      .string()
      .matches(onlyLetters, t('Field contains invalid characters'))
      .max(254, t('Invalid field length'))
      .required(t('This field is required')),
    kid_token: yup.string().optional(),
  });

  export const registrationRuStepOneWithInviteSchema = (t: TFunction) =>
    yup.object().shape({
      kid_token: yup.string(),
      iAgree: yup
        .boolean()
        .oneOf([true], t('You must agree to the terms and conditions'))
        .required(t('This field is required')),
    });
