import clsx from 'classnames';
import { Button } from 'components/shared/Button';
import UiIcon from 'components/shared/Icon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Mobile.module.scss';
import { MobileModalProps } from './Mobile.props';
import CheckboxSelectItem from 'components/shared/MultiSelect/CheckboxSelectItem';
import { BusinessGradientIcon } from 'components/shared/Icons/BusinessGradientIcon';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';

const MobileModal = ({
  title,
  setOpen,
  open,
  onSelect,
  data,
  isPriceTag,
  buttonTextOptions,
  select,
  setSelect,
  options,
  selected,
  isMultiSelect,
  onMultiSelect,
  onMultiDelete,
  subtitle,
  isReversedBtns,
  description,
}: MobileModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const header = document.querySelector('#global-header-id') as HTMLElement;
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      header?.style.setProperty('z-index', '0');
    } else {
      header?.style.removeProperty('z-index');
    }

    return () => {
      header?.style.removeProperty('z-index');
    };
  }, [header, open]);

  const handleClose = () => {
    if (buttonTextOptions && buttonTextOptions?.onCancel) {
      buttonTextOptions?.onCancel();
    }
    if (setOpen) {
      setIsClosing(true);
      setOpen(false);
    }
  };

  const selectedAsObject = selected?.reduce(
    (acc, curr) => {
      acc[curr.value] = curr.label;
      return acc;
    },
    {} as Record<string, React.ReactNode>
  );

  const handleClickCheckbox = (id?: string | number) => {
    if (id === 'all' && selected?.length) {
      onMultiDelete?.(id);
      return;
    }
    if (selectedAsObject?.[id as any]) {
      onMultiDelete?.(id);
      return;
    }
    onMultiSelect?.(id);
  };

  const multiSelectValues = (item: any, index: number) => (
    <div key={`${item.value}-${index}`} className={classes.item}>
      <CheckboxSelectItem
        checked={!!selectedAsObject?.[item.value]}
        onClick={() => handleClickCheckbox(item.value)}
        label={item.label}
        withOrder={false}
      />
    </div>
  );

  return (
    <>
      <div
        className={clsx(classes.overlay, { [classes.close]: isClosing })}
        onClick={() => {
          setOpen && setOpen(!open);
          handleClose();
        }}
      />
      <div
        className={clsx(classes.fixedButton, { [classes.close]: isClosing })}
      >
        <div className={clsx(classes.title, classes.title__multi)}>
          <div className={classes.titleHeader}>
            <div>{title}</div>
            {description?.length && (
              <div className={classes.description}>{description}</div>
            )}
          </div>
          <UiIcon
            name="CrossIcon"
            onClick={() => {
              setOpen && setOpen(!open);
              handleClose();
            }}
          />
        </div>
        {subtitle?.length && (
          <div className={classes.subHeader}>
            <div className={classes.subHeader__icon}>
              <BusinessGradientIcon />
            </div>
            <div className={classes.subHeader__text}>{subtitle}</div>
            {selected?.length ? (
              <div
                className={classes.subHeader__count}
                onClick={() => handleClickCheckbox('all')}
              >
                <span>{selected?.length}</span>
                <span className={classes.subHeader__count__icon}>
                  <CrossIcon />
                </span>
              </div>
            ) : null}
          </div>
        )}
        {data?.length && (
          <div
            className={clsx(
              classes.items,
              isMultiSelect && classes.items__isMulti
            )}
          >
            {data?.map((item: any, index: number) =>
              isMultiSelect ? (
                multiSelectValues(item, index)
              ) : (
                <Button
                  key={`${item.id}${index}`}
                  className={clsx(
                    classes.button,
                    isPriceTag && classes.priceName
                  )}
                  onClick={() => {
                    setOpen && setOpen(!open);
                    handleClose();
                    if (item?.id !== select?.id) {
                      setSelect(item);
                      onSelect && onSelect(item);
                    }
                  }}
                >
                  {t(item?.name)}
                  {isPriceTag && (
                    <span className={classes.price}>{item?.value}</span>
                  )}
                </Button>
              )
            )}
          </div>
        )}

        {buttonTextOptions && (
          <div className={classes.modalContainer}>
            <div className={classes.bottomBlock}>
              {isReversedBtns ? (
                <>
                  {!buttonTextOptions?.hideSubmit && (
                    <Button
                      className={classes.bottomBlock__button}
                      theme="default"
                      type="button"
                      onClick={buttonTextOptions.onSubmit}
                    >
                      {buttonTextOptions.submit}
                    </Button>
                  )}
                  {!buttonTextOptions?.hideCancel && (
                    <Button
                      className={classes.bottomBlock__button}
                      theme="light"
                      onClick={buttonTextOptions.onCancel}
                    >
                      {buttonTextOptions.cancel}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {!buttonTextOptions?.hideCancel && (
                    <Button
                      className={classes.bottomBlock__button}
                      theme="light"
                      onClick={buttonTextOptions.onCancel}
                    >
                      {buttonTextOptions.cancel}
                    </Button>
                  )}
                  {!buttonTextOptions?.hideSubmit && (
                    <Button
                      className={classes.bottomBlock__button}
                      theme="default"
                      type="button"
                      onClick={buttonTextOptions.onSubmit}
                    >
                      {buttonTextOptions.submit}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MobileModal;
