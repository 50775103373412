import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import classes from './index.module.scss';
import clsx from 'classnames';
import { Input } from '../Input';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import { Button } from '../Button';
import { useWindowWidth } from 'hooks/useWindowWidth';
import UiIcon from 'components/shared/Icon';
import { useDisableScrolling } from 'hooks/useDisableScrolling';
import { LocalizedNames } from 'types/util-types';
import HeaderButtons from 'containers/Account/Employees/TableEmployees/MenuCell/MobileModals/HeaderButtons';

export interface SelectData extends LocalizedNames {
  id: number | string;
  name?: string;
  type?: string;
  description?: string;
}

interface SelectProps {
  options?: SelectData[];
  placeholder?: string;
  setSelect?: (val: any) => void;
  select: any;
  title?: string;
  open?: boolean;
  setOpen?: (val: boolean) => void;
  defaultValue?: string;
  onClick?: () => void;
  onSelect?: (item: any) => void;
  isSupervisor?: boolean;
  setIsClicked?: Dispatch<
    SetStateAction<{ role: boolean; businessUnit: boolean }>
  >;
}

const Select: FC<SelectProps> = ({
  options,
  placeholder,
  setSelect,
  select,
  title,
  open,
  setOpen,
  defaultValue,
  onClick,
  onSelect,
  isSupervisor,
  setIsClicked,
}) => {
  const isMobile = useWindowWidth().isMediaTablet;
  useDisableScrolling(isMobile && open);

  const [isClosing, setIsClosing] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!open && isClosing) {
      const timer = setTimeout(() => {
        setIsClosing(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [open, isClosing]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef?.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setOpen && setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  const handleClose = () => {
    if (setOpen) {
      setIsClosing(true);
      setOpen(false);
    }
  };

  const selectComponent = (
    <div
      ref={selectRef}
      className={classes.selectBox}
      onClick={() => onClick && onClick()}
    >
      <div className={classes.title}>{title}</div>
      <div className={classes.wrapper}>
        <div
          onClick={() => {
            if (setOpen) setOpen(!open);
          }}
          className={clsx(classes['main-input'], open && classes.dropActive)}
        >
          <Input
            className={classes.drop}
            readOnly={true}
            placeholder={placeholder}
            value={select?.name || defaultValue}
          />
          <div className={clsx(classes.arrow, open && classes.arrowActive)}>
            <ArrowDownIcon />
          </div>
        </div>
        {open && (
          <div className={classes.dropdown}>
            <div className={classes.list}>
              {options &&
                options?.map((item: SelectData, index: number) => (
                  <div
                    key={`${item.id}${index}`}
                    onClick={() => {
                      if (setOpen) setOpen(false);
                      if (item?.id !== select?.id) {
                        if (onSelect) onSelect(item);
                        if (setSelect) setSelect(item);
                      }
                    }}
                    className={clsx(
                      classes.item,
                      (item?.name === select?.name || item.id === select?.id) &&
                        classes.active
                    )}
                  >
                    <span>{item?.name}</span>
                    {item?.id === select?.id && (
                      <UiIcon
                        name="CheckIcon"
                        additionalClassName={classes.checkIcon}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const mobileSelect =
    open || isClosing ? (
      <>
        <div
          className={clsx(classes.overlay, { [classes.close]: isClosing })}
          onClick={() => {
            setOpen && setOpen(!open);
            handleClose();
          }}
        />
        <div
          className={clsx(classes.fixedButton, { [classes.close]: isClosing })}
        >
          {!isSupervisor ? (
            <div className={classes.title}>
              <div>{title}</div>
              <UiIcon
                name="CrossIcon"
                onClick={() => {
                  setOpen && setOpen(!open);
                  handleClose();
                }}
              />
            </div>
          ) : (
            <HeaderButtons
              name={title || ''}
              handleCloseModal={() => {
                handleClose();
                setIsClicked &&
                  setIsClicked({ role: false, businessUnit: false });
              }}
              handleBackToMenu={() =>
                setIsClicked
                  ? setIsClicked({ role: false, businessUnit: false })
                  : null
              }
            />
          )}
          <div className={classes.items}>
            {options?.map((item: SelectData, index: number) => (
              <Button
                type="button"
                key={`${item.id}${index}`}
                className={clsx(
                  classes.button,
                  item?.id === select?.id && classes.active
                )}
                onClick={() => {
                  onClick && onClick();
                  setOpen && setOpen(!open);
                  if (item?.id !== select?.id) {
                    if (onSelect) onSelect(item);
                    if (setSelect) setSelect(item);
                  }
                  handleClose();
                  setIsClicked &&
                    setIsClicked({ role: false, businessUnit: false });
                }}
              >
                {item?.name}
                {item?.id === select?.id && (
                  <UiIcon
                    name="CheckIcon"
                    additionalClassName={classes.checkIcon}
                  />
                )}
              </Button>
            ))}
          </div>
        </div>
      </>
    ) : null;

  return isMobile ? mobileSelect : selectComponent;
};

export default Select;
