import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Request } from 'tools/request';
import { ShowNotification } from 'tools/showNotification';
import { FormInputsPersonal } from 'types/auth';
import { personalSchema } from '../validationSchemas';
import { DefaultValue } from './Personal.props';
import { AxiosError } from 'axios';
import { PARTNER_ROLE } from 'types/user';
import { userStore } from 'stores/user-store';
import { TFunction } from 'i18next';
import { useWindowWidth } from 'hooks/useWindowWidth';

export const helperTextForRole = (
  role: PARTNER_ROLE,
  t: TFunction,
  isSupervisor?: boolean
) => {
  switch (role) {
    case PARTNER_ROLE.ADMIN:
      return t(
        'All platform sections are available, including employee invitations and access level management'
      );
    case PARTNER_ROLE.MANAGER:
      return t(
        'Access to viewing and ordering Solutions, managing Projects, and viewing Dashboards is available. No access to managing Finances or inviting employees'
      );
    case PARTNER_ROLE.VIEWER:
      return t(
        'Only viewing of Solutions, Projects, and Dashboards is available'
      );
    case PARTNER_ROLE.SUPERVISOR:
      return t(
        'Viewing, editing and moderation of Solutions, Project management, and information about business units are available'
      );

    default:
      return '';
  }
};

export const usePersonal = (
  defaultValue: DefaultValue,
  getParams: () => Promise<void>
) => {
  const { t, i18n } = useTranslation();
  const { isMediaTablet } = useWindowWidth();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputsPersonal>({
    resolver: yupResolver(personalSchema as any),
    mode: 'onChange',
    defaultValues: {
      fullName: defaultValue.fullName,
      email: defaultValue.email,
      phone: defaultValue.phone,
    },
  });

  const [cookies, setCookies] = useCookies(['access', 'refresh']);
  const formRef = useRef(null);

  const [fullName, setFullName] = useState<string>(defaultValue.fullName);
  const [phone, setPhone] = useState<string | null>(defaultValue.phone);

  const [disableSubmit, setDisableSubmit] = useState(true);

  const partner = userStore.partners?.results?.[0];

  const { isSupervisor } = userStore;

  const email = defaultValue?.email;

  const bottomHeightMobile = isMediaTablet
    ? {
        bottom:
          (window?.screen?.availHeight -
            document?.documentElement?.clientHeight) /
            2 +
          25,
      }
    : {};

  const onSubmit = async () => {
    const formValues = new FormData();

    formValues.append('full_name', fullName);
    formValues.append('phone_number', `+${phone}`);

    try {
      const result = await Request({
        type: 'PUT',
        url: `${process.env.REACT_APP_API}/api/v1/partners/users/`,
        isProtected: true,
        access: cookies.access,
        data: formValues,
      });
      if (result.status !== 400) {
        getParams();

        setDisableSubmit(false);
        ShowNotification({
          type: 'success',
          title: t('Personal information changed'),
          children: t(
            'Your information has been updated. Please review to ensure accuracy'
          ),
        });
      }
    } catch (e: unknown) {
      await getParams();

      if (e instanceof AxiosError) {
        const result_data = e.response?.data;
        if ('phone_number' in result_data) {
          setDisableSubmit(true);
        } else {
          setDisableSubmit(true);
          ShowNotification({
            type: 'error',
            children: t('Something went wrong'),
          });
        }
      }
    }
  };

  useEffect(() => {
    if (fullName !== '' && !errors.fullName && phone && phone.length > 10) {
      if (fullName !== defaultValue.fullName || phone !== defaultValue.phone) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    } else {
      setDisableSubmit(true);
    }
  }, [errors?.fullName, fullName, phone]);

  return {
    t,
    i18n,
    formRef,
    handleSubmit,
    onSubmit,
    control,
    errors,
    setFullName,
    email,
    disableSubmit,
    setPhone,
    partner,
    isValid,
    isSupervisor,
    bottomHeightMobile,
  };
};
