import React, { FC, useEffect } from 'react';
import '../styles/main.scss';
import { useTranslation } from 'react-i18next';
import { AddSolution } from '../containers/AddSolution';
import { PARTNER_ROLE } from 'types/user';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../stores/user-store';

interface AddSolutionPageProps {
  isModeratePage?: boolean;
  isChangePage?: boolean;
}

export const AddSolutionPage: FC<AddSolutionPageProps> = ({
  isModeratePage,
  isChangePage,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const partnerPermission = userStore?.partners?.results?.[0]?.permission?.type;

  const isViewer = partnerPermission === PARTNER_ROLE.VIEWER;

  useEffect(() => {
    if (isViewer) {
      navigate('/solutions');
    }
  }, []);

  return (
    <AddSolution
      title={
        isModeratePage
          ? t('Moderating solution')
          : isChangePage
            ? t('Changing solution')
            : t('Adding new Solution')
      }
      isModeratePage={isModeratePage}
      isChangePage={isChangePage}
    />
  );
};
