import { ProjectsDetailContainer } from 'containers/ProjectsDetail';
import { FC } from 'react';
import '../styles/main.scss';
import { useProjectsStore } from '../contexts/ProjectsContext';
import { NotFoundPage } from './NotFoundPage';

export const ProjectsDetail: FC = () => {
  const projectsStore = useProjectsStore();

  return projectsStore.isProjectError ? (
    <NotFoundPage />
  ) : (
    <ProjectsDetailContainer />
  );
};
