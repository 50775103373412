import {
  usePagination,
  useTable,
  useSortBy,
  TableCellProps,
  ColumnInstance,
} from 'react-table';
import classes from './TableEmployees.module.scss';
import clsx from 'classnames';
import { Fader } from 'components/shared/Fader';
import {
  TableCellCustomProps,
  TableEmployeesProps,
} from './TableEmployees.props';
import { useTranslation } from 'react-i18next';
import { PARTNER_ROLE } from 'types/user';
import RoleCell from './RoleCell/RoleCell';
import MenuCell from './MenuCell/MenuCell';
import { Pagination } from 'components/shared/Pagination';
import React, { useMemo, useRef, useState } from 'react';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { userStore } from 'stores/user-store';
import { partnerStore } from 'stores/partnerStore';
import { CopyIcon } from 'components/shared/Icons/CopyIcon';
import { handleCopy } from 'tools/utils';

const TableEmployees = ({
  businessUnits,
  data,
  columns,
  additionalClassNames,
  id,
  partner,
  handleDeletePermissions,
  handleChangePermissions,
}: TableEmployeesProps) => {
  const { t } = useTranslation();
  const { width, isMediaTablet } = useWindowWidth();

  const [search, setSearch] = useState<string>('');
  const [copiedId, setCopiedId] = useState<string | null>(null);
  const [isClicked, setIsClicked] = useState<string | undefined>('');

  const showTooltip = (id: string) => {
    setCopiedId(id);
    setTimeout(() => setCopiedId(null), 2000);
  };

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startPress = (value: string) => {
    setIsClicked(value);
    timerRef.current = setTimeout(() => {
      showTooltip(`name-${value}`);
      handleCopy(value);
    }, 1000);
  };

  const stopPress = () => {
    setIsClicked('');
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const filteredData = useMemo(() => {
    if (!search) return data;
    return data.filter((row: any) =>
      row?.user?.value?.toLowerCase().includes(search.toLowerCase())
    );
  }, [data, search]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data: filteredData, initialState: { pageSize: 8 } },
    useSortBy,
    usePagination
  );

  const renderFilterByHeader = (header: string) => {
    switch (header) {
      default:
        return null;
    }
  };

  const mainContent = (value: TableCellProps & TableCellCustomProps) => {
    if (value?.isEmail)
      return (
        <div
          className={classes.table__header_email}
          onMouseDown={() => {
            value?.value && setIsClicked(value?.value);
          }}
          onMouseUp={() => {
            setIsClicked('');
          }}
          onTouchStart={() => {
            value?.value && startPress(value?.value);
          }}
          onTouchEnd={() => {
            stopPress();
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <span
            className={clsx(
              classes.table__header_email_text,
              isClicked === value.value &&
                classes.table__header_email_text_active
            )}
            style={(!value.id && { opacity: 0.4 }) || undefined}
          >
            {value.value}
          </span>
          <CopyIcon
            viewBox="0, 0, 16, 16"
            onClick={() => {
              if (value?.value) {
                handleCopy(value.value);
                showTooltip(`name-${value.value}`);
              }
            }}
          />
          {copiedId === `name-${value.value}` && (
            <div className={classes.tooltip}>{t('Email is copied')}</div>
          )}
        </div>
      );
    return value?.value;
  };

  const { user, isSupervisor } = userStore;

  return (
    <Fader active={false}>
      <div
        className={clsx(
          classes.table,
          additionalClassNames && String(additionalClassNames)
        )}
        id={id}
      >
        <table {...getTableProps()} className={classes.table__wrapper}>
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={clsx(
                  classes.table__row,
                  isSupervisor && classes.table__row__supervisor
                )}
              >
                <th className={classes.table__cell}>
                  {/*<FilterSearch
                    classNameForButton={classes.table__search}
                    buttonElement={<SearchIcon />}
                    usePortal
                  >
                    {() => (
                      <>
                        <p className={classes.search__description}>
                          {t('Search by table')}
                        </p>
                        <NewInput
                          classNames={classes.search__input}
                          onChange={setSearch}
                          type={'text'}
                          placeholder={t('Enter value...')}
                          value={search}
                        />
                      </>
                    )}
                  </FilterSearch>*/}
                </th>

                {headerGroup?.headers?.map((column: ColumnInstance<{}>) => {
                  if (column.render('header'))
                    return (
                      <th
                        className={classes.table__cell}
                        {...column.getHeaderProps()}
                      >
                        {t(column.render('header') as string)}

                        {renderFilterByHeader(
                          column.render('header') as string
                        )}
                      </th>
                    );

                  return null;
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page?.map((row) => {
              prepareRow(row);

              return (
                <tr
                  {...row.getRowProps()}
                  className={clsx(
                    classes.table__content,
                    isSupervisor && classes.table__content__supervisor
                  )}
                >
                  {row?.cells?.map((cell) => {
                    const { value } = cell;
                    const isClient = value.id === user.id;
                    const isAdmin =
                      partner.permission.type === PARTNER_ROLE.ADMIN;

                    const isDisabledChangeRole =
                      (!isSupervisor && !isAdmin) ||
                      isClient ||
                      !partnerStore.allowInvite;

                    const isShowMenu = value?.isMenu && isAdmin && !isClient;
                    const isShowMenuForSuperVisor =
                      value?.isMenu && isSupervisor;

                    return (
                      <td
                        {...cell.getCellProps()}
                        className={clsx(
                          classes.table__cell,
                          isSupervisor &&
                            isMediaTablet &&
                            classes.table__cell__supervisor
                        )}
                      >
                        <div className={classes.table__cell__container}>
                          <div className={classes.wrapperHeader}>
                            <div
                              className={clsx(
                                classes.table__header,
                                value.isName && classes.table__header_name
                              )}
                              style={
                                value.id || value?.isEmail
                                  ? { maxWidth: width - 70 }
                                  : { opacity: 0.4, maxWidth: width - 75 }
                              }
                            >
                              {mainContent(value)}
                            </div>
                            {value.isName && isClient && (
                              <span className={classes.you}>({t('you')})</span>
                            )}
                          </div>

                          {value?.label && (
                            <RoleCell
                              key={value.id}
                              value={value}
                              isDisabledChangeRole={isDisabledChangeRole}
                              handleChangePermissions={handleChangePermissions}
                            />
                          )}

                          {(isShowMenu || isShowMenuForSuperVisor) &&
                            partnerStore.allowInvite && (
                              <MenuCell
                                data={businessUnits}
                                key={value.id}
                                value={value}
                                isDisabledChangeRole={isDisabledChangeRole}
                                handleDeletePermissions={
                                  handleDeletePermissions
                                }
                                handleChangePermissions={
                                  handleChangePermissions
                                }
                                isSupervisor={isSupervisor}
                              />
                            )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {pageCount > 1 && (
          <div className={classes.pagination}>
            <Pagination
              currentPage={pageIndex + 1}
              totalPages={pageCount}
              setPageNumber={(page) => gotoPage(page - 1)}
              nextPage={canNextPage ? nextPage : undefined}
              prevPage={canPreviousPage ? previousPage : undefined}
            />
          </div>
        )}
      </div>
    </Fader>
  );
};

export default TableEmployees;
