import React from 'react';
import classes from '../EmployeesInvite.module.scss';
import { Controller } from 'react-hook-form';
import NewInput from 'components/shared/NewInput';
import { OptionTypeString } from 'types/util-types';
import { Radio } from 'components/shared/Radio';
import { Button } from 'components/shared/Button';
import { ModalMobile } from 'components/shared/ModalMobile';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import HeaderButtons from '../../TableEmployees/MenuCell/MobileModals/HeaderButtons';

interface AdminMobileInviteProps {
  showInviteModal: boolean;
  handleCloseInviteModal: () => void;
  onSubmit: (data: any, errors?: any) => void;
  control: any;
  errors: any;
  employeesRoles: OptionTypeString[];
  t: TFunction;
  emailValue: string;
  isSupervisor?: boolean;
  setIsClicked?: React.Dispatch<
    React.SetStateAction<{ role: boolean; businessUnit: boolean }>
  >;
  formValues?: any;
  trigger?: any;
}

const AdminMobileInvite = observer(
  ({
    showInviteModal,
    handleCloseInviteModal,
    onSubmit,
    control,
    errors,
    employeesRoles,
    t,
    emailValue,
    isSupervisor,
    setIsClicked,
    formValues,
    trigger,
  }: AdminMobileInviteProps) => {
    return (
      <ModalMobile
        title=""
        isOpen={showInviteModal}
        onClose={handleCloseInviteModal}
        isBasicHeader={false}
        crossIcon={!isSupervisor}
      >
        <div className={classes.inner}>
          {isSupervisor ? (
            <HeaderButtons
              name={t('Role table')}
              handleCloseModal={async () => {
                trigger && (await trigger());
                handleCloseInviteModal();
                setIsClicked &&
                  setIsClicked({ role: false, businessUnit: false });
              }}
              handleBackToMenu={async () => {
                trigger && (await trigger());
                setIsClicked
                  ? setIsClicked({ role: false, businessUnit: false })
                  : null;
              }}
            />
          ) : (
            <div className={classes.header}>
              <h3 className={classes.title}>{t('Invitation')}</h3>
            </div>
          )}

          {isSupervisor ? null : (
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <NewInput
                  {...field}
                  classNames={classes.input}
                  type="text"
                  title={t('Employee work email')}
                  placeholder={t('Employee work email')}
                  error={!!errors.email?.message}
                  maxLength={50}
                />
              )}
            />
          )}

          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <div className={classes.radioGroup}>
                {employeesRoles.map((role: OptionTypeString) => (
                  <Radio
                    key={role.id}
                    name="role"
                    value={role}
                    checked={field.value?.id === role.id}
                    onChange={async (item) => {
                      field.onChange(item);
                      setIsClicked &&
                        setIsClicked({ role: false, businessUnit: false });
                      trigger && (await trigger('role'));
                    }}
                    label={role.name ? t(role.name) : ''}
                    description={role.description ? t(role.description) : ''}
                  />
                ))}
              </div>
            )}
          />

          {isSupervisor ? null : (
            <Button
              type="button"
              className={classes.button}
              theme="primary"
              size="middle"
              disabled={emailValue.length === 0}
              onClick={(e) => {
                e.preventDefault();
                onSubmit(formValues, errors);
              }}
            >
              {t('Invite employee')}
            </Button>
          )}
        </div>
      </ModalMobile>
    );
  }
);

export default AdminMobileInvite;
