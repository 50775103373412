import classes from './Employees.module.scss';
import { TFunction } from 'i18next';
import { getLangName } from 'tools/utils';
import i18n from 'i18n/i18n';

export const columnsArrayEmployees = (isSupervisor?: boolean) => {
  const columns = [
    {
      header: '',
      accessor: 'serialNumber',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: 'Name table',
      accessor: 'user',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: 'Work email table',
      accessor: 'email',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: 'Role table',
      accessor: 'role',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: 'Menu_empty',
      accessor: 'menu',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
  ];

  if (isSupervisor) {
    columns.splice(2, 0, {
      header: 'Business-unit',
      accessor: 'businessUnit',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    });
  }

  return columns;
};

export const tableDataEmployees = (
  users: any[],
  t: TFunction,
  isSupervisor?: boolean
) =>
  users?.map((user, index) => ({
    key: `user-${user.id}`,
    id: user.id,
    serialNumber: {
      id: index + 1,
      value: index + 1,
      className: classes.table__header,
    },
    user: {
      id: user.id,
      value: user.full_name ? t(user.full_name) : t('Invite sent'),
      className: classes.table__header,
      description: {
        text: t(user.full_name),
        className: classes.table__text,
      },
      isName: true,
    },
    email: {
      id: user.id,
      value: user.email,
      className: classes.table__header,
      description: {
        text: user.email,
        className: classes.table__text,
      },
      isEmail: true,
    },
    role: {
      email: user.email,
      id: user.id,
      label: getLangName(user.permission, 'name', i18n),
      type: user.permission.type,
      className: classes.table__header,
      partnerId: isSupervisor && user?.partner_id,
    },
    menu: {
      id: user?.id,
      isMenu: true,
      full_name: user?.full_name,
      email: user?.email,
      permission: user?.permission,
      className: classes.table__header,
      partnerName: isSupervisor && user?.partner_name,
      partnerId: isSupervisor && user?.partner_id,
    },
    businessUnit: {
      id: user.id,
      value: user?.partner_name,
      partnerId: user?.partner_id,
    },
  }));
