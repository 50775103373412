import api from './http';
import { AxiosResponse } from 'axios';
import { 
  CompanyProduct, 
  Country, 
  CrmProduct, 
  DevicePlatform 
} from '@type/common';
import { ListResponse } from '@type/api';

export default class Common {
  static async getCountries<TData = ListResponse<Country>>(params?: {
    all?: boolean;
    page?: number;
    page_size?: number;
  }): Promise<TData> {
    const response = await api<TData>({
      url: '/api/v1/countries/',
      method: 'get',
      params,
    });

    return response.data;
  }

  static async getCaseKpi(): Promise<AxiosResponse> {
    return api.get(`/api/v1/cases/get_case_kpi/`);
  }

  static async getCompanyProducts(params?: {
    page?: number;
  }): Promise<AxiosResponse<ListResponse<CompanyProduct>>> {
    return api<ListResponse<CompanyProduct>>({
      url: '/api/v1/products/',
      method: 'get',
      params,
    });
  }

  static async getPlatforms(): Promise<
    AxiosResponse<ListResponse<DevicePlatform>>
  > {
    return await api.get<ListResponse<DevicePlatform>>('/api/v1/platforms/');
  }

  static async getDictionaries(): Promise<AxiosResponse> {
    return api.get(`/api/v1/dictionaries/`);
  }

  static async getCrmProducts(params?: string): Promise<AxiosResponse<ListResponse<CrmProduct>>> {
    const query = params ? `?${params}` : '';
    return api.get<ListResponse<CrmProduct>>(`/api/v1/partners/crm-products/${query}`);
  }
}

