import clsx from 'clsx';
import React from 'react';
import { Button } from 'components/shared/Button';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import UiIcon from 'components/shared/Icon';
import ArrowUpRight from 'components/shared/Icons/ArrowUpRight';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { NavLink } from 'react-router-dom';
import MobileModal from '../Mobile';
import { percentNumber } from '../utils';
import classes from './Card.module.scss';
import { useCard } from './useCard';
import { useState } from 'react';
import { PARTNER_ROLE } from 'types/user';
import { userStore } from 'stores/user-store';
import { FILTERS_LABELS } from '../../../constants/enum';
import { RUSSIAN_LOCALE } from 'utils';

export const PartnerCard = ({
  solution,
  cardHit = '',
}: {
  solution: any;
  cardHit?: string;
}) => {
  const {
    mobileModalDataPrice,
    isTablet,
    tagClass,
    tagText,
    t,
    dropdownRef,
    open,
    setOpen,
    onCopy,
    isNoneImpressions,
    isNoneFavorites,
    isNoneCart,
    percentDifference,
    mobileModalData,
    isSupervisor,
    onArchive,
    isAdmin,
  } = useCard(solution);

  const [openPriceTag, setOpenPriceTag] = useState(false);
  const [selected, setSelected] = useState<any>(null);
  const { name, link } = solution?.source || {};
  const title = solution?.name;
  const isLongDesc = solution.data?.price?.period?.length > 10;
  const currency = solution?.currency?.symbol;
  const isPartnerViewer =
    userStore?.partners?.results?.[0]?.permission?.type === PARTNER_ROLE.VIEWER;

  const separateIcon = (
    <span className={classes.card__top__bottom__separate}></span>
  );

  const desktopModalData = mobileModalDataPrice?.map(
    (item: any, index: number) => (
      <span key={`${item.id}${index}`} className={classes.tooltipItem}>
        {item?.name}
        <span className={classes.price}>{item?.value}</span>
      </span>
    )
  );

  const isEditable =
    solution?.status !== FILTERS_LABELS.IN_REVIEW &&
    solution?.status !== FILTERS_LABELS.ARCHIVED;

  const isReviewable = solution?.status === FILTERS_LABELS.IN_REVIEW;

  const partnerLevel = userStore?.partners?.results?.[0]?.level;
  const isExternal = partnerLevel === 'EXTERNAL';
  const hasCrmProduct = !!solution?.product_crm_id;

  return (
    <div
      className={clsx(
        classes.card,
        !hasCrmProduct &&
          !isExternal &&
          RUSSIAN_LOCALE &&
          classes.card__noCrmProduct
      )}
      {...(cardHit ? { [cardHit]: true } : {})}
    >
    {/* <div className={classes.card} {...(cardHit ? { [cardHit]: true } : {})}> */}
      <div className={classes.card__top}>
        <div className={classes.card__top__left}>
          {isTablet && (
            <div className={clsx(classes.card__tag, tagClass)}>{tagText}</div>
          )}
          <NavLink to={link} className={classes.card__link}>
            <h3 className={classes.card__header}>{solution.name}</h3>
          </NavLink>
          <div className={classes.card__description}>
            {!isTablet && (
              <div className={clsx(classes.card__tag, tagClass)}>{tagText}</div>
            )}
            <div className={classes.card__top__bottom}>
              {!isTablet && separateIcon}
              {!isTablet && (
                <span className={classes.card__id}>{t('Type of product')}</span>
              )}
              <span className={classes.card__source__mobile}>{name}</span>
              {solution?.id && separateIcon}
              {solution?.id && (
                <div className={classes.card__id}>ID {solution.id}</div>
              )}
              {isSupervisor && solution?.partner_name && separateIcon}
              {isSupervisor && solution?.partner_name && (
                <div className={classes.card__source__mobile}>
                  {solution.partner_name}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={classes.card__top__right} ref={dropdownRef}>
          {
            // TODO: Add permission to admin (isSupervisor || isAdmin)
            isSupervisor &&
              !isTablet &&
              solution.status !== FILTERS_LABELS.ARCHIVED && (
                <Button
                  theme="light"
                  className={classes.moderateBtn}
                  onClick={() => onCopy(solution, isReviewable, isEditable)}
                >
                  {isReviewable ? t('Moderate') : t('Change')}
                </Button>
              )
          }

          <Button
            theme="light"
            className={classes.moreButton}
            disabled={isPartnerViewer}
            onClick={() => !isPartnerViewer && setOpen(!open)}
          >
            <UiIcon
              name="DotIcon"
              additionalClassName={classes.moreButton__dotIcon}
            />
          </Button>

          {open && !isTablet && (
            <div className={classes.dropdown}>
              <span
                className={classes.dropdown__item}
                onClick={() =>
                  !isSupervisor
                    ? onCopy(solution)
                    : onArchive(solution?.source?.link)
                }
              >
                {!isSupervisor
                  ? t('Copy')
                  : solution?.status === FILTERS_LABELS.ARCHIVED
                    ? t('Unarchive')
                    : t('Archive')}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className={classes.card__grid}>
        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>
            {solution?.data.impressions?.name}
          </div>
          <div className={classes.content}>
            <div className={classes.content__inner}>
              <span className={classes.dataCount}>
                {solution?.data.impressions?.count}
              </span>
              <div
                className={clsx(
                  classes.percentage,
                  percentNumber({
                    decrease: solution?.data.impressions?.percentage,
                  }) && classes.down,
                  isNoneImpressions && classes.none
                )}
              >
                {!isNoneImpressions && (
                  <ArrowUpRight className={clsx(classes.arrowIcon)} />
                )}
                {!isNoneImpressions &&
                  percentNumber({
                    percentage: solution?.data.impressions?.percentage,
                  })}
              </div>
            </div>
            <div className={classes.bottomDescription}>
              {solution?.data.impressions?.description}
            </div>
          </div>
        </div>

        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>
            {solution?.data.favorites?.name}
          </div>
          <div className={classes.content}>
            <div className={classes.content__inner}>
              <span className={classes.dataCount}>
                {solution?.data.favorites?.count}
              </span>
              <div
                className={clsx(
                  classes.percentage,
                  percentNumber({
                    decrease: solution?.data.favorites?.percentage,
                  }) && classes.down,
                  isNoneFavorites && classes.none
                )}
              >
                {!isNoneFavorites && (
                  <ArrowUpRight className={classes.arrowIcon} />
                )}
                {!isNoneFavorites &&
                  percentNumber({
                    percentage: solution?.data.favorites?.percentage,
                  })}
              </div>
            </div>
            <div className={classes.bottomDescription}>
              {solution?.data.favorites?.description}
            </div>
          </div>
        </div>

        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>
            {solution?.data.cart?.name}
          </div>
          <div className={classes.content}>
            <div className={classes.content__inner}>
              <span className={classes.dataCount}>
                {solution?.data.cart?.count}
              </span>
              <div
                className={clsx(
                  classes.percentage,
                  percentNumber({
                    decrease: solution?.data.cart?.percentage,
                  }) && classes.down,
                  isNoneCart && classes.none
                )}
              >
                {!isNoneCart && (
                  <ArrowUpRight className={clsx(classes.arrowIcon)} />
                )}
                {!isNoneCart &&
                  percentNumber({
                    percentage: solution?.data.cart?.percentage,
                  })}
              </div>
            </div>
            <div className={classes.bottomDescription}>
              {solution?.data.cart?.description}
            </div>
          </div>
        </div>

        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>
            <span>{solution?.data.price?.name}</span>
            {solution?.data.price?.discountCost && percentDifference > 0 ? (
              <div
                className={classes.priceTag}
                onClick={() => isTablet && setOpenPriceTag(!openPriceTag)}
              >
                {!isTablet ? (
                  <TooltipContainer
                    text={desktopModalData}
                    position="left"
                    classNameTooltip={classes.priceTooltip}
                  >
                    {percentDifference}%
                  </TooltipContainer>
                ) : (
                  <span>{percentDifference}%</span>
                )}
              </div>
            ) : null}
          </div>

          <div className={classes.content}>
            <div className={clsx(classes.content__inner, classes.priceBox)}>
              <span className={classes.dataCount}>
                <FormatPrice
                  currency={currency}
                  price={solution?.data.price?.discountCost}
                />
              </span>
              {solution?.data.price?.period && !solution?.isFree && (
                <div
                  className={clsx(
                    classes.percentage,
                    classes.price,
                    isLongDesc && classes.price__long
                  )}
                >
                  {!isTablet ? '/' : ''} {solution?.data?.price?.period}
                </div>
              )}
            </div>

            <div className={classes.bottomDescription}>
              {solution?.data?.price?.description}
            </div>
          </div>
        </div>
      </div>

      {isTablet && openPriceTag && (
        <MobileModal
          open={openPriceTag}
          setOpen={setOpenPriceTag}
          title={title}
          data={mobileModalDataPrice}
          isPriceTag
        />
      )}

      {isTablet && open && (
        <MobileModal
          select={selected}
          setSelect={setSelected}
          open={open}
          setOpen={setOpen}
          title={title}
          data={mobileModalData}
          onSelect={async (item: any) => {
            if (item?.id === 2) {
              await onCopy(solution);
            } else if (item?.id === 3) {
              await onCopy(solution, false, true);
            } else if (item?.id === 1) {
              await onArchive(solution?.source?.link);
            } else if (item?.id === 4) {
              await onCopy(solution, true);
            }
          }}
        />
      )}
    </div>
  );
};
