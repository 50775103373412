import { useEffect, useMemo, useState } from 'react';
import { MultiSelectOption } from '../../components/shared/MultiSelect';
import { onDelete, onSelect } from '../AddSolution/utils';
import { BusinessUnit } from 'types/user';
import { getLangName } from 'tools/utils';
import { userStore } from 'stores/user-store';
import { useTranslation } from 'react-i18next';

export const useProjects = () => {
  const businessUnits = userStore?.businessUnits;
  const { t, i18n } = useTranslation();

  const [selectedBUnits, setSelectedBUnits] = useState([]);
  const [internalBUnitOptions, setInternalBUnitOptions] = useState<
    MultiSelectOption[]
  >([]);
  const [openMobileFilterBUnits, setOpenMobileFilterBUnits] = useState(false);

  const internalBusinessUnits = useMemo(
    () =>
      businessUnits?.map((item: BusinessUnit) => ({
        value: item.id,
        label: getLangName(item, 'name', i18n),
      })),
    [businessUnits, i18n]
  );

  useEffect(() => {
    internalBusinessUnits && setInternalBUnitOptions(internalBusinessUnits);
  }, [internalBusinessUnits]);

  const callbacks = {
    onSelectBUnits: (id: number | string, onChange?: any) =>
      onSelect(id, internalBusinessUnits, setSelectedBUnits as any, onChange),
    onDeleteBunits: (id: number | string, onChange?: any) =>
      onDelete(id, setSelectedBUnits as any, onChange),
  };

  const buttonTextOptions = {
    onCancel: () => {
      callbacks.onDeleteBunits('all');
    },
    cancel: t('Reset business units'),
    submit: t('Show solutions'),
    onSubmit: () => {},
  };

  return {
    selectedBUnits,
    internalBUnitOptions,
    callbacks,
    openMobileFilterBUnits,
    setOpenMobileFilterBUnits,
    buttonTextOptions,
  };
};
