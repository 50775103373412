import { DotsIcon } from 'components/shared/Icons/DotsIcon';
import classes from './MenuCell.module.scss';
import FilterSearch from 'components/shared/FilterSearch/FilterSearch';
import { useWindowWidth } from 'hooks/useWindowWidth';
import React, { useMemo, useState } from 'react';
import { ModalMobile } from 'components/shared/ModalMobile';
import { MenuCellProps } from './MenuCell.props';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared/Button';
import { getEmployeesRoles } from '../../employeesRoles';
import { OptionTypeString } from 'types/util-types';
import { PARTNER_ROLE } from 'types/user';
import clsx from 'clsx';
import AdminEmployeeMobileModal from './MobileModals/AdminMobileModal';
import SupervisorMobileModal from './MobileModals/SupervisorMobileModal';

const MenuCell = ({
  data,
  value,
  handleDeletePermissions,
  handleChangePermissions,
  isDisabledChangeRole,
  isSupervisor,
}: MenuCellProps) => {
  const { width } = useWindowWidth();
  const { t, i18n } = useTranslation();

  const employeesRoles = useMemo(() => getEmployeesRoles(), [i18n.language]);

  const [isShowMenuModal, setIsShowMenuModal] = useState(false);
  const handleShowMenuModal = () => {
    setIsShowMenuModal(true);
  };
  const handleCloseMenuModal = () => {
    setIsShowMenuModal(false);
  };

  const [isShowDotsMenu, setShowDotsMenu] = useState(false);
  const handleShowDotsModal = () => {
    setShowDotsMenu(true);
  };
  const handleCloseDotsModal = () => {
    setShowDotsMenu(false);
  };

  const handleClose = () => {
    handleCloseDotsModal();
    handleCloseMenuModal();
    close();
  };

  const [typeUser, setTypeUser] = useState<PARTNER_ROLE>(
    value.permission?.type
  );
  const [typeBusinessUnit, setTypeBusinessUnit] = useState<OptionTypeString>(
    value?.id
  );
  const getBusinessUnitName = (id: number) => {
    return value?.partnerId && value.value;
  };

  const removeText = () => {
    if (isSupervisor) {
      return `${t('Remove {{ name }} from Partner', {
        name: value.full_name,
      })} ${value?.partnerName}?`;
    } else if (value?.full_name) {
      return t('Remove {{ name }} from Account?', {
        name: value.full_name,
      });
    } else {
      return (
        <>
          {t('Remove')} <a className={classes.emailLink}>{value.email}</a>{' '}
          {t('from Account?')}
        </>
      );
    }
  };

  return (
    <>
      <div className={classes.dots}>
        {width <= 960 ? (
          <DotsIcon onClick={() => handleShowMenuModal()} />
        ) : (
          <FilterSearch
            classNameForButton={classes.table__search}
            buttonElement={<DotsIcon />}
            isLastFilter={true}
          >
            {() => (
              <div onClick={handleShowDotsModal} className={classes.delete}>
                {t('Delete')}
              </div>
            )}
          </FilterSearch>
        )}
      </div>

      {isSupervisor ? (
        <SupervisorMobileModal
          data={data}
          value={value}
          isShowMenuModal={isShowMenuModal}
          handleCloseMenuModal={handleCloseMenuModal}
          isDisabledChangeRole={isDisabledChangeRole}
          handleChangePermissions={handleChangePermissions}
          handleClose={handleClose}
          handleShowDotsModal={handleShowDotsModal}
          typeUser={typeUser}
          setTypeUser={setTypeUser}
          businessUnit={typeBusinessUnit}
          setBusinessUnit={setTypeBusinessUnit}
          employeesRoles={employeesRoles}
          t={t}
        />
      ) : (
        <AdminEmployeeMobileModal
          value={value}
          isShowMenuModal={isShowMenuModal}
          handleCloseMenuModal={handleCloseMenuModal}
          isDisabledChangeRole={isDisabledChangeRole}
          handleChangePermissions={handleChangePermissions}
          handleClose={handleClose}
          handleShowDotsModal={handleShowDotsModal}
          typeUser={typeUser}
          setTypeUser={setTypeUser}
          employeesRoles={employeesRoles}
        />
      )}

      {
        <ModalMobile
          title=""
          isOpen={isShowDotsMenu}
          onClose={handleCloseDotsModal}
          isBasicHeader={false}
          isOnCenter
          crossIcon={false}
          noPadding={true}
        >
          <div className={clsx(classes.inner, classes.innerDelete)}>
            <div className={clsx(classes.headerWrapper)}>
              <h3 className={classes.titleRemove}>{removeText()}</h3>
              <p className={classes['title-description-remove']}>
                {isSupervisor
                  ? t(
                      'The employee will lose access to the Partner. This action cannot be undone'
                    )
                  : t(
                      'Employee will no longer have access to Account. This action cannot be undone'
                    )}
              </p>
            </div>

            <div className={classes.buttons}>
              <Button
                className={classes.button}
                theme="primary"
                size="middle"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCloseDotsModal();
                }}
              >
                {t('Cancel')}
              </Button>
              <Button
                className={classes.button}
                theme="primary-light"
                size="middle"
                onClick={async (e) => {
                  e.stopPropagation();
                  await handleDeletePermissions(
                    value.id,
                    value.email,
                    value?.partnerId
                  );
                  handleClose();
                }}
              >
                {isSupervisor ? t('Confirm') : t('Continue')}
              </Button>
            </div>
          </div>
        </ModalMobile>
      }
    </>
  );
};

export default MenuCell;
