import React, { useEffect, useMemo, useState } from 'react';
import exampleCaseImage from 'assets/img/Partner/emptyCaseImage.png';
import exampleCaseImageRu from 'assets/img/Partner/emptyCaseImageRu.png';
import exampleCaseImageEs from 'assets/img/Partner/exampleCaseImageEs.png';
import { Button } from 'components/shared/Button';
import UiIcon from 'components/shared/Icon';
import { PurpleArrowLeftIcon } from 'components/shared/Icons/PurpleArrowLeftIcon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RUSSIAN_LOCALE } from 'utils';
import classes from '../../FormBlock.module.scss';
import { EmptyCaseProps } from './EmptyCase.props';
import { useFormContext } from 'react-hook-form';
import { usePartnerStore } from 'contexts/PartnerContext';
import ExistingCases from '../ExistingCase';
import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import { Case, CaseKPI } from '@type/partner';
import { ExistingCase } from '../ExistingCase/ExistingCases.props';
import { FormValidationProps } from '../../FormBlock.props';
import clsx from 'clsx';

const EmptyCase = ({
  handleExampleLoaded,
  disabled,
  addStep,
  setCurrentStep,
  setOpenCancelModal,
  onValidateClick,
  addedFields,
  append,
  remove,
  isModeratePage,
  setOpenDeclineModal,
}: EmptyCaseProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trigger, setValue, clearErrors } =
    useFormContext<FormValidationProps>();
  const {
    isCompletedFormSteps,
    setSelectExistingCases,
    selectedExistingCases,
    partnerCases,
  } = usePartnerStore();

  const { i18n } = useTranslation();
  const [selectedCases, setSelectedCases] = useState<Case[]>(
    selectedExistingCases || []
  );
  const [isNewCaseAdded, setIsNewCaseAdded] = useState<boolean>(false);

  const existingCases = useMemo(
    () =>
      partnerCases?.map((item: ExistingCase) => {
        const caseKpises = item?.KPI?.map((kpi: CaseKPI) => ({
          name: kpi?.description,
          value: kpi?.amount,
          valueType: kpi?.amount_type,
          unit: kpi?.amount_unit,
        }));
        return {
          caseBusinessType: item?.business_type?.id,
          caseName: item?.name,
          kpi: caseKpises,
          platforms: item?.platforms,
          image: item?.image,
          id: item?.id,
          isExistingCase: true,
          uniqueId: item?.id,
          checked: true,
        };
      }),
    [partnerCases]
  );

  const handleSelectCases = async (caseItem: Case) => {
    if (selectedCases?.some((c: Case) => c?.id === caseItem?.id)) {
      const updatedCases = selectedCases.filter((c) => c.id !== caseItem.id);
      const indexToRemove = addedFields.findIndex(
        (item: Case) => item.isExistingCase && item.uniqueId === caseItem.id
      );
      if (indexToRemove !== -1) {
        await remove(indexToRemove);
      }

      setSelectedCases(updatedCases);
    } else {
      await append(caseItem as Case);
      addedFields?.length === 0 && clearErrors('cases');
      setSelectedCases((prevCases) => [...prevCases, caseItem]);
    }
  };

  const handleSelectAll = () => {
    setSelectedCases((prevCases: Case[]): Case[] => {
      const nonExistingFields = addedFields?.filter(
        (item: Case) => !item.isExistingCase
      );
      if (prevCases?.length === existingCases?.length) {
        setValue('cases', nonExistingFields as any);
        return [];
      } else {
        setValue('cases', [
          ...nonExistingFields,
          ...(existingCases as unknown as Case[]),
        ]);
        return existingCases as unknown as Case[];
      }
    });
  };

  useEffect(() => {
    setSelectExistingCases(selectedCases);
  }, [selectedCases]);

  useEffect(() => {
    addedFields &&
      setIsNewCaseAdded(
        !!addedFields?.filter(
          (item: Case) => partnerCases?.length && !item?.isExistingCase
        )?.length
      );
  }, [existingCases, addedFields, partnerCases?.length]);

  const exampleImage = () => {
    if (i18n.language === 'es') {
      return exampleCaseImageEs;
    } else if (RUSSIAN_LOCALE) {
      return exampleCaseImageRu;
    } else return exampleCaseImage;
  };

  return (
    <>
      {!isNewCaseAdded && (
        <div
          data-step-add_cases_empty_p-0
          className={classes.exampleBlock}
          id="emptyCaseId"
        >
          <div
            className={clsx(
              classes.exampleBlock__title,
              classes.exampleBlock__title_createBlock
            )}
          >
            {t('Create new case')}
          </div>
          <img
            onLoad={handleExampleLoaded}
            className={
              RUSSIAN_LOCALE
                ? classes.exampleBlock__imageRu
                : classes.exampleBlock__image
            }
            src={exampleImage()}
            alt="Example case image"
          />
          <div className={classes.addCaseBlock}>
            <span className={classes.exampleBlock__description}>
              {!disabled &&
                t(
                  'Allow customers to understand your competencies better by showcasing cases on the solution card'
                )}
              {disabled &&
                t(
                  'You currently have no cases added. You can prove your expertise by adding cases to this solution.'
                )}
            </span>
            {!disabled && (
              <Button
                theme="primary"
                className={classes.mainButton}
                onClick={(e) => {
                  disabled ? navigate('/solutions/add-solution') : addStep(e);
                  e.preventDefault();
                }}
              >
                <UiIcon
                  name="PlusIcon"
                  additionalClassName={classes.plusIcon}
                />
                {t('Add new case')}
              </Button>
            )}
          </div>
        </div>
      )}

      {isNewCaseAdded && (
        <Button
          theme="primary-light"
          className={classes.mainButton__second}
          onClick={(e) => {
            addStep(e);
            e.preventDefault();
          }}
        >
          <UiIcon name="PlusIcon" additionalClassName={classes.plusIcon} />
          {t('Add new case')}
        </Button>
      )}

      {partnerCases?.length ? (
        <div
          className={clsx(
            classes.exampleBlock,
            classes.exampleBlock__withScroll
          )}
        >
          <div className={classes.exampleBlock__title}>
            {t('Use existing case')}
          </div>
          <div
            className={clsx(
              classes.exampleBlock__checkbox,
              selectedCases?.length === existingCases?.length &&
                classes.exampleBlock__checkbox__selectAll
            )}
          >
            <Checkbox
              onChange={handleSelectAll}
              indeterminate={
                selectedCases?.length > 0 &&
                selectedCases?.length < existingCases?.length
              }
              checked={selectedCases?.length === existingCases?.length}
            />
            <span>{t('Select all')}</span>
          </div>

          <ExistingCases
            handleSelectCases={handleSelectCases}
            existingCases={existingCases as unknown as ExistingCase[]}
          />
        </div>
      ) : null}

      {!disabled && (
        <div className={classes.bottomBlock}>
          <div className={classes.left}>
            <Button
              className={classes.button}
              theme="light"
              onClick={async (e) => {
                e.preventDefault();
                setCurrentStep?.(1);
                const isValid = await trigger();
                if (!isValid) {
                  isCompletedFormSteps.stepTwo = false;
                }
              }}
            >
              <PurpleArrowLeftIcon />
              {t('Back')}
            </Button>
            <Button
              className={classes.button}
              theme="light"
              onClick={(e) => {
                e.preventDefault();
                setOpenCancelModal(true);
              }}
            >
              {t('Cancel')}
            </Button>
            {isModeratePage ? (
              <Button
                className={classes.button}
                theme="light"
                onClick={async (e) => {
                  e.preventDefault();
                  setOpenDeclineModal && setOpenDeclineModal(true);
                }}
              >
                {t('Cancel moderate')}
              </Button>
            ) : null}
          </div>
          <Button
            className={classes.button}
            theme="default"
            onClick={async (e) => {
              e.preventDefault();
              await onValidateClick(
                !isNewCaseAdded && !!selectedExistingCases?.length
              );
            }}
          >
            {t('Continue')}
          </Button>
        </div>
      )}
    </>
  );
};

export default EmptyCase;
