import { useMemo, useState } from 'react';
import { SolutionTree, SolutionType } from '../types';
import classes from './ProjectsList.module.scss';
import Accordion from 'components/common/Accordion';
import ListAccordionHeader from '../ListAccordionHeader';
import StatusSticker from '../StatusSticker';
import { Link } from 'components/shared/Link';
import { useTranslation } from 'react-i18next';
import SvgArrowUpRight from 'components/shared/Icons/ArrowUpRight';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import { Button } from 'components/shared/Button';
import { useUserStore } from 'contexts/UserContext';

type ProjectsListProps = {
  data: SolutionType[];
};

function ProjectsList(props: ProjectsListProps) {
  const { t } = useTranslation();
  const { isSupervisor } = useUserStore();

  const [visibleProjects, setVisibleProjects] = useState(20);

  const solutionTrees: SolutionTree[] = useMemo(() => {
    const tempTrees: SolutionTree[] = [];

    props?.data?.forEach((project: any) => {
      const { solution, projects, ...restProject } = project;
      const solutionIndex = tempTrees?.findIndex(
        (tree) => tree?.id === solution?.id
      );

      if (
        solutionIndex !== -1 &&
        tempTrees?.[solutionIndex]?.projects?.length
      ) {
        tempTrees?.[solutionIndex]?.projects?.push(restProject as any);
      } else {
        tempTrees?.push({
          id: project?.solution?.id,
          name: project?.solution?.name,
          currency: project?.solution?.currency,
          totalCost: project?.solution?.totalCost,
          projects: projects?.id ? [projects] : projects,
        });
      }
    });
    return tempTrees;
  }, [props.data]);

  const source = (source: string) =>
    source && (source?.includes('http') ? source : `https://${source}`);

  const loadMoreProjects = () => {
    setVisibleProjects((prevState) => prevState + 20);
  };

  return (
    // <div className={classes.wrapper}>
    <>
      {solutionTrees?.map((solutionTree, solutionIndex) => (
        <Accordion
          key={solutionIndex}
          header={
            <ListAccordionHeader
              title={solutionTree?.name}
              totalCost={solutionTree?.totalCost}
              currency={solutionTree?.currency}
              projects={solutionTree?.projects}
            />
          }
          headerClassname={classes.accordion__header}
        >
          {solutionTree?.projects
            ?.slice(0, visibleProjects)
            ?.map((project, projectIndex) => (
              <div
                key={`${solutionIndex}-${projectIndex}`}
                className={classes.project}
              >
                <div className={classes.project__header}>
                  <StatusSticker status={project?.status} t={t} />
                  {project?.source?.name && (
                    <Link
                      className={classes.project__link}
                      href={source(project?.source?.name)}
                      target="_blank"
                    >
                      <span>{source(project?.source?.name)}</span>
                      <SvgArrowUpRight />
                    </Link>
                  )}
                </div>
                <div className={classes.project__content}>
                  <Link
                    href={`/projects/${project?.projectId}`}
                    className={classes.project__title}
                  >
                    {project?.company}
                  </Link>
                  <div className={classes.project__info}>
                    <span className={classes.project__id}>{project?.id}</span>
                    {isSupervisor && (
                      <span className={classes.project__businessUnit}>
                        {project?.partner_name}
                      </span>
                    )}
                  </div>
                </div>
                <div className={classes.project__cost}>
                  <FormatPrice
                    currency={project?.currency?.symbol}
                    price={
                      isNaN(project?.cost)
                        ? project?.cost?.[0]?.cost
                        : project?.cost
                    }
                  />
                </div>
              </div>
            ))}
          {solutionTree?.projects?.length > visibleProjects && (
            <Button onClick={loadMoreProjects} className={classes.button}>
              {t('See more')}
            </Button>
          )}
        </Accordion>
      ))}
    </>
    // </div>
  );
}

export default ProjectsList;
