import clsx from 'classnames';
import React from 'react';
import { Button } from 'components/shared/Button';
import MultiSelect from 'components/shared/MultiSelect';
import { SelectData } from 'components/shared/SelectComponent';
import { LABELS } from 'constants/onboarding';
import { ProjectGoalsContainer } from 'containers/ProjectsDetail/Goals';
import { useCommonStore } from 'contexts/CommonContext';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from 'components/shared/NewInput';
import { Textarea } from 'components/shared/NewInput/TextArea';
import { Select } from 'components/shared/Select';
import UploadMaterials from '../../UploadMaterials';
import { multiFieldValues, writeRef } from '../../utils';
import classes from '../FormBlock.module.scss';
import { FirsStepProps } from './FirsStep.props';
import { useFirstStep } from './useFirstStep';
import TextEditor from 'components/common/TextEditor';
import { firstStepMessages } from '../validationSchemas';
import { useRef } from 'react';
import EmptyProducts from 'components/shared/EmptyProducts/EmptyProducts';
import { PurpleArrowLeftIcon } from '../../../../components/shared/Icons/PurpleArrowLeftIcon';
import { userStore } from 'stores/user-store';
import { toJS } from 'mobx';
import UiIcon from 'components/shared/Icon';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { RUSSIAN_LOCALE } from 'utils';
import { useWindowWidth } from 'hooks/useWindowWidth';

const FirsStep = observer(
  ({
    productTypes,
    crmProductTypes,
    serviceTypes,
    industryTypes,
    companySizes,
    setCurrentStep,
    setOpenCancelModal,
    setOpenDeclineModal,
    materialsData,
    disabled,
    currentStep,
    isFormBlock,
    isModeratePage,
    isChangePage,
  }: FirsStepProps) => {
    const { t, i18n } = useTranslation();
    const fieldRefs = useRef<Record<string, HTMLDivElement | null>>({});

    const {
      generalCount,
      endIconOptions,
      errors,
      control,
      internalIndustries,
      isMediaTablet,
      selectIndustryTypes,
      callbacks,
      setInternalIndustries,
      selectedCompanySizes,
      internalCompanySizes,
      setInternalCompanySizes,
      detailCount,
      materialCount,
      onValidateClick,
      file,
      setMaterialCount,
      selectServiceTypes,
      setInternalServiceTypes,
      internalServiceTypes,
      clearErrors,
    } = useFirstStep(
      companySizes,
      industryTypes,
      setCurrentStep,
      disabled,
      serviceTypes,
      currentStep,
      fieldRefs
    );

    const { dictionaries } = useCommonStore();
    const blockId = 'general_block';

    const isMobile = useWindowWidth().isMediaTablet;
    const partnerName = userStore?.partners?.results?.[0]?.name;
    const partnerLevel = userStore?.partners?.results?.[0]?.level;
    const isExternal = partnerLevel === 'EXTERNAL';

    const totalGeneralFields = !isExternal && RUSSIAN_LOCALE && crmProductTypes?.length ? 8 : 7;


    return (
      <div
        className={clsx(
          classes.formFirstStep,
          !isFormBlock && classes.formFirstStep__preview
        )}
      >
        <div className={classes.generalGoalsWrapper} data-step-add_solution_p-0>
          <div
            className={clsx(classes.generalBlock, disabled && classes.disabled)}
            id={blockId}
          >
            <div className={clsx(classes.title, classes.title__withCounter)}>
              <span>{t('General overview')}</span>
              <span className={classes.counter}>
                {generalCount} {t(`out of ${totalGeneralFields} filled`)}
              </span>
            </div>

            <div
              ref={(element) =>
                writeRef(!!errors.name, fieldRefs, element, 'name')
              }
            >
              <Controller
                render={({ field }) => (
                  <Input
                    placeholder={t('Solution name')}
                    classNames={classes.solutionInput}
                    onChange={(e: any) => {
                      field.onChange(
                        !isChangePage
                          ? e.replaceAll(/\s{2,}/g, ' ').replace(/^\s/g, '')
                          : e
                      );
                      clearErrors('name');
                    }}
                    maxLength={50}
                    value={field.value}
                    endIcon={endIconOptions(firstStepMessages(t).solution_name)}
                    title={t('Solution name')}
                    error={errors.name?.message}
                    errorText={errors?.name?.message}
                  />
                )}
                name="name"
                control={control}
              />
            </div>
            <div
              ref={(element) =>
                writeRef(
                  !!errors.productType,
                  fieldRefs,
                  element,
                  'productType'
                )
              }
            >
              <Controller
                render={({ field }) => (
                  <Select
                    title={t('Product type')}
                    placeholder={t('Select category')}
                    select={productTypes?.find(
                      (item: any) => item?.id === field?.value
                    )}
                    options={productTypes}
                    isSolutionForm
                    endIcon={endIconOptions(firstStepMessages(t).product_type)}
                    onSelect={(item: SelectData) => {
                      field.onChange(item?.id);
                      clearErrors('productType');
                    }}
                    error={errors?.productType?.message}
                  />
                )}
                name="productType"
                control={control}
              />
            </div>


            {!isExternal && RUSSIAN_LOCALE &&(
            <div
              ref={(element) =>
                writeRef(
                  !!errors.crmProductType,
                  fieldRefs,
                  element,
                  'crmProductType'
                )
              }
            >
              <Controller
                render={({ field }) => (
                  <div className={classes.selectWithWarning}>
                    <div className={classes.selectWrapper}>
                      <Select
                        title={t('CRM product')}
                        placeholder={t('Select crm product')}
                        select={crmProductTypes?.find(
                          (item: any) => item?.id === field?.value
                        )}
                        options={crmProductTypes}
                        isSolutionForm
                        showWarning={disabled && !field.value}
                        endIcon={endIconOptions(firstStepMessages(t).crmProduct_type)}
                        onSelect={(item: SelectData) => {
                          field.onChange(item?.id);
                          clearErrors('crmProductType');
                        }}
                        error={errors?.crmProductType?.message}
                        noOptionsRender={
                          <div className={classes.empty}>
                            <EmptyProducts
                              additionalClass={classes.empty__content}
                              bottomText={t('The {{name}} does not have any CRM products yet.', {
                                name: partnerName || t('the partner'),
                              })}
                              link={{
                                href: 'https://wiki.ly9.ru/pages/viewpage.action?pageId=196411594',
                                text: t("How to fix it"),
                              }}
                            />
                          </div>
                        }
                      />
                    </div>
                    {disabled && !field.value && (
                    <div className={clsx(classes.tooltipWrapper, classes.crmTooltipWrapper)}>
                      <TooltipContainer
                        text={t('The solution is not linked to the CRM Product. Please select a suitable product or initiate the creation of a new one.')}
                        position={isMobile ? 'bottom' : 'right'}
                        classNameTooltip={clsx(classes.tooltip, classes.crmTooltipOverride)}
                      >
                        <UiIcon name="InfoIcon" additionalClassName={classes.warningIconRight} />
                      </TooltipContainer>
                    </div>
                    )}
                  </div>
                )}
                name="crmProductType"
                control={control}
              />
            </div>
            )}

            <div
              ref={(element) =>
                writeRef(
                  !!errors.serviceTypes,
                  fieldRefs,
                  element,
                  'serviceTypes'
                )
              }
            >
              <Controller
                render={({ field }) => (
                  <MultiSelect
                    title={t('Service type')}
                    additionalClassName={classes.multiselectWrapper}
                    options={internalServiceTypes}
                    isSolutionForm
                    multiline
                    contentHeight="50px"
                    contentWidth={isMediaTablet ? '103%' : 'unset'}
                    showCounter
                    isSearch
                    withOrder
                    searchPlaceholder={t('Search')}
                    endIcon={endIconOptions(firstStepMessages(t).service_types)}
                    selected={multiFieldValues(selectServiceTypes, field, i18n)}
                    onSelect={(id) => {
                      callbacks.onSelectServiceType(id, field.onChange);
                      clearErrors('serviceTypes');
                    }}
                    onDeleteSelected={(id) => {
                      if (id === 'all') field.onChange(null);
                      callbacks.onDeleteServiceType(id, field.onChange);
                      selectServiceTypes.length === 1 && field.onChange(null);
                    }}
                    onSearch={(value) => {
                      serviceTypes &&
                        setInternalServiceTypes(
                          serviceTypes?.filter((item) =>
                            String(item?.label)
                              ?.toLowerCase()
                              ?.includes(value.toLowerCase())
                          )
                        );
                    }}
                    error={errors?.serviceTypes?.message}
                    placeholder=""
                  />
                )}
                name="serviceTypes"
                control={control}
              />
            </div>
            <div
              ref={(element) =>
                writeRef(!!errors.industry, fieldRefs, element, 'industry')
              }
            >
              <Controller
                render={({ field }) => (
                  <MultiSelect
                    title={t('Company industry')}
                    additionalClassName={classes.multiselectWrapper}
                    options={internalIndustries}
                    isSolutionForm
                    multiline
                    contentHeight="50px"
                    contentWidth={isMediaTablet ? '103%' : 'unset'}
                    showCounter
                    isSearch
                    withOrder
                    searchPlaceholder={t('Search')}
                    endIcon={endIconOptions(firstStepMessages(t).industry)}
                    selected={multiFieldValues(
                      selectIndustryTypes,
                      field,
                      i18n
                    )}
                    onSelect={(id) => {
                      callbacks.onSelectIndustry(id, field.onChange);
                      clearErrors('industry');
                    }}
                    onDeleteSelected={(id) => {
                      if (id === 'all') field.onChange(null);
                      callbacks.onDeleteSelectedIndustry(id, field.onChange);
                      selectIndustryTypes.length === 1 && field.onChange(null);
                    }}
                    onSearch={(value) => {
                      industryTypes &&
                        setInternalIndustries(
                          industryTypes?.filter((item) =>
                            String(item?.label)
                              ?.toLowerCase()
                              ?.includes(value.toLowerCase())
                          )
                        );
                    }}
                    error={errors?.industry?.message?.toString()}
                    placeholder=""
                  />
                )}
                name="industry"
                control={control}
              />
            </div>
            <div
              ref={(element) =>
                writeRef(
                  !!errors.companySize,
                  fieldRefs,
                  element,
                  'companySize'
                )
              }
            >
              <Controller
                render={({ field }) => (
                  <MultiSelect
                    selected={multiFieldValues(
                      selectedCompanySizes,
                      field,
                      i18n,
                      true
                    )}
                    options={internalCompanySizes}
                    isSolutionForm
                    title={t('Company size')}
                    placeholder=""
                    isSearch
                    multiline
                    contentHeight="50px"
                    contentWidth={isMediaTablet ? '103%' : 'unset'}
                    showCounter
                    searchPlaceholder={t('Search')}
                    endIcon={endIconOptions(firstStepMessages(t).company_sizes)}
                    additionalClassName={classes.multiselectWrapper}
                    onSelect={(id) => {
                      callbacks.onSelectCompanySizes(id, field.onChange);
                      clearErrors('companySize');
                    }}
                    onDeleteSelected={(id) => {
                      if (id === 'all') field.onChange(null);
                      callbacks.onDeleteCompanySize(id, field.onChange);
                      companySizes.length === 1 && field.onChange(null);
                      selectedCompanySizes.length === 1 && field.onChange(null);
                    }}
                    onSearch={(value) => {
                      companySizes &&
                        setInternalCompanySizes(
                          companySizes?.filter((item) =>
                            String(item?.label)
                              ?.toLowerCase()
                              ?.includes(value.toLowerCase())
                          )
                        );
                    }}
                    error={errors?.companySize?.message}
                  />
                )}
                name="companySize"
                control={control}
              />
            </div>

            <div
              ref={(element) =>
                writeRef(!!errors.worksFor, fieldRefs, element, 'worksFor')
              }
            >
              <Controller
                render={({ field }) => (
                  <TextEditor
                    onChange={(value: string) => {
                      field.onChange(value);
                      clearErrors('worksFor');
                    }}
                    defaultValue={field.value}
                    endIcon={endIconOptions(firstStepMessages(t).works_for)}
                    error={errors.worksFor?.message}
                    maxLength={8000}
                    title={t('Works for')}
                    t={t}
                    isModeratePage={isModeratePage}
                    isChangePage={isChangePage}
                  />
                )}
                name="worksFor"
                control={control}
              />
            </div>
          </div>

          <div
            ref={(element) =>
              writeRef(!!errors.goals, fieldRefs, element, 'goals')
            }
            className={clsx(classes.goalsBlock, disabled && classes.disabled)}
          >
            <Controller
              render={({ field }) => {
                return (
                  <ProjectGoalsContainer
                    isAddSolution
                    systemGoals={dictionaries?.goals}
                    userGoals={field.value}
                    title={t('Expected results')}
                    tabsId={blockId}
                    error={errors?.goals?.message?.toString()}
                    onChange={(goals) => {
                      field.onChange(goals);
                      clearErrors('goals');
                    }}
                    endIcon={endIconOptions(firstStepMessages(t).goals)}
                  />
                );
              }}
              name="goals"
              control={control}
            />
          </div>
        </div>
        <div
          className={classes.detailedStepsWrapper}
          data-step-add_solution_p-1
        >
          <div
            className={clsx(
              classes.detailedBlock,
              disabled && classes.disabled
            )}
          >
            <div className={clsx(classes.title, classes.title__withCounter)}>
              <span>{t('Detailed description')}</span>
              <span className={classes.counter}>
                {detailCount} {t('out of 3 filled')}
              </span>
            </div>

            <div
              ref={(element) =>
                writeRef(
                  !!errors.shortDescription,
                  fieldRefs,
                  element,
                  'shortDescription'
                )
              }
            >
              <Controller
                render={({ field }) => (
                  <Textarea
                    placeholder={t('Short description')}
                    endIcon={endIconOptions(
                      firstStepMessages(t).short_description
                    )}
                    title={t('Short description')}
                    value={field.value}
                    maxLength={150}
                    onChange={(e: any) => {
                      field.onChange(
                        e.replaceAll(/\s{2,}/g, ' ').replace(/^\s/g, '')
                      );
                      clearErrors('shortDescription');
                    }}
                    error={errors.shortDescription?.message}
                    errorText={errors.shortDescription?.message}
                  />
                )}
                name="shortDescription"
                control={control}
              />
            </div>
            <div
              ref={(element) =>
                writeRef(
                  !!errors.fullDescription,
                  fieldRefs,
                  element,
                  'fullDescription'
                )
              }
            >
              <Controller
                render={({ field }) => {
                  return (
                    <TextEditor
                      onChange={(value: string) => {
                        field.onChange(value);
                        clearErrors('fullDescription');
                      }}
                      defaultValue={field.value}
                      endIcon={endIconOptions(
                        firstStepMessages(t).full_description
                      )}
                      error={errors.fullDescription?.message}
                      maxLength={32000}
                      title={t('Project stages')}
                      t={t}
                      isModeratePage={isModeratePage}
                      isChangePage={isChangePage}
                    />
                  );
                }}
                name="fullDescription"
                control={control}
              />
            </div>
            <div
              ref={(element) =>
                writeRef(
                  !!errors.projectDuration,
                  fieldRefs,
                  element,
                  'projectDuration'
                )
              }
            >
              <Controller
                render={({ field }) => (
                  <Input
                    endIcon={endIconOptions(
                      firstStepMessages(t).project_duration
                    )}
                    title={t('Project duration')}
                    placeholder={t('Project duration')}
                    classNames={classes.input}
                    value={field.value}
                    onChange={(e: any) => {
                      field.onChange(
                        e.replaceAll(/\s{2,}/g, ' ').replace(/^\s/g, '')
                      );
                      clearErrors('projectDuration');
                    }}
                    maxLength={50}
                    error={errors.projectDuration?.message}
                    errorText={errors.projectDuration?.message}
                  />
                )}
                name="projectDuration"
                control={control}
              />
            </div>
          </div>
        </div>
        <div
          data-step-add_solution_p-2
          className={clsx(classes.materialsBlock, disabled && classes.disabled)}
        >
          <div className={clsx(classes.title, classes.title__withCounter)}>
            <span>{t('Marketing materials')}</span>
            <span className={classes.counter}>
              {materialCount} {t('out of 1 filled')}
            </span>
          </div>

          <div
            ref={(element) =>
              writeRef(!!errors['useCases-2'], fieldRefs, element, 'useCases-2')
            }
          >
            {materialsData?.map((item: any, index: number) => (
              <Controller
                key={index}
                render={({ field }) => (
                  <UploadMaterials
                    error={errors?.['useCases-2']?.message as any}
                    infoIconOptions={endIconOptions(
                      t(
                        'Upload a brief for your solution to make the initial client interview more accurate'
                      )
                    )}
                    data={field.value}
                    onUploadFile={(file: File) => {
                      field.onChange(file);
                      setMaterialCount([file].length);
                      clearErrors('useCases-2');
                    }}
                    onRemoveFile={() => {
                      if (file?.name) {
                        field.onChange(null);
                        setMaterialCount(materialCount - 1);
                      }
                    }}
                    hideDeleteButtons={disabled}
                  />
                )}
                name="useCases-2"
                control={control}
              />
            ))}
          </div>
        </div>

        {isModeratePage && !disabled && (
          <div className={classes.bottomBlock}>
            <div className={classes.left}>
              <Button
                className={classes.button}
                theme="light"
                onClick={(e: any) => {
                  e.preventDefault();
                  setOpenCancelModal(true);
                }}
              >
                {t('Cancel')}
              </Button>
              {isModeratePage ? (
                <Button
                  className={classes.button}
                  theme="light"
                  onClick={async (e) => {
                    e.preventDefault();
                    setOpenDeclineModal && setOpenDeclineModal(true);
                  }}
                >
                  {t('Cancel moderate')}
                </Button>
              ) : null}
            </div>

            <Button
              className={classes.button}
              theme="default"
              onClick={async (e: any) => {
                e.preventDefault();
                await onValidateClick();
              }}
            >
              {t('Continue')}
            </Button>
          </div>
        )}

        {!disabled && !isModeratePage && (
          <div className={classes.bottomBlock}>
            <Button
              className={classes.button}
              theme="light"
              onClick={(e: any) => {
                e.preventDefault();
                setOpenCancelModal(true);
              }}
            >
              {t('Cancel')}
            </Button>

            <Button
              className={classes.button}
              theme="default"
              onClick={async (e: any) => {
                e.preventDefault();
                await onValidateClick();
              }}
            >
              {t('Continue')}
            </Button>
          </div>
        )}
      </div>
    );
  }
);

export default FirsStep;
