import React, { FC, useMemo } from 'react';

import classes from './ProjectDealStages.module.scss';
import { CheckCirleIconPurple } from 'components/shared/Icons/CheckCirleIconPurple';
import { PurpleTimeIcon } from 'components/shared/Icons/PurpleTimeIcon';
import { PurpleEmptyIcon } from 'components/shared/Icons/PurpleEmptyIcon';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CancelledIcon } from '../../../components/shared/Icons/CancelledIcon';
import { PausedIcon } from '../../../components/shared/Icons/PausedIcon';
import { DownloadIcon } from 'components/shared/Icons/DownloadIcon';
import { Link } from 'react-router-dom';
import i18n from 'i18n/i18n';
import { getLangName } from '../../../tools/utils';
import { DEAL_STAGES } from 'constants/dealStatuses';
import { RUSSIAN_LOCALE } from 'utils';
import { formatterRuDate } from 'tools/formattedDate';
import { ConstStageType, Project, ProjectDealStage } from '@type/projects';
import { ProjectDealStageTypeEnum } from 'constants/enum/project';
import { useTranslation } from 'react-i18next';
import { User } from '../../../components/common/User';
import { Projects } from '../../../pages/Projects';
import ProjectsService from '../../../services/projects';

interface Props {
  project: Project | null;
}

export const ProjectsDealStages: FC<Props> = observer(({ project }) => {
  const projectAllStage = project?.deal_stages;
  const systemStages = project?.all_stages;
  const { PAUSED, CANCELLED, COMPLETED } = DEAL_STAGES;

  const { t } = useTranslation();

  const stageArray = useMemo(
    () =>
      {
        return systemStages?.map((stage: ConstStageType) => {
          if (
            stage?.type?.toLowerCase() === CANCELLED.toLowerCase() ||
            stage?.type?.toLowerCase() === PAUSED.toLowerCase() ||
            stage?.type?.toLowerCase() === COMPLETED.toLowerCase()
          ) {
            return null;
          } else {
            return {
              name: stage.name,
              type: stage.type,
              description: stage.description,
            };
          }
        })
      },
    [CANCELLED, COMPLETED, PAUSED, systemStages]
  );

  let lastIndex = -1;

  const orderedStages = stageArray?.map((stage, index) => {
    const findStage = projectAllStage?.find(
      (projectStage) => stage?.type === projectStage?.type
    );
    if (findStage) {
      lastIndex = index;
      return {
        ...findStage,
        name: t(findStage?.name),
        description: t(findStage?.description),
      };
    } else {
      return stage;
    }
  });

  // добавляем стадии паузы или отмены на место где была последняя активная стадия
  const pausedStage = projectAllStage?.find(
    (stage) => stage.type === ProjectDealStageTypeEnum.PAUSED
  );
  const cancelledStage = projectAllStage?.find(
    (stage) => stage.type === ProjectDealStageTypeEnum.CANCELLED
  );
  const completedStage = projectAllStage?.find(
    (stage) => stage.type === ProjectDealStageTypeEnum.COMPLETED
  );

  if (pausedStage) {
    orderedStages?.splice(lastIndex + 1, 0, {
      ...pausedStage,
      name: getLangName(pausedStage, 'name', i18n),
    });
  }

  if (cancelledStage) {
    const index = pausedStage ? 2 : 1;
    orderedStages?.splice(lastIndex + index, 0, {
      ...cancelledStage,
      name: getLangName(cancelledStage, 'name', i18n),
    });
  }

  if (completedStage) {
    orderedStages?.splice(lastIndex + 1, 0, {
      ...completedStage,
      name: getLangName(completedStage, 'name', i18n),
    });
  }

  const formatDate = (dateString: string) => {
    const date: Date = new Date(dateString);
    const locale = i18n.language || 'es-ES';
    return date.toLocaleDateString(locale, {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  };

  const getStepIcon = (
    key: React.Key,
    stageName: string,
    stageType: ProjectDealStageTypeEnum,
    id = 0,
    planEndDate = '',
    factEndDate = ''
  ) => {
    const projectFile = project?.files.find((el) => el.deal_stage === id);
    const projectFilePath = projectFile?.file;

    const additionalFiles = [];

        if (stageType === ProjectDealStageTypeEnum.SIGNING) {
          if (project?.agreement_file) {
            additionalFiles.push({ file: project.agreement_file, name: project.agreement_name });
          }
        }

      if (stageType === ProjectDealStageTypeEnum.CREATION) {
        if (project?.commercial_proposal_file) {
          additionalFiles.push({ file: project.commercial_proposal_file, name: project.commercial_proposal_name });
        }
      }
  

    const helpText = orderedStages?.find(
      (stage) => stage?.type === stageType
    )?.description;
    const fileName = projectFile?.file_name
      ? projectFile?.file_name
      : projectFilePath?.match(/\/files\/(.+)/)?.[1] || '';
    const shortFileName =
      fileName?.length > 20 ? `${fileName?.slice(0, 20)}...` : fileName;

    const additionalStageIcon = (
      stageType: ProjectDealStageTypeEnum,
      isTimeIcon?: boolean
    ) => {
      if (stageType === ProjectDealStageTypeEnum.PAUSED) {
        return <PausedIcon />;
      } else if (stageType === ProjectDealStageTypeEnum.CANCELLED) {
        return <CancelledIcon />;
      } else if (isTimeIcon) {
        return <PurpleTimeIcon />;
      } else {
        return <CheckCirleIconPurple />;
      }
    };

    if (planEndDate && factEndDate) {
      let factRuDate = null;

      if (RUSSIAN_LOCALE) {
        factRuDate = formatterRuDate.format(new Date(factEndDate));
      }

      return (
        <div className={classes.step} key={key}>
          <div className={clsx(classes.name, classes.past)}>
            <span className={classes.step__date}>
              {RUSSIAN_LOCALE ? factRuDate : formatDate(factEndDate)}
            </span>
            {additionalStageIcon(stageType)}
            <div className={classes.step__block}>
              <span>{stageName}</span>
              <p>{helpText}</p>
              {projectFilePath && (
                <a
                  className={classes.map__link}
                  title={fileName}
                  onClick={async () => {
                    await ProjectsService.handleDownloadFile(
                      projectFilePath,
                      fileName as string
                    );
                  }}
                >
                  {shortFileName}
                  <DownloadIcon />
                </a>
              )}

              {additionalFiles.map(({ file, name }, index) => (
              <a
                key={index}
                className={classes.map__link}
                title={name || file?.split('/').pop()}
                onClick={async () => {
                  if (file) {
                    await ProjectsService.handleDownloadFile(file, name || file.split('/').pop() || '');
                  }
                }}
              >
                {name || file?.split('/').pop()}
                <DownloadIcon />
              </a>
            ))}
            </div>
          </div>
        </div>
      );
    } else if (planEndDate && !factEndDate) {
      let planRuDate = null;

      if (RUSSIAN_LOCALE) {
        planRuDate = formatterRuDate.format(new Date(planEndDate));
      }

      return (
        <div className={clsx(classes.step, classes.step_empty)} key={key}>
          <div className={clsx(classes.name, classes.past)}>
            <span className={classes.step__date}>
              {RUSSIAN_LOCALE ? planRuDate : formatDate(planEndDate)}
            </span>
            {additionalStageIcon(stageType, true)}
            <div className={classes.step__block}>
              <span>{stageName}</span>
              <p>{helpText}</p>
              {projectFilePath && (
                <a
                  className={classes.map__link}
                  title={fileName}
                  onClick={async () => {
                    await ProjectsService.handleDownloadFile(
                      projectFilePath,
                      fileName as string
                    );
                  }}
                >
                  {shortFileName}
                  <DownloadIcon />
                </a>
              )}

              {additionalFiles.map(({ file, name }, index) => (
              <a
                key={index}
                className={classes.map__link}
                title={name || file?.split('/').pop()}
                onClick={async () => {
                  if (file) {
                    await ProjectsService.handleDownloadFile(file, name || file.split('/').pop() || '');
                  }
                }}
              >
                {name || file?.split('/').pop()}
                <DownloadIcon />
              </a>
            ))}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={clsx(classes.step, classes.step_empty)} key={key}>
          <div className={clsx(classes.name, classes.past)}>
            <span className={classes.step__date}></span>
            <PurpleEmptyIcon />
            <div className={classes.step__block}>
              <span>{stageName}</span>
              <p>{helpText}</p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={classes.map}>
      {orderedStages?.map((el) => {
        const stageElement = el as ProjectDealStage;
        if (stageElement?.id) {
          return getStepIcon(
            `${stageElement.id}-${stageElement?.type}`,
            stageElement?.name || '',
            stageElement?.type,
            stageElement.id,
            stageElement?.plan_end_date,
            stageElement?.fact_end_date
          );
        } else if (el) {
          return getStepIcon(
            `${el?.name}-${el?.type}`,
            el?.name || '',
            el?.type as ProjectDealStageTypeEnum
          );
        } else return null;
      })}
    </div>
  );
});
