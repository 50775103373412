import { Button } from 'components/shared/Button';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { Type } from 'components/shared/Type';
import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import classes from './Personal.module.scss';
import { PersonalProps } from './Personal.props';
import { helperTextForRole, usePersonal } from './usePersonal';
import { RUSSIAN_LOCALE } from 'utils';
import { AttentionIcon2 } from 'components/shared/Icons/Attention2';
import NewInput from 'components/shared/NewInput';
import { PhoneInputMaskFull } from 'components/shared/PhoneInputFull';
import { getLinkForPrivacy } from '../getLinkForPrivacy';
import { getLangName } from 'tools/utils';
import { PARTNER_ROLE } from 'types/user';
import { useWindowWidth } from 'hooks/useWindowWidth';
import clsx from 'clsx';

export const Personal = ({ defaultValue, getParams }: PersonalProps) => {
  const {
    t,
    i18n,
    formRef,
    handleSubmit,
    onSubmit,
    control,
    errors,
    setFullName,
    email,
    disableSubmit,
    setPhone,
    partner,
    isSupervisor,
    bottomHeightMobile,
  } = usePersonal(defaultValue, getParams);

  const { isMediaTablet } = useWindowWidth();

  return (
    <>
      <TitleAndDescription pageName="Account/personal" />

      <div className={classes.wrapper}>
        <div className={classes.titleWrap}>
          <Type tag={'h2'} size={'xs-bold'} className={classes.title}>
            {t('Personal information')}
          </Type>
        </div>
        <p className={classes['info']}>
          {t('Viewing profile information. By providing contact information,')}
          <br />
          {t('you agree to the terms of the')}{' '}
          <Link
            className={classes['info__link']}
            to={getLinkForPrivacy(i18n.language)}
            target="_blank"
          >
            {t('Privacy Policy on account')}
          </Link>
        </p>

        {RUSSIAN_LOCALE && (
          <div className={classes.attention}>
            <AttentionIcon2 style={{ flexShrink: 0 }} />

            <ul className={classes.attention__list} translate="no">
              <li className={classes.attention__title}>
                {t('Data obtained from Kokoc ID')}
              </li>
              <li className={classes.attention__description}>
                {t('To update the information, go to your')}{' '}
                <a
                  href={`${process.env.REACT_APP_KID_URL}passport`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.attention__link}
                >
                  {t('Kokoc ID profile')}
                </a>
                .{' '}
                {t(
                  'The data remains confidential, and updating it in the profile ensures reliability'
                )}
              </li>
            </ul>
          </div>
        )}

        <form className={classes.inner} ref={formRef}>
          <div className={classes.box}>
            <div className={classes.groupRow}>
              <div className={classes.groupField}>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => (
                    <NewInput
                      classNames={classes.input}
                      onChange={(e) => {
                        field.onChange(e);
                        setFullName(e);
                      }}
                      isAccount
                      title={t('Full name')}
                      type={'text'}
                      placeholder={t('Full name')}
                      value={field.value}
                      disabled={RUSSIAN_LOCALE}
                      error={errors.fullName ? t('Invalid name') : undefined}
                      maxLength={50}
                    />
                  )}
                />
              </div>
              <div className={classes.groupField}>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <NewInput
                      classNames={classes.input}
                      disabled
                      type={'text'}
                      title={t('Role')}
                      placeholder={t('Role')}
                      value={
                        getLangName(partner?.permission, 'name', i18n) ||
                        t('Viewer')
                      }
                      isAccount
                      endIcon={{
                        endIconName: 'HelpIcon',
                        endIconTooltipText: helperTextForRole(
                          partner?.permission?.type || PARTNER_ROLE.VIEWER,
                          t
                        ),
                        endIconTooltipPosition: isMediaTablet
                          ? 'left'
                          : isSupervisor
                            ? 'right'
                            : 'top',
                        endIconTooltipClassName: classes.endIconTooltip,
                        tooltipInner: clsx(
                          classes.tooltipInner,
                          isSupervisor && classes.tooltipInnerRight
                        ),
                        classNameTooltip: isSupervisor && classes.tooltip,
                      }}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
            </div>

            <div className={classes.groupRow}>
              <div className={classes.groupField}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <NewInput
                      classNames={classes.input}
                      title={t('Work email')}
                      disabled
                      isAccount
                      type={'text'}
                      placeholder={t('Work email')}
                      value={email}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
              <div className={classes.groupField}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <PhoneInputMaskFull
                      bigInput
                      inputLabel={true}
                      className={classes.input}
                      onChange={(e) => {
                        setPhone(e);
                        field.onChange(e);
                      }}
                      type={'text'}
                      placeholder={t('Phone number')}
                      value={field.value}
                      disabled={RUSSIAN_LOCALE}
                      isBackground
                      error={
                        errors.phone ? t('Invalid phone number') : undefined
                      }
                    />
                  )}
                />
              </div>
            </div>
          </div>
          {!RUSSIAN_LOCALE ? (
            <div className={classes.submit} style={bottomHeightMobile}>
              <Button
                className={classes.button}
                theme="primary"
                size="middle"
                disabled={disableSubmit}
                onClick={async (e) => {
                  e.preventDefault();
                  await onSubmit();
                }}
              >
                {t('Save changes')}
              </Button>
            </div>
          ) : null}
        </form>
      </div>
    </>
  );
};
