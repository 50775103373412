import React from 'react';
import { ModalMobile } from 'components/shared/ModalMobile';
import classes from '../MenuCell.module.scss';
import clsx from 'clsx';
import { OptionTypeString } from 'types/util-types';
import { BusinessUnit, PARTNER_ROLE } from 'types/user';
import { Button } from 'components/shared/Button';
import { observer } from 'mobx-react-lite';
import { TFunction } from 'i18next';
import { ChevronIcon } from 'components/shared/Icons/ChevronIcon';
import AdminEmployeeMobileModal from './AdminMobileModal';
import SelectComponent from 'components/shared/SelectComponent';
import { useWindowWidth } from 'hooks/useWindowWidth';

interface SupervisorMobileModalProps {
  data: BusinessUnit[];
  value: any;
  typeUser: PARTNER_ROLE;
  setTypeUser: (type: PARTNER_ROLE) => void;
  businessUnit: OptionTypeString;
  setBusinessUnit: (type: OptionTypeString) => void;
  employeesRoles: OptionTypeString[];
  isShowMenuModal: boolean;
  handleCloseMenuModal: () => void;
  isDisabledChangeRole?: boolean;
  handleChangePermissions: (
    id: number,
    role: PARTNER_ROLE,
    email: string,
    partnerId?: number
  ) => Promise<void>;
  handleClose: () => void;
  handleShowDotsModal: () => void;
  t: TFunction;
}

const SupervisorMobileModal = observer(
  ({
    data,
    value,
    typeUser,
    setTypeUser,
    employeesRoles,
    isShowMenuModal,
    handleCloseMenuModal,
    isDisabledChangeRole,
    handleChangePermissions,
    handleClose,
    handleShowDotsModal,
    t,
    businessUnit,
    setBusinessUnit,
  }: SupervisorMobileModalProps) => {
    const isMobile = useWindowWidth().isMediaTablet;
    const [isClicked, setIsClicked] = React.useState({
      role: false,
      businessUnit: false,
    });
    const getRoleName = (role: PARTNER_ROLE) => {
      return employeesRoles.find((item) => item?.id === role)?.name;
    };

    return (
      <>
        <ModalMobile
          title=""
          isOpen={isShowMenuModal}
          onClose={handleCloseMenuModal}
          isBasicHeader={false}
          crossIcon
        >
          <div className={classes.inner}>
            <div
              className={clsx(
                classes.headerWrapper,
                classes.supervisorHeaderWrapper
              )}
            >
              <h3
                className={clsx(
                  classes.title,
                  !value.id && classes.titleWithoutId
                )}
              >
                {value.full_name || value.email}
              </h3>
              {value.id && (
                <h3 className={classes.supervisor__titleEmail}>
                  <a>{value.email}</a>
                </h3>
              )}
            </div>
            <div className={classes.chooseContainer}>
              <Button
                theme="primary-light"
                className={clsx(
                  classes.chooseContainer__item,
                  (isClicked?.role || typeUser) && classes.active
                )}
                onClick={() => {
                  setIsClicked({ role: true, businessUnit: false });
                }}
              >
                <span
                  className={clsx(
                    classes.btnInner,
                    typeUser && classes.btnInner__full
                  )}
                >
                  <span className={classes.btnInner__title}>
                    {t('Choose role')}{' '}
                    {typeUser && <span className={classes.redDot}></span>}
                  </span>
                  {typeUser && (
                    <span className={classes.btnInner__value}>
                      {getRoleName(typeUser)}
                    </span>
                  )}
                </span>
                <span>
                  <ChevronIcon />
                </span>
              </Button>
              <Button
                disabled
                theme="primary-light"
                className={clsx(
                  classes.chooseContainer__item,
                  (isClicked.businessUnit ||
                    businessUnit?.name ||
                    value?.partnerName) &&
                    classes.active
                )}
                onClick={() => {
                  setIsClicked({
                    role: false,
                    businessUnit: true,
                  });
                }}
              >
                <span
                  className={clsx(
                    classes.btnInner,
                    (businessUnit?.name || value?.partnerName) &&
                      classes.btnInner__full
                  )}
                >
                  <span className={classes.btnInner__title}>
                    {t('Business-unit')}{' '}
                    {(businessUnit?.name || value?.partnerName) && (
                      <span className={classes.redDot}></span>
                    )}
                  </span>
                  {(businessUnit?.name || value?.partnerName) && (
                    <span className={classes.btnInner__value}>
                      {businessUnit?.name || value?.partnerName}
                    </span>
                  )}
                </span>
                <span>
                  <ChevronIcon />
                </span>
              </Button>
            </div>

            <div className={classes.buttons}>
              <Button
                className={classes.button}
                theme="primary"
                size="middle"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (typeUser !== value.permission.type)
                    await handleChangePermissions(
                      value.id,
                      typeUser,
                      value.email,
                      value?.partnerId
                    );

                  handleClose();
                }}
                disabled={isDisabledChangeRole}
              >
                {t('Save changes')}
              </Button>
              <Button
                className={classes.button}
                theme="primary-light"
                size="middle"
                onClick={handleShowDotsModal}
              >
                {t('Remove')}
              </Button>
            </div>
          </div>
        </ModalMobile>

        {isClicked?.role && (
          <AdminEmployeeMobileModal
            value={value}
            typeUser={typeUser}
            setTypeUser={setTypeUser}
            employeesRoles={employeesRoles}
            isShowMenuModal={isShowMenuModal}
            handleCloseMenuModal={handleCloseMenuModal}
            isDisabledChangeRole={isDisabledChangeRole}
            handleChangePermissions={handleChangePermissions}
            handleClose={handleClose}
            handleShowDotsModal={handleShowDotsModal}
            isSupervisor
            setIsClicked={setIsClicked}
          />
        )}
        {isClicked.businessUnit && isMobile && (
          <div>
            <SelectComponent
              select={businessUnit}
              setSelect={setBusinessUnit}
              open={isClicked.businessUnit}
              options={data}
              isSupervisor
              title={t('Business-unit')}
              setIsClicked={setIsClicked}
            />
          </div>
        )}
      </>
    );
  }
);

export default SupervisorMobileModal;
