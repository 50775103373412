import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { pagination as getPaginationList } from 'tools/pagination';
import { ArrowPagination } from 'components/shared/Icons/ArrowPagination';
import classes from './Pagination.module.scss';
import { IPagination } from '@type/global';

interface PaginationProps extends IPagination {
  className?: string;
  setPageNumber: (n: number) => void;
  extraAction?: () => void;
  prevPage?: () => void;
  nextPage?: () => void;
}

export const Pagination = ({
  className,
  currentPage,
  totalPages,
  showPagesNearby = 3,
  symbol,
  setPageNumber,
  extraAction,
  prevPage,
  nextPage,
}: PaginationProps): ReactElement => {
  const [currentPagePagination, setCurrentPagePagination] =
    useState(currentPage);

  useEffect(() => {
    setCurrentPagePagination(currentPage);
  }, [currentPage]);

  const onClick = useCallback(
    (page: any) => {
      setPageNumber(page);
    },
    [setPageNumber]
  );

  const paginationList = useMemo(
    () =>
      getPaginationList({
        currentPage: currentPagePagination,
        totalPages,
        showPagesNearby,
        symbol,
      }),
    [currentPagePagination, totalPages, showPagesNearby, symbol]
  );

  const disablePrev = currentPagePagination === 1;
  const disableNext = currentPagePagination === totalPages;

  return (
    <div className={clsx(className, classes.wrapper)}>
      <button
        className={clsx(
          classes.button,
          classes.button_prev,
          disablePrev && classes.button_disabled
        )}
        disabled={disablePrev}
        onClick={() => {
          onClick(currentPagePagination - 1);
          prevPage && prevPage();
        }}
      >
        <ArrowPagination fill="currentColor" rotate="left" />
      </button>
      <div className={clsx(classes['wrapper-pagination'])}>
        {paginationList.map((item, index) => (
          <div
            key={index}
            className={clsx(
              classes.item,
              typeof item !== 'number' && classes.symbol,
              currentPagePagination === item && classes.item_active
            )}
            onClick={() => {
              onClick(item);
              extraAction && extraAction();
            }}
          >
            {item}
          </div>
        ))}
      </div>
      <button
        className={clsx(
          classes.button,
          classes.button_next,
          disableNext && classes.button_disabled
        )}
        disabled={disableNext}
        onClick={() => {
          onClick(currentPagePagination + 1);
          nextPage && nextPage();
        }}
      >
        <ArrowPagination fill="currentColor" />
      </button>
    </div>
  );
};
