import React from 'react';
import classes from '../../TableEmployees/MenuCell/MenuCell.module.scss';
import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { ChevronIcon } from 'components/shared/Icons/ChevronIcon';
import { ModalMobile } from 'components/shared/ModalMobile';
import { observer } from 'mobx-react-lite';
import { TFunction } from 'i18next';
import SelectComponent from 'components/shared/SelectComponent';
import AdminMobileInvite from './AdminMobileInvite';
import { OptionTypeString } from 'types/util-types';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Controller } from 'react-hook-form';
import NewInput from 'components/shared/NewInput';
import { BusinessUnit } from 'types/user';

interface SupervisorModalInviteProps {
  data: BusinessUnit[];
  showInviteModal: boolean;
  handleCloseInviteModal: () => void;
  t: TFunction;
  emailValue: string;
  onSubmit: (data: any, errors?: any) => void;
  control: any;
  errors: any;
  employeesRoles: OptionTypeString[];
  businessUnit?: OptionTypeString;
  setBusinessUnit?: (type: OptionTypeString) => void;
  formValues?: any;
  trigger?: any;
}

const SupervisorModalInvite = observer(
  ({
    data,
    showInviteModal,
    handleCloseInviteModal,
    t,
    emailValue,
    onSubmit,
    control,
    errors,
    employeesRoles,
    formValues,
    trigger,
  }: SupervisorModalInviteProps) => {
    const isMobile = useWindowWidth().isMediaTablet;

    const [isClicked, setIsClicked] = React.useState({
      role: false,
      businessUnit: false,
    });

    return (
      <>
        <ModalMobile
          title=""
          isOpen={showInviteModal}
          onClose={handleCloseInviteModal}
          isBasicHeader={false}
        >
          <div className={classes.inner}>
            <div className={classes.headerWrapper}>
              <h4 className={classes.title}>{t('Invitation')}</h4>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <NewInput
                    {...field}
                    classNames={classes.input}
                    type="text"
                    title={t('Employee work email')}
                    placeholder={t('Employee work email')}
                    error={!!errors.email?.message}
                    errorText={errors.email?.message}
                    maxLength={50}
                  />
                )}
              />
            </div>
            <div className={classes.chooseContainer}>
              <div className={clsx(classes.btnContainer)}>
                <Button
                  theme="primary-light"
                  className={clsx(
                    classes.chooseContainer__item,
                    (isClicked?.role || formValues?.role) && classes.active,
                    errors?.role?.id && classes.error
                  )}
                  type="button"
                  onClick={() => {
                    setIsClicked({ role: true, businessUnit: false });
                  }}
                >
                  <span
                    className={clsx(
                      classes.btnInner,
                      formValues?.role && classes.btnInner__full
                    )}
                  >
                    <span className={classes.btnInner__title}>
                      {t('Choose role')}{' '}
                      {formValues?.role && (
                        <span className={classes.redDot}></span>
                      )}
                    </span>
                    {formValues?.role && (
                      <span className={classes.btnInner__value}>
                        {formValues.role?.name}
                      </span>
                    )}
                  </span>
                  <span className={classes.icon}>
                    <ChevronIcon />
                  </span>
                </Button>
                {errors?.role?.id?.message && (
                  <span className={classes.errorText}>
                    {errors?.role?.id?.message}
                  </span>
                )}
              </div>
              <div className={classes.btnContainer}>
                <Button
                  theme="primary-light"
                  className={clsx(
                    classes.chooseContainer__item,
                    (isClicked.businessUnit || formValues?.bUnit) &&
                      classes.active,
                    errors.bUnit?.id && classes.error
                  )}
                  type="button"
                  onClick={() => {
                    setIsClicked({ role: false, businessUnit: true });
                  }}
                >
                  <span
                    className={clsx(
                      classes.btnInner,
                      formValues?.bUnit && classes.btnInner__full
                    )}
                  >
                    <span className={classes.btnInner__title}>
                      {t('Business-unit')}{' '}
                      {formValues?.bUnit && (
                        <span className={classes.redDot}></span>
                      )}
                    </span>
                    {formValues?.bUnit && (
                      <span className={classes.btnInner__value}>
                        {formValues.bUnit.name}
                      </span>
                    )}
                  </span>
                  <span className={classes.icon}>
                    <ChevronIcon />
                  </span>
                </Button>
                {errors?.bUnit?.id?.message && (
                  <span className={classes.errorText}>
                    {errors?.bUnit?.id?.message}
                  </span>
                )}
              </div>
            </div>

            <div className={classes.buttons}>
              <Button
                type="button"
                className={classes.button}
                theme="primary"
                size="middle"
                disabled={emailValue.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit(formValues, errors);
                }}
              >
                {t('Invite employee')}
              </Button>
            </div>
          </div>
        </ModalMobile>

        {isClicked?.role && (
          <AdminMobileInvite
            showInviteModal={showInviteModal}
            handleCloseInviteModal={handleCloseInviteModal}
            onSubmit={onSubmit}
            control={control}
            errors={errors}
            employeesRoles={employeesRoles}
            t={t}
            emailValue={emailValue}
            isSupervisor
            setIsClicked={setIsClicked}
            trigger={trigger}
          />
        )}
        {isClicked.businessUnit && isMobile && (
          <Controller
            name="bUnit"
            control={control}
            render={({ field }) => (
              <SelectComponent
                select={field.value}
                setSelect={async (value) => {
                  field.onChange(value);
                  await trigger('bUnit');
                }}
                open={isClicked.businessUnit}
                options={data}
                isSupervisor
                title={t('Business-unit')}
                setIsClicked={setIsClicked}
              />
            )}
          />
        )}
      </>
    );
  }
);

export default SupervisorModalInvite;
