import clsx from 'clsx';
import classes from '../TableEmployees.module.scss';
import { RoleCellProps } from './RoleCell.props';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { OptionTypeString } from 'types/util-types';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/shared/Select';
import { getEmployeesRoles } from '../../employeesRoles';
import { PARTNER_ROLE } from 'types/user';
import { useMemo } from 'react';
import { useUserStore } from '../../../../../contexts/UserContext';

const RoleCell = ({
  value,
  isDisabledChangeRole,
  handleChangePermissions,
}: RoleCellProps) => {
  const { width } = useWindowWidth();
  const { i18n } = useTranslation();
  const { isSupervisor } = useUserStore();
  const mobileSupervisor = isSupervisor && width < 960;

  const employeesRoles = useMemo(() => getEmployeesRoles(), [i18n.language]);

  return (
    <div
      className={clsx(
        classes.label,
        classes[value.label],
        classes[`type_${value.type}`]
      )}
    >
      {width < 961 ? (
        <div
          className={clsx(
            classes.nameRole,
            !value.id && classes.opacity,
            mobileSupervisor && classes.nameRole__supervisor
          )}
        >
          {employeesRoles?.find(
            (item: OptionTypeString) => item?.id === value.type
          )?.name || ''}
        </div>
      ) : (
        <Select
          select={employeesRoles?.find(
            (item: OptionTypeString) => item?.id === value.type
          )}
          isRadio
          small
          options={employeesRoles}
          isSolutionForm
          onSelect={(item: any) =>
            handleChangePermissions(
              value.id,
              item.id as PARTNER_ROLE,
              value.email,
              value?.partnerId
            )
          }
          disabled={isDisabledChangeRole}
        />
      )}
    </div>
  );
};

export default RoleCell;
