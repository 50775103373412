import { makeAutoObservable, toJS } from 'mobx';
import Partner from '../services/partner';
import { userStore } from './user-store';
import { ShowNotification } from '../tools/showNotification';
import { getLangName, getLangNameNew } from '../tools/utils';
import i18n from 'i18next';
import i18next from 'i18next';
import { AxiosError } from 'axios';
import { FILTERS_LABELS, Status } from '../constants/enum';

export class PartnerStore {
  constructor() {
    makeAutoObservable(this);
  }

  services = [];
  servicesCount = 0;
  statusCount = {
    current_filter: 0,
    active: 0,
    in_review: 0,
    archived: 0,
    rejected: 0,
  };
  copiedService: any = {};
  nextServices = '';
  prevServices = '';
  analytics = [];
  singleService: any = {};
  goals = [];
  isCompletedFormSteps = {
    stepOne: false,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
  };
  allowInvite = true;
  partnerCases = [];
  selectedExistingCases = [];
  isErrorServices = false;
  serviceToActivate = '';
  serviceToChange = 0;
  isServiceActivated = false;
  isServiceDeclined = false;
  isServiceChanged = false;
  isServiceArchivated = false;

  setIsErrorServices(isErrorServices: boolean) {
    this.isErrorServices = isErrorServices;
  }

  setIsServiceActivated(isServiceActivated: boolean) {
    this.isServiceActivated = isServiceActivated;
  }

  setIsServiceDeclined(isServiceDeclined: boolean) {
    this.isServiceDeclined = isServiceDeclined;
  }

  setIsServiceChanged(isServiceChanged: boolean) {
    this.isServiceChanged = isServiceChanged;
  }

  setIsServiceArchivated(isServiceArchivated: boolean) {
    this.isServiceArchivated = isServiceArchivated;
  }

  activateService = async (serviceToActivate: string) => {
    try {
      const response = await Partner.activateSolution(serviceToActivate);
      if (response.status === 200) {
        this.setIsServiceActivated(true);
      }
    } catch (error: any) {
      ShowNotification({
        children:
          error?.response?.data?.error ||
          i18next.t('Error activating solution'),
        type: 'error',
      });
    }
  };

  changeSolution = async (solutionId: number, data: any) => {
    try {
      const response = await Partner.changeSolution(solutionId, data);
      if (response.status === 200) {
        this.setIsServiceChanged(true);
      }
    } catch (error: any) {
      ShowNotification({
        children:
          error?.response?.data?.error || i18next.t('Error changing solution'),
        type: 'error',
      });
    }
  };

  declineService = async (serviceToActivate: string) => {
    try {
      const response = await Partner.declineSolution(serviceToActivate);
      if (response.status === 200) {
        this.setIsServiceDeclined(true);
      }
    } catch (error: any) {
      ShowNotification({
        children:
          error?.response?.data?.error || i18next.t('Error declining solution'),
        type: 'error',
      });
    }
  };

  archiveService = async (serviceToActivate: string) => {
    try {
      const response = await Partner.archiveService(serviceToActivate);
      if (response.status === 200 || response.status === 202) {
        ShowNotification({
          type: 'success',
          title: i18next.t('Solution added to archive'),
          children: i18next.t(
            'Customers no longer see the solution on the showcase. To change the status of the solution, contact the manager'
          ),
          isNewToast: true,
        });
      }
    } catch (error: any) {
      ShowNotification({
        children:
          error?.response?.data?.error || i18next.t('Error archiving solution'),
        type: 'error',
      });
    }
  };

  unarchiveService = async (serviceToActivate: string) => {
    try {
      await Partner.unarchiveService(serviceToActivate);
    } catch (error: any) {
      ShowNotification({
        children:
          error?.response?.data?.error ||
          i18next.t('Error unarchiving solution'),
        type: 'error',
      });
    }
  };

  fetchPartnerServices = async (params: string) => {
    try {
      if (!userStore.partners) {
        await userStore.loadPartner();
      }
      const partnerId = userStore.partners?.results[0]?.id;

      if (!partnerId) {
        throw new Error('no partnerId');
      }

      const response = userStore.isSupervisor
        ? await Partner.getServicesForSupervisor(params)
        : await Partner.getServices(partnerId, params);

      if (response?.status === 200) {
        const {
          results,
          allServices,
          count,
          archived_count,
          active_count,
          review_count,
          rejected_count,
        } = response?.data;
        this.services = results;
        this.servicesCount = allServices;
        this.statusCount = {
          current_filter: count,
          active: active_count,
          in_review: review_count,
          archived: archived_count,
          rejected: rejected_count,
        };
        this.nextServices = response?.data?.next;
        this.prevServices = response?.data?.previous;
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const isServerError =
          (error.response && error.response?.status >= 500) ||
          (error.request && error.request?.status >= 500);
        if (isServerError) {
          return error;
        } else {
          ShowNotification({
            type: 'error',
            children: error?.message || i18next.t('Error fetching services'),
          });
        }
      }
    }
  };

  fetchSingleService = async (serviceSlug: string, isModerate?: boolean) => {
    try {
      const response = await Partner.getSingleService(serviceSlug);
      if (response?.status === 200) {
        this.setIsErrorServices(false);
        const serviceData = response?.data;
        const getStatus = (value: Status) => {
          switch (value) {
            case Status.active: {
              return FILTERS_LABELS.ACTIVE;
            }
            case Status.reviewed: {
              return FILTERS_LABELS.IN_REVIEW;
            }
            case Status.archived: {
              return FILTERS_LABELS.ARCHIVED;
            }
            case Status.rejected: {
              return FILTERS_LABELS.REJECTED;
            }
            case Status.moderated: {
              return FILTERS_LABELS.IN_REVIEW;
            }
          }
        };
        const status: Status = serviceData.status;
        this.singleService = {
          status: getStatus(status),
          name: getLangName(serviceData, 'name', i18n),
          id: serviceData?.id,
          currency: userStore?.user?.currency,
          files: serviceData.files.map((file: any) => ({
            name: file.name,
            link: file?.link,
            size: file?.file_size,
            id: file?.id,
          })),
          worksFor: getLangNameNew(serviceData, 'available_for', i18n),
          service_type: serviceData?.service_type,
          description: getLangName(serviceData, 'description', i18n),
          full_description: getLangName(serviceData, 'description', i18n),
          short_description: getLangName(
            serviceData,
            'short_description',
            i18n
          ),
          period_comment: getLangName(serviceData, 'period_comment', i18n),
          product: serviceData?.product,
          stages: serviceData?.service_stage,
          cases: serviceData?.case,
          company_size: serviceData?.company_size,
          business_type: serviceData?.business_type,
          goals: serviceData?.goals,
          cost: serviceData?.cost_with_discounts,
          price_per: serviceData?.price_per,
          is_free: serviceData?.is_free,
          countries: serviceData?.countries,
          slug: serviceData?.slug,
          payment_types: serviceData?.payment_type,
          partner_name: serviceData?.partner_name,
          product_crm_id: serviceData?.product_crm_id,
          product_crm_name: serviceData?.product_crm_name
        };
      }
      if (isModerate) {
        this.setCopyOfService(toJS(this.singleService));
        this.serviceToActivate = response?.data?.slug;
        this.serviceToChange = response?.data?.id;
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const isNotFoundError =
          (error.response && error.response?.status === 404) ||
          (error.request && error.request?.status === 404);
        if (isNotFoundError) {
          this.setIsErrorServices(true);
          return error;
        }
        const isServerError =
          (error.response && error.response?.status >= 500) ||
          (error.request && error.request?.status >= 500);
        if (isServerError) {
          return error;
        } else {
          ShowNotification({
            type: 'error',
            children: error?.message || i18next.t('Error fetching service'),
          });
        }
      }
    }
  };

  fetchSingleServiceAnalytics = async (params: string, serviceSlug: string) => {
    try {
      const response = await Partner.getSingleServiceAnalytics(
        params,
        serviceSlug
      );
      if (response?.status === 200) {
        this.analytics = response?.data.results;
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const isServerError =
          (error.response && error.response?.status >= 500) ||
          (error.request && error.request?.status >= 500);
        if (isServerError) {
          return error;
        } else {
          ShowNotification({
            type: 'error',
            children: error?.message || i18next.t('Error fetching service'),
          });
        }
      }
    }
  };

  fetchSolutionGoals = async () => {
    const response = await Partner.getSolutionGoals();
    this.goals = response?.data?.results;
  };

  createPartnerSolution = async (data: any) => {
    try {
      const response = await Partner.createSolution(data);
      if (response?.status === 201) {
        ShowNotification({
          type: 'success',
          children: i18next.t('Solution created successfully'),
        });
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const isServerError =
          (error.response && error.response?.status >= 500) ||
          (error.request && error.request?.status >= 500);
        if (isServerError) {
          return error;
        } else {
          ShowNotification({
            type: 'error',
            children: error?.message || i18next.t('Error creating solution'),
          });
        }
      }
    }
  };

  getPartnerCases = async (partnerId?: number) => {
    try {
      const response = await Partner.getCases(partnerId);
      this.partnerCases = response?.data?.results;
      return response?.data?.results;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const isServerError =
          (error.response && error.response?.status >= 500) ||
          (error.request && error.request?.status >= 500);
        if (isServerError) {
          return error;
        } else {
          ShowNotification({
            type: 'error',
            children: error?.message || i18next.t('Error fetching cases'),
          });
        }
      }
    }
  };

  setSelectExistingCases = (cases: any) => {
    this.selectedExistingCases = cases;
  };

  setCopyOfService = (service: any) => {
    this.copiedService = service;
  };

  clearCopyOfService = () => {
    this.copiedService = {};
    this.singleService = {};
    this.selectedExistingCases = [];
  };

  clearCompletedFormSteps = () => {
    this.isCompletedFormSteps = {
      stepOne: false,
      stepTwo: false,
      stepThree: false,
      stepFour: false,
    };
  };

  clearStore = () => {
    this.servicesCount = 0;
    this.services = [];
    this.clearCompletedFormSteps();
    this.clearCopyOfService();
  };
}

export const partnerStore = new PartnerStore();
