import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import Modals from '../Modals';
import React from 'react';
import { initialSteps, materialsData } from '../utils';
import FirsStep from './FirstStep';
import classes from './FormBlock.module.scss';
import { FormBlockProps } from './FormBlock.props';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import { useFormBlock } from './useFormBlock';
import { PageLoader } from 'components/shared/PageLoader';
import FourthStep from './FourthStep';
import clsx from 'clsx';

export const FormBlock = observer(
  ({
    setCurrentStep,
    currentStep,
    copiedService,
    isDisabled,
    isFormBlock,
    openCaseModal,
    setOpenCaseModal,
    isShowHits = true,
    isModeratePage,
    isChangePage,
  }: FormBlockProps) => {
    const {
      methods,
      productTypes,
      crmProductTypes,
      industryTypes,
      dictionaries,
      formatDictionaryTypes,
      internalData,
      goals,
      setOpenCancelModal,
      platforms,
      setOpenSubmitModal,
      openCancelModal,
      openSubmitModal,
      handleSubmit,
      handleCancel,
      t,
      isCreating,
      openDeclineModal,
      setOpenDeclineModal,
      handleDeclineSolution,
    } = useFormBlock(
      copiedService,
      currentStep,
      isDisabled,
      isModeratePage,
      isChangePage
    );

    return (
      <div
        className={clsx(
          classes.formBlock,
          isModeratePage && classes.isModeratePage
        )}
      >
        <form className={classes.form}>
          {currentStep === 1 && (
            <FirsStep
              productTypes={productTypes}
              crmProductTypes={crmProductTypes}
              industryTypes={industryTypes || []}
              companySizes={formatDictionaryTypes(dictionaries?.company_size)}
              serviceTypes={formatDictionaryTypes(dictionaries?.service_types)}
              initialSteps={internalData?.stages || initialSteps(t)}
              defaultGoals={goals}
              setCurrentStep={setCurrentStep}
              setOpenCancelModal={setOpenCancelModal}
              materialsData={materialsData(t)}
              copiedService={internalData}
              disabled={isDisabled}
              currentStep={currentStep}
              isFormBlock={isFormBlock}
              isModeratePage={isModeratePage}
              isChangePage={isChangePage}
              setOpenDeclineModal={setOpenDeclineModal}
            />
          )}

          {currentStep === 2 && (
            <Controller
              render={({ field }) => (
                <SecondStep
                  setCurrentStep={setCurrentStep}
                  setOpenCancelModal={setOpenCancelModal}
                  setOpenDeclineModal={setOpenDeclineModal}
                  platforms={platforms}
                  disabled={isDisabled}
                  defaultValues={field.value}
                  currentStep={currentStep}
                  openCasesModal={openCaseModal}
                  setOpenCasesModal={setOpenCaseModal}
                  isShowHits={isShowHits}
                  isModeratePage={isModeratePage}
                />
              )}
              name="cases"
              control={methods.control}
            />
          )}

          {currentStep === 3 && (
            <ThirdStep
              setCurrentStep={setCurrentStep}
              setOpenSubmitModal={setOpenSubmitModal}
              setOpenCancelModal={setOpenCancelModal}
              disabled={isDisabled}
              isFormBlock={isFormBlock}
              currentStep={currentStep}
              isModeratePage={isModeratePage}
              setOpenDeclineModal={setOpenDeclineModal}
            />
          )}

          {currentStep === 4 && (
            <FourthStep
              setCurrentStep={setCurrentStep}
              setOpenCancelModal={setOpenCancelModal}
              disabled={isDisabled}
              setOpenSubmitModal={setOpenSubmitModal}
              t={t}
              isFormBlock={isFormBlock}
              currentStep={currentStep}
              isModeratePage={isModeratePage}
              isChangePage={isChangePage}
              setOpenDeclineModal={setOpenDeclineModal}
              handleSubmit={handleSubmit}
            />
          )}
        </form>

        <Modals
          openCancelModal={openCancelModal}
          setOpenCancelModal={setOpenCancelModal}
          openSubmitModal={openSubmitModal}
          setOpenSubmitModal={setOpenSubmitModal}
          openDeclineModal={openDeclineModal}
          setOpenDeclineModal={setOpenDeclineModal}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onDecline={handleDeclineSolution}
          currentStep={currentStep}
        />

        {isCreating && <PageLoader />}
      </div>
    );
  }
);
