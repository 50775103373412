import clsx from 'clsx';
import React from 'react';
import { Grid } from 'components/common/Grid';
import { observer } from 'mobx-react-lite';
import { GridItem } from '../common/Grid';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { CardManager } from './CardManager';
import { Cases } from './Cases';
import { OurTeam } from './OurTeam';
import { Price } from './Price';
import classes from './Preview.module.scss';
import { ServiceInfo } from './ServiceInfo';
import { usePreview } from './usePreview';
import { getLangName } from 'tools/utils';
import { FC } from 'react';
import { GoalType } from '@type/partner';
import { NoticeBox } from '../shared/NoticeBox';
import { NOTICE } from 'constants/enum';
import { toJS } from 'mobx';

interface PreviewProps {
  data: any;
  isDisabled?: boolean;
  className?: string;
}

export const Preview: FC<PreviewProps> = observer(
  ({ data, isDisabled, className }) => {
    const { width } = useWindowWidth();
    const { serviceInfo, t, i18n } = usePreview(data, isDisabled);

    return (
      <>
        {!isDisabled && (
          <div className={classes.noticeBlock}>
            <NoticeBox
              type={NOTICE.DEFAULT}
              title={t(
                "At this point, you can see how your solution will appear on the Client's showcase"
              )}
              description={t(
                'If there are any changes you would like to make, please go back to previous steps. If you are happy with everything, then please send the solution for review'
              )}
            />
          </div>
        )}

        <div className={classes.wrapper} data-step-preview_solution_p-0>
          <Grid className={classes.grid} direction={'row'}>
            <GridItem col={{ default: 12, xsup: 8 }} className={classes.left}>
              <ServiceInfo data={serviceInfo} />
              {serviceInfo?.worksFor && serviceInfo?.worksFor !== '' && (
                <OurTeam
                  data={getLangName(serviceInfo, 'worksFor', i18n)}
                  title={t('Works for')}
                />
              )}

              {width < 961 && <CardManager alt="Manager" />}

              {serviceInfo?.fullDescription &&
                serviceInfo?.fullDescription !== '' && (
                  <OurTeam
                    data={getLangName(serviceInfo, 'fullDescription', i18n)}
                    title={t('Project stages')}
                  />
                )}

              {serviceInfo?.goals?.length > 0 && (
                <div className={classes.goals}>
                  <div className={classes.goals_title}>
                    {t('Expected results')}
                  </div>
                  <div className={classes.goals_container}>
                    {serviceInfo.goals?.map((goal: GoalType, index: number) => (
                      <div
                        key={index}
                        className={clsx(classes.goals_tag, className)}
                      >
                        {goal?.name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {serviceInfo?.cases && serviceInfo?.cases?.length > 0 && (
                <Cases data={serviceInfo.cases} isPreview />
              )}
            </GridItem>

            {width > 960 && (
              <GridItem
                col={{ default: 12, xsup: 4 }}
                className={classes.price}
              >
                <div className={clsx(classes.sticky)}>
                  <div className={classes['price-block']}>
                    <Price data={serviceInfo} nameSource={serviceInfo.name} />
                  </div>

                  <div className={classes.manager}>
                    <CardManager alt="Manager" />
                  </div>
                </div>
              </GridItem>
            )}
          </Grid>
        </div>
      </>
    );
  }
);
