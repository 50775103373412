import ColorSticker, { StickerColor } from 'components/shared/ColorSticker';
import classes from './SolutionHeader.module.scss';
import { TFunction } from 'i18next';
import React from 'react';

type SolutionHeaderProps = {
  title: string;
  statusText: string;
  solutionId: string;
  solutionType: {
    name: string;
    link: string;
  };
  stickerColor: StickerColor;
  isMobile?: boolean;
  t: TFunction;
  isSupervisor?: boolean;
  partnerName?: string;
};

function SolutionHeader(props: SolutionHeaderProps) {
  return (
    <div className={classes.header}>
      <h1 className={classes.header__title}>{props.title}</h1>
      <div className={classes.header__info}>
        <div className={classes.header__info_item}>
          <ColorSticker
            text={props.t(`${props.statusText}`)}
            color={props.stickerColor}
          />
        </div>
        <div className={classes.header__info_item}>
          {!props.isMobile && (
            <span className={classes['header__type-text']}>
              {props.t('Type of product')}
            </span>
          )}
          <span className={classes.header__type}>
            {props.solutionType?.name}
          </span>
        </div>
        <div className={classes.header__info_item}>
          <span className={classes.header__id}>ID {props?.solutionId}</span>
        </div>
        {props?.isSupervisor && props?.partnerName && (
          <div className={classes.header__type}>{props.partnerName}</div>
        )}
      </div>
    </div>
  );
}

export default SolutionHeader;
