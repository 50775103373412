import { yupResolver } from '@hookform/resolvers/yup';
import { useCommonStore } from 'contexts/CommonContext';
import { usePartnerStore } from 'contexts/PartnerContext';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getLangName } from 'tools/utils';
import { RUSSIAN_LOCALE } from 'utils';
import * as yup from 'yup';
import { convertFileToBase64 } from '../utils';
import { CaseProps, FormValidationProps } from './FormBlock.props';
import {
  firstStepSchema,
  secondStepSchema,
  thirdStepSchema,
} from './validationSchemas';
import { userStore } from 'stores/user-store';
import { useUserStore } from 'contexts/UserContext';
import { commonStore } from 'stores/commonStore';

export const useFormBlock = (
  copiedService: any,
  currentStep?: number,
  isDisabled?: boolean,
  isModeratePage?: boolean,
  isChangePage?: boolean
) => {
  const { t, i18n } = useTranslation();
  const initialBusinessTypes = useCommonStore().dictionaries?.business_types;
  const navigate = useNavigate();
  const {
    goals,
    createPartnerSolution,
    singleService,
    setCopyOfService,
    serviceToActivate,
    declineService,
    changeSolution,
  } = usePartnerStore();

  const { isSupervisor } = useUserStore();
  const { 
    dictionaries, 
    products, 
    platforms: platformData, 
    crmProducts 
  } = useCommonStore();
  const partner = userStore?.partners?.results?.[0];

  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false);
  const [openDeclineModal, setOpenDeclineModal] = useState<boolean>(false);
  const [schema, setSchema] = useState<yup.AnyObjectSchema>(firstStepSchema(t));
  const [internalData, setInternalData] = useState<any>(singleService);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const methods = useForm<FormValidationProps>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: getValuesForForm(internalData),
  });

  useEffect(() => {
    const partnerLevel = userStore?.partners?.results?.[0]?.level;
  
    if (partnerLevel === 'INTERNAL' && RUSSIAN_LOCALE) {
      commonStore.loadCrmProducts();
    }
  }, []);

  useEffect(() => {
    const handleLanguageChange = () => {
      const currentErrors = methods.formState.errors;
      if (Object.keys(currentErrors).length > 0) {
        Object.keys(currentErrors).forEach((field: any) => {
          methods.trigger(field);
        });
      }
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, methods]);

  useEffect(() => {
    if (currentStep === 1) {
      setSchema(firstStepSchema(t));
    } else if (currentStep === 2) {
      setSchema(secondStepSchema(t));
    } else if (currentStep === 3) {
      setSchema(thirdStepSchema(t));
    }
  }, [currentStep, i18n.language]);

  useEffect(() => {
    if (copiedService) {
      setInternalData(copiedService);
    }
  }, [copiedService]);

  useEffect(() => {
    setInternalData(singleService);
    setFormValues(singleService);
  }, [singleService]);

  const industryTypes = initialBusinessTypes?.map((item) => ({
    value: item.id,
    label: getLangName(item, 'name', i18n),
  }));

  const formatDictionaryTypes = (externalData: any) =>
    externalData?.map((item: any) => ({
      value: item?.type || item?.id,
      label: getLangName(item, 'name', i18n),
    })) || [];

  const platforms = platformData?.map((item: any) => ({
    value: item.id,
    label: getLangName(item, 'name', i18n),
  }));

  const productTypes = internalData?.productTypes || products;
  const crmProductTypes = internalData?.crmProductTypes || crmProducts;

  function getValuesForForm(internalData: any) {
    const defaultCase = () => {
      if (
        internalData?.cases?.length &&
        (isDisabled || isModeratePage || isChangePage)
      ) {
        return internalData?.cases?.map((item: any) => ({
          caseName: item?.name,
          caseId: item?.id,
          caseBusinessType: item?.business_type?.id,
          platforms: item?.platforms,
          kpi: item?.case_kpises?.map((kpi: any, index: number) => ({
            title: `KPI ${index + 1}`,
            step: index + 1,
            name: kpi?.description,
            value: kpi?.amount,
            valueType: kpi?.amount_type,
            unit: kpi?.amount_unit,
          })),
        }));
      } else return [];
    };
    return {
      name: internalData?.name || '',
      worksFor: internalData?.worksFor || '',
      companySize: internalData?.company_size,
      industry: internalData?.business_type,
      goals: internalData?.goals || [],
      shortDescription: internalData?.short_description || '',
      serviceTypes: internalData?.service_type,
      fullDescription: internalData?.full_description || '',
      productType: internalData?.product?.id || 0,
      crmProductType: internalData?.product_crm_id, 
      price: internalData?.cost || 0,
      projectDuration: internalData?.period_comment,
      cases: defaultCase(),
      currency: RUSSIAN_LOCALE ? 'Российский рубль, ₽' : 'USD',
      'useCases-2': {
        name: internalData?.files?.[0]?.name || '',
        size: internalData?.files?.[0]?.size || 0,
        id: internalData?.files?.[0]?.id,
      },
      is_free: internalData?.is_free || false,
      countries: internalData?.countries,
      payment_type: internalData?.payment_types,
    };
  }

  function setFormValues(internalData: any) {
    methods.reset(getValuesForForm(internalData));
  }

  const watchedFields = useWatch({ control: methods?.control });

  useEffect(() => {
    setCopyOfService(watchedFields);
  }, [setCopyOfService, watchedFields]);

  const handleSubmit = async (e: Event, serviceToChange?: number) => {
    e.preventDefault();
    setIsCreating(true);
    const values = copiedService ?? methods.getValues();

    const fileData = await convertFileToBase64(values?.['useCases-2']);
    const filesData = values?.['useCases-2']?.id
      ? [
          {
            id: values?.['useCases-2']?.id,
          },
        ]
      : [
          {
            name: values?.['useCases-2']?.name,
            body: fileData,
          },
        ];

    const goals = values.goals?.map((item: any) => {
      if (item?.name) {
        return { name: item?.name };
      }
    });

    const countries = values.countries?.map((item: any) => item.id);
    const payment_type = values.payment_type?.map((item: any) => item.id);
    const company_size = values.companySize?.map(
      (item: any) => item.id || item.type
    );

    const data = {
      partner_id: partner?.id,
      name: values.name,
      works_for: values.worksFor,
      product: values.productType,
      product_crm: values?.crmProductType, 
      business_type: values.industry?.map((item: any) => item.id),
      company_size,
      goals,
      service_type: values.serviceTypes?.map((item: any) => item.id),
      description: values.fullDescription,
      short_description: values.shortDescription,
      period_comment: values.projectDuration,
      files: filesData,
      cases: values.cases?.map((item: CaseProps) => {
        if (item?.isExistingCase) {
          return { id: item?.id };
        } else {
          return {
            id: item?.caseId,
            name: item.caseName,
            business_type: item.caseBusinessType,
            platforms: item.platforms?.map((platform: any) => platform.id),
            case_kpises: item.kpi?.map((kpi: any) => ({
              description: kpi?.name,
              amount: kpi?.value,
              amount_type: kpi?.valueType,
              amount_unit: kpi?.unit,
            })),
          };
        }
      }),
      price_per: 'BUDGET',
      cost: String(values?.price)?.replace(/\s+/g, ''),
      currency: RUSSIAN_LOCALE ? 'RUB' : 'USD',
      is_free: values.is_free,
      countries,
      payment_type: values.is_free ? [6] : payment_type,
    };

    if (serviceToChange) {
      await changeSolution(serviceToChange, data)
        .then(() => {
          setOpenSubmitModal(false);
          navigate('/solutions/');
        })
        .finally(() => {
          setIsCreating(false);
        });
    } else if (!isSupervisor) {
      await createPartnerSolution(data)
        .then(() => {
          setOpenSubmitModal(false);
          navigate('/solutions/');
        })
        .finally(() => {
          setIsCreating(false);
        });
    }
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    navigate('/solutions/');
  };

  const handleDeclineSolution = async (e: any) => {
    e.preventDefault();
    await declineService(serviceToActivate).then(() => {
      navigate('/solutions');
    });
  };

  return {
    methods,
    productTypes,
    crmProductTypes,
    industryTypes,
    dictionaries,
    formatDictionaryTypes,
    internalData,
    goals,
    setOpenCancelModal,
    platforms,
    setOpenSubmitModal,
    openCancelModal,
    openSubmitModal,
    handleSubmit,
    handleCancel,
    t,
    isCreating,
    openDeclineModal,
    setOpenDeclineModal,
    handleDeclineSolution,
  };
};
