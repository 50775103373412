import { useEffect, useMemo, useState } from 'react';
import { columnsArrayEmployees } from './employeesTableData';
import { PARTNER_ROLE, UserOnPartner } from 'types/user';
import { useUserStore } from 'contexts/UserContext';
import { ShowNotification } from 'tools/showNotification';
import { useTranslation } from 'react-i18next';
import UserService from 'services/user';
import { useNavigate } from 'react-router-dom';
import { routers } from 'constants/routers';

export const useEmployees = () => {
  const { t, i18n } = useTranslation();
  const { loadPartnerById, partners, user, isSupervisor, businessUnits } =
    useUserStore();
  const navigate = useNavigate();
  const partner = partners?.results?.[0];
  const isAccessEmployeesTable =
    partner &&
    (partner.permission?.type === PARTNER_ROLE.ADMIN ||
      partner.permission?.type === PARTNER_ROLE.MANAGER ||
      isSupervisor) &&
    partner.is_active;

  const columns = useMemo(
    () => columnsArrayEmployees(isSupervisor),
    [i18n.language, partner?.permission?.type]
  );
  const [clients, setClients] = useState<UserOnPartner[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isAccessEmployeesTable) {
      navigate(routers.personal);
    }
  }, [isAccessEmployeesTable, navigate]);

  const getUsers = async () => {
    setIsLoading(true);

    try {
      const result = await UserService.getPartners();

      const sortedData = result?.data?.users?.sort((a: any, b: any) => {
        const aIsUndefined = a?.id === undefined;
        if (aIsUndefined) a.tempId = result?.data?.users.length;
        const bIsUndefined = b?.id === undefined;
        if (bIsUndefined) b.tempId = result?.data?.users.length + 1;
        const aUndefinedUserSerialNumber = a?.serialNumber?.id;
        const bUndefinedUserSerialNumber = b?.serialNumber?.id;

        if (a.tempId && b.tempId) return a.tempId - b.tempId;

        if (user?.id === a?.id) return 1;
        if (user?.id === b?.id) return -1;

        if (aIsUndefined && !bIsUndefined) return -1;
        if (!aIsUndefined && bIsUndefined) return 1;

        if (aUndefinedUserSerialNumber && !bUndefinedUserSerialNumber)
          return -1;
        if (!aUndefinedUserSerialNumber && bUndefinedUserSerialNumber) return 1;

        return b.id - a.id;
      });

      setClients(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleDeletePermissions = async (
    id: number,
    email: string,
    partnerId?: number
  ) => {
    try {
      const result = await UserService.deleteUserPermissions(
        partner.id,
        email,
        id,
        partnerId
      );

      if (
        result.data?.status === true ||
        result.data?.status?.toLowerCase() === 'true' ||
        result.data?.status === 'Permission successfully removed'
      ) {
        ShowNotification({
          type: 'success',
          title: t('The employee has been removed'),
          children: t("The user no longer has access to your Partner's data"),
          isNewToast: true,
        });
        await getUsers();
      } else if (result.data.status === false) {
        ShowNotification({
          type: 'error',
          children: t('Error deleting user'),
        });
      }
    } catch (error: any) {
      console.error('Error deleting user permissions:', error);
    }
  };

  const handleChangePermissions = async (
    id: number,
    role: PARTNER_ROLE,
    email: string,
    partnerId?: number
  ) => {
    try {
      const result = await UserService.changeUserPermissions(
        partner.id,
        role,
        email,
        id,
        partnerId
      );

      if (
        result?.data?.status === true ||
        result.data?.status?.toLowerCase() === 'true'
      ) {
        await getUsers();
      }
    } catch (error: any) {
      console.error('Error changing user permissions:', error);
    }
  };

  return {
    partner,
    isLoading,
    clients,
    columns,
    handleDeletePermissions,
    getUsers,
    handleChangePermissions,
    isSupervisor,
    businessUnits,
  };
};
