import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={props?.viewBox || '0 0 24 24'}
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_4627_242154)">
      <path
        d="M4.66669 4.66669V3.03335C4.66669 2.37996 4.66669 2.05326 4.79385 1.8037C4.9057 1.58418 5.08418 1.4057 5.3037 1.29385C5.55326 1.16669 5.87996 1.16669 6.53335 1.16669H10.9667C11.6201 1.16669 11.9468 1.16669 12.1963 1.29385C12.4159 1.4057 12.5943 1.58418 12.7062 1.8037C12.8334 2.05326 12.8334 2.37996 12.8334 3.03335V7.46669C12.8334 8.12008 12.8334 8.44678 12.7062 8.69634C12.5943 8.91586 12.4159 9.09434 12.1963 9.20619C11.9468 9.33335 11.6201 9.33335 10.9667 9.33335H9.33335M3.03335 12.8334H7.46669C8.12008 12.8334 8.44678 12.8334 8.69634 12.7062C8.91586 12.5943 9.09434 12.4159 9.20619 12.1963C9.33335 11.9468 9.33335 11.6201 9.33335 10.9667V6.53335C9.33335 5.87996 9.33335 5.55326 9.20619 5.3037C9.09434 5.08418 8.91586 4.9057 8.69634 4.79385C8.44678 4.66669 8.12008 4.66669 7.46669 4.66669H3.03335C2.37996 4.66669 2.05326 4.66669 1.8037 4.79385C1.58418 4.9057 1.4057 5.08418 1.29385 5.3037C1.16669 5.55326 1.16669 5.87996 1.16669 6.53335V10.9667C1.16669 11.6201 1.16669 11.9468 1.29385 12.1963C1.4057 12.4159 1.58418 12.5943 1.8037 12.7062C2.05326 12.8334 2.37996 12.8334 3.03335 12.8334Z"
        stroke={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4627_242154">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
