import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosError } from 'axios';
import Common from 'services/common';
import StatusFactory from 'tools/status';
import {
  CompanyProduct,
  Country,
  CrmProduct,
  DevicePlatform,
  Dictionaries,
  KpiOptions,
} from '@type/common';
import { ValueType } from 'types/util-types';
import { IStatus, ListResponse } from '@type/api';

export class CommonStore {
  countries: Country[] = [];
  loadCountriesStatus: IStatus<ListResponse<Country> | Country[] | null> =
    StatusFactory.default();
  products: CompanyProduct[] = [];
  loadProductsStatus: IStatus<ListResponse<CompanyProduct> | null> =
    StatusFactory.default();
  crmProducts: CrmProduct[] = [];
  platforms: DevicePlatform[] = [];
  kpiOptions: KpiOptions<ValueType[]> | undefined;
  dictionaries: Dictionaries | undefined;
  isSearchResults = false;

  constructor() {
    makeAutoObservable(this);
  }

  public resetLoadCountriesStatus(): void {
    this.loadCountriesStatus = StatusFactory.default();
  }

  public async loadCountries(
    params?: { all?: boolean; page?: number; page_size?: number },
    onError?: () => void
  ) {
    this.loadCountriesStatus = StatusFactory.loading();

    try {
      let allCountries: Country[];
      let pageCountries: ListResponse<Country>;

      if (params?.all) {
        allCountries = await Common.getCountries<Country[]>(params);
      } else {
        pageCountries = await Common.getCountries(params);
      }

      runInAction(() => {
        this.countries = allCountries || [
          ...this.countries,
          ...pageCountries.results,
        ];
        this.loadCountriesStatus = StatusFactory.success(
          allCountries || pageCountries
        );
      });
    } catch (error) {
      onError?.();
      runInAction(() => {
        this.loadCountriesStatus = StatusFactory.failed(error as AxiosError);
      });
    }
  }

  public async loadCompanyProducts(
    params?: { page?: number },
    onError?: (error: AxiosError) => void
  ) {
    this.loadProductsStatus = StatusFactory.loading();
    try {
      const result = await Common.getCompanyProducts(params);

      runInAction(() => {
        this.products = result.data.results;
      });

      this.loadProductsStatus = StatusFactory.success(result.data);
    } catch (error) {
      onError?.(error as AxiosError);
      this.loadProductsStatus = StatusFactory.failed(error as AxiosError);
    }
  }

  public async loadCrmProducts(params?: string) {
    try {
      const result = await Common.getCrmProducts(params);
      runInAction(() => {
        this.crmProducts = result.data.results;
      });
    } catch (error) {
      console.error('Error loading CRM products', error);
    }
  }

  async loadPlatforms() {
    const result = await Common.getPlatforms();
    this.platforms = result?.data.results;
  }

  getCaseKpiOptions = async () => {
    const result = await Common.getCaseKpi();
    this.kpiOptions = result?.data;
  };

  loadDictionaries = async () => {
    const result = await Common.getDictionaries();
    this.dictionaries = result?.data;
  };

  setIsSearchResults = (isSearchResults: boolean) => {
    this.isSearchResults = isSearchResults;
  };

  clearStore() {
    this.countries = [];
    this.dictionaries = undefined;
  }
}

export const commonStore = new CommonStore();
