import React, { Dispatch, SetStateAction } from 'react';
import { ModalMobile } from 'components/shared/ModalMobile';
import clsx from 'clsx';
import classes from '../MenuCell.module.scss';
import { Button } from 'components/shared/Button';
import { OptionTypeString } from 'types/util-types';
import { Radio } from 'components/shared/Radio';
import { PARTNER_ROLE } from 'types/user';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import HeaderButtons from './HeaderButtons';

interface AdminEmployeeMobileModalProps {
  value: any;
  isShowMenuModal: boolean;
  handleCloseMenuModal: () => void;
  isDisabledChangeRole?: boolean;
  handleChangePermissions: (
    id: number,
    role: PARTNER_ROLE,
    email: string
  ) => Promise<void>;
  handleClose: () => void;
  handleShowDotsModal: () => void;
  typeUser: PARTNER_ROLE;
  setTypeUser: (type: PARTNER_ROLE) => void;
  employeesRoles: OptionTypeString[];
  isSupervisor?: boolean;
  setIsClicked?: Dispatch<
    SetStateAction<{ role: boolean; businessUnit: boolean }>
  >;
}

const AdminEmployeeMobileModal = observer(
  ({
    value,
    isShowMenuModal,
    handleCloseMenuModal,
    isDisabledChangeRole,
    handleChangePermissions,
    handleClose,
    handleShowDotsModal,
    typeUser,
    setTypeUser,
    employeesRoles,
    isSupervisor,
    setIsClicked,
  }: AdminEmployeeMobileModalProps) => {
    const { t } = useTranslation();
    return (
      <ModalMobile
        title=""
        isOpen={isShowMenuModal}
        onClose={handleCloseMenuModal}
        isBasicHeader={false}
        crossIcon={!isSupervisor}
      >
        <div className={classes.inner}>
          <div className={clsx(classes.headerWrapper)}>
            {isSupervisor ? (
              <HeaderButtons
                name={t('Role table')}
                handleCloseModal={() => {
                  handleCloseMenuModal();
                  setIsClicked &&
                    setIsClicked({ role: false, businessUnit: false });
                }}
                handleBackToMenu={() =>
                  setIsClicked
                    ? setIsClicked({ role: false, businessUnit: false })
                    : null
                }
              />
            ) : (
              <>
                <h3
                  className={clsx(
                    classes.title,
                    !value.id && classes.titleWithoutId
                  )}
                >
                  {value.full_name || value.email}
                </h3>
                {value.id && (
                  <h3 className={classes.titleEmail}>
                    <a>{value.email}</a>
                  </h3>
                )}
              </>
            )}

            <div className={classes.radioGroup}>
              {employeesRoles.map((role: OptionTypeString) => (
                <Radio
                  key={role.id}
                  name="role"
                  value={role}
                  checked={typeUser === role.id}
                  onChange={(item) => {
                    setTypeUser(item?.id as PARTNER_ROLE);
                    setIsClicked &&
                      setIsClicked({ role: false, businessUnit: false });
                  }}
                  label={role.name ? t(role.name) : ''}
                  description={role.description ? t(role.description) : ''}
                  disabled={isDisabledChangeRole}
                />
              ))}
            </div>
          </div>

          {!isSupervisor && (
            <div className={classes.buttons}>
              <Button
                className={classes.button}
                theme="primary"
                size="middle"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (typeUser !== value.permission.type)
                    await handleChangePermissions(
                      value.id,
                      typeUser,
                      value.email
                    );

                  handleClose();
                }}
                disabled={isDisabledChangeRole}
              >
                {t('Save changes')}
              </Button>
              <Button
                className={classes.button}
                theme="primary-light"
                size="middle"
                onClick={handleShowDotsModal}
              >
                {t('Remove')}
              </Button>
            </div>
          )}
        </div>
      </ModalMobile>
    );
  }
);

export default AdminEmployeeMobileModal;
