import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classes from './EmployeesInvite.module.scss';
import { Select } from 'components/shared/Select';
import { Button } from 'components/shared/Button';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useMemo, useState } from 'react';
import { OptionTypeString } from 'types/util-types';
import { EmployeesInviteProps } from './EmployeesInvite.props';
import { getEmployeesRoles } from '../employeesRoles';
import { ShowNotification } from 'tools/showNotification';
import { REGEX_EMAIL_254 as emailCheckReg } from 'constants/matches';
import NewInput from 'components/shared/NewInput';
import UserService from 'services/user';
import { useUserStore } from 'contexts/UserContext';
import SupervisorModalInvite from './MobileInvite/SupervisorModalInvite';
import AdminMobileInvite from './MobileInvite/AdminMobileInvite';
import { RUSSIAN_LOCALE } from 'utils';

export interface FormValidationProps {
  email: string;
  role: OptionTypeString;
  bUnit: OptionTypeString;
}

const EmployeesInvite = ({ partner, getUsers }: EmployeesInviteProps) => {
  const { t, i18n } = useTranslation();
  const { isSupervisor, businessUnits } = useUserStore();
  const employeesRoles = useMemo(() => getEmployeesRoles(), [i18n.language]);

  const schema = yup.object().shape({
    email: yup
      .string()
      .matches(emailCheckReg, t('Enter an existing email address'))
      .required(t('Enter an existing email address')),
    role: yup.object({
      id: yup.string().required(t('Role is required')),
    }),
  });

  const supervisorSchema = yup.object().shape({
    email: yup
      .string()
      .matches(emailCheckReg, t('Enter an existing email address'))
      .required(t('Enter an existing email address')),
    role: yup.object({
      id: yup.string().required(t('Role is required')),
    }),
    bUnit: yup.object({
      id: yup.string().required(t('Business unit is required')),
    }),
  });

  const { isMediaTablet, isSmallLaptop, width } = useWindowWidth();

  const [showInviteModal, setShowInviteModal] = useState(false);

  const handleShowInviteModal = () => {
    setShowInviteModal(true);
  };

  const handleCloseInviteModal = () => {
    setShowInviteModal(false);
    reset();
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    reset,
    getValues,
    trigger,
  } = useForm<FormValidationProps>({
    resolver: yupResolver(isSupervisor ? supervisorSchema : (schema as any)),
    defaultValues: {
      email: '',
    },
    mode: isMediaTablet ? 'onSubmit' : 'onChange',
  });

  const onSubmit = async (data: any, errors?: any) => {
    const isValid = await trigger();

    const errorMessage =
      (!data?.role && t('Role is required')) ||
      errors?.email?.message ||
      (!data.email && t('Enter an existing email address')) ||
      errors?.role?.id?.message;

    if (!isValid && !isMediaTablet) {
      ShowNotification({
        type: 'error',
        children: errorMessage || errors?.role?.id?.message,
      });
      return;
    }
    if (!data.email || !data.role?.id || (isSupervisor && !data.bUnit?.id)) {
      return;
    }
    try {
      const result = await UserService.getUserPermissions(
        partner?.id,
        data?.email,
        data?.role?.id,
        data?.bUnit?.id
      );
      if (
        result.data.status === true ||
        result.data.status === 'Permission successfully assigned'
      ) {
        ShowNotification({
          type: 'success',
          title: t('Invitation sent'),
          children: isSupervisor
            ? t(
                'User {{ name }} will get an email invitation within 15 minutes',
                { name: data.email }
              )
            : t('The invitation will arrive by email within 15 minutes'),
          isNewToast: true,
        });
        !isSmallLaptop && handleCloseInviteModal();
        await getUsers();
        reset();
      } else {
        ShowNotification({
          type: 'error',
          children: result.data.status,
        });
        !isSmallLaptop && handleCloseInviteModal();
      }
    } catch (error: any) {
      if (error?.response?.data?.email) {
        setError('email', {
          type: 'server',
          message: t(error.response.data.email?.[0]),
        });
        if (!isMediaTablet) {
          ShowNotification({
            type: 'error',
            children: t(error.response.data.email?.[0]),
            isNewToast: true,
          });
        }
      } else if (error?.status === 400 && RUSSIAN_LOCALE) {
        ShowNotification({
          type: 'error',
          title: t('Invite already sent'),
          children: t(
            'You cannot add a user who has already joined the partnership'
          ),
          isNewToast: true,
        });
      }
    }
  };

  const [isInputFocused, setIsInputFocused] = useState(false);

  const emailValue = watch('email');

  return (
    <>
      {width > 960 ? (
        <form style={{ position: 'relative', zIndex: 1 }}>
          <div className={classes.inputWrap}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <NewInput
                  {...field}
                  classNames={classes.input}
                  inputTitleClassName={classes.inputTitle}
                  type="text"
                  title={t('Invite employee by work email')}
                  placeholder={t('Invite employee by work email')}
                  error={errors?.email?.message}
                  maxLength={50}
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                />
              )}
            />

            <div className={classes.inputRight}>
              {emailValue && (
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        isRadio
                        select={employeesRoles?.find(
                          (item: OptionTypeString) =>
                            item?.id === field?.value?.id
                        )}
                        className={classes.selectRole}
                        title={t('Role')}
                        placeholder={t('Role')}
                        options={employeesRoles}
                        isSolutionForm
                        onSelect={(item: any) => field.onChange(item)}
                        isInputFocused={isInputFocused}
                        isInInput
                        isNotHoverEffect
                        small
                      />
                    );
                  }}
                />
              )}
              {emailValue && isSupervisor && (
                <Controller
                  name="bUnit"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        isRadio
                        select={businessUnits?.find((item: any) => {
                          return item?.id === field?.value?.id;
                        })}
                        className={classes.selectBUnit}
                        title={t('Business-unit')}
                        placeholder={t('Business-unit')}
                        options={businessUnits}
                        isSolutionForm
                        onSelect={(item: any) => field.onChange(item)}
                        isInputFocused={isInputFocused}
                        isInInput
                        isNotHoverEffect
                        small
                        dropdownClassName={classes.selectBUnit_dropdown}
                      />
                    );
                  }}
                />
              )}

              <Button
                type="button"
                className={classes.button}
                theme="primary"
                size="middle"
                disabled={emailValue.length === 0}
                onClick={async (e) => {
                  e.preventDefault();
                  await onSubmit(getValues(), errors);
                }}
              >
                {width < 1180 ? t('Invite') : t('Invite employee')}
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <>
          <Button
            theme="primary"
            size="big"
            className={classes.inviteEmployee}
            onClick={handleShowInviteModal}
          >
            <span>{t('Invite employee')}</span>
          </Button>
          <form>
            {isSupervisor ? (
              <SupervisorModalInvite
                data={businessUnits}
                showInviteModal={showInviteModal}
                handleCloseInviteModal={handleCloseInviteModal}
                onSubmit={onSubmit}
                control={control}
                errors={errors}
                employeesRoles={employeesRoles}
                t={t}
                emailValue={emailValue}
                formValues={getValues()}
                trigger={trigger}
              />
            ) : (
              <AdminMobileInvite
                showInviteModal={showInviteModal}
                handleCloseInviteModal={handleCloseInviteModal}
                onSubmit={onSubmit}
                control={control}
                errors={errors}
                employeesRoles={employeesRoles}
                t={t}
                emailValue={emailValue}
                formValues={getValues()}
                trigger={trigger}
              />
            )}
          </form>
        </>
      )}
    </>
  );
};

export default EmployeesInvite;
