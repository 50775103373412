import React, { FC } from 'react';
import clsx from 'clsx';
import classes from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import { LinksPolitic } from 'components/shared/LinksPolitic';
import visa_masterCard from '../../../assets/img/Visa_MasterCard.svg';
import { RUSSIAN_LOCALE } from '../../../utils';

interface FooterProps {
  className?: string;
  isAuth?: boolean;
}

export const Footer: FC<FooterProps> = ({ className, isAuth }) => {
  const { t } = useTranslation();

  return (
    <footer
      className={clsx(
        classes.footer,
        className && classes[className],
        isAuth && classes.auth
      )}
    >
      <div className="layout-inner">
        <div
          className={clsx(
            classes.inner,
            RUSSIAN_LOCALE && classes['inner__ru']
          )}
        >
          <div className={classes.copy} translate="no">
            &copy; {new Date().getFullYear()} {t('KIT Global')}
          </div>
          <LinksPolitic isAuth={isAuth} />
          {RUSSIAN_LOCALE && (
            <img
              className={classes.image}
              src={visa_masterCard}
              alt={'visa_masterCard'}
            />
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
