import { userStore } from 'stores/user-store';
import { RUSSIAN_LOCALE } from 'utils';
import * as yup from 'yup';

export const firstStepMessages = (t: I18next) => ({
  solution_name: t(
    t(
      'Please enter a name for your solution (up to 50 characters). This helps to identify your solution.'
    )
  ),
  product_type: t(
    'Choose a product type. It helps categorize your solution better.'
  ),
  crmProduct_type: t(
    'Link the solution to the CRM Product. Please select a suitable product or initiate the creation of a new one.'
  ),
  crmProduct_type_error: t(
    'Choose a CRM Product'
  ),
  service_types: t(
    'Selecting a service type enables clients to quickly find the solutions they need'
  ),
  industry: t(
    'Let us know the industries relevant to your solution. It helps provide context.'
  ),
  company_sizes: t(
    "Tell us the size of the companies you're targeting. This is crucial for planning and scalability."
  ),
  works_for: t('Describe your target customers in a few sentences'),
  goals: t(
    'Describe what you expect to deliver. Clear expectations keep everyone on the same page.'
  ),
  short_description: t(
    'Provide a short description (up to 150 characters). It should give a quick overview of the solution.'
  ),
  full_description: t(
    'Give a detailed description of the project (up to 32000 characters). This will help your customers fully understand it.'
  ),
  project_duration: t(
    'How long will the project take? Specify in months, weeks, or days (up to 50 characters). This is important for planning.'
  ),
});

export const addCaseMessages = (t: I18next) => ({
  case_name: t(
    'Enter the case title. This helps identify what the case is about'
  ),
  business_types: t(
    'Indicate the business type. This helps potential clients understand the industry of the case.'
  ),
  key_achievements: t(
    'Highlight the key achievement. This shows how similar problems were successfully resolved'
  ),
  platforms: t(
    'List the platforms used in the case. This shows the tools and technologies involved'
  ),
  description: t(
    'Provide a detailed description of the case. This helps clients understand the scope and details of the project'
  ),
});

export const kpiMessages = (t: I18next) => ({
  name: t(
    'Enter the name of the Key Performance Indicator (KPI) for which you are reporting the achieved results'
  ),
  value: t(
    'Enter the achieved value for the KPI. This is the numerical result you obtained for this metric'
  ),
  valueType: t('Select the type of value that reflects the results achieved'),
  unit: t(
    'Select the unit of measurement that corresponds to the achieved value. Examples include percentages (%) or dollars ($)'
  ),
});

export const thirdStepMessages = (t: I18next) => ({
  price: t(
    'Enter the solution price. Enter the minimum budget price for the service you are offering. This price is the base amount necessary to begin the project.'
  ),
  currency: t('Enter your price. Up to 16 symbols'),
  payment_type: t(
    'Select the appropriate payment types. This is crucial for different stages and situations.'
  ),
  countries: t(
    'Please enter the countries where you plan to offer your services. This helps us tailor and display your offerings to clients in relevant regions, expanding your market reach'
  ),
});

const stepSchema = (t: I18next) =>
  yup.object().shape({
    step: yup.number().required(t('Fill in the field')),
    title: yup.string().required(t('Fill in the field')),
    value: yup
      .string()
      .max(64, t('Value must be at most 64 characters\n'))
      .required(t('Fill in the field')),
  });

const goalSchema = (t: I18next) =>
  yup.object().shape({
    name: yup.string().required(t('Fill in the field')),
    id: yup.number(),
  });

const fileValidation = (t: I18next) =>
  yup
    .mixed()
    .test(
      'fileSize',
      t('File size exceeds 10 MB'),
      (value: any) => value?.size <= 10 * 1024 * 1024
    )
    .test(
      'fileName',
      t('Select file'),
      (value: any) => value?.name?.length > 0
    );

const platformSchema = (t: I18next) =>
  yup.object().shape({
    id: yup.number().required(t('Fill in the field')),
  });

const kpiItemSchema = (t: I18next) =>
  yup.object().shape({
    title: yup.string(),
    name: yup.string().required(kpiMessages(t).name),
    value: yup.string().required(t('Enter achieved value')),
    valueType: yup.string().required(t('Select value type')),
    unit: yup.string().required(t('Select measurement unit')),
  });

const caseSchema = (t: I18next) =>
  yup.object().shape({
    caseName: yup.string().required(addCaseMessages(t).case_name),
    caseBusinessType: yup.string().required(addCaseMessages(t).business_types),
    platforms: yup
      .array()
      .of(platformSchema(t))
      .min(1, addCaseMessages(t).platforms)
      .required(addCaseMessages(t).platforms),
    kpi: yup.array().of(kpiItemSchema(t)).min(1, t('Please, add KPI data')),
  });

export const firstStepSchema = (t: I18next) =>
  yup.object().shape({
    name: yup.string().required(firstStepMessages(t).solution_name),
    productType: yup
      .number()
      .required(firstStepMessages(t).product_type)
      .notOneOf([0], firstStepMessages(t).product_type),
      crmProductType: yup
      .number()
      .when([], {
        is: () => {
          const partnerLevel = userStore?.partners?.results?.[0]?.level;
          return partnerLevel === 'INTERNAL' && RUSSIAN_LOCALE;
        },
        then: (schema) =>
          schema
            .required(firstStepMessages(t).crmProduct_type_error)
            .notOneOf([0], firstStepMessages(t).crmProduct_type_error),
        otherwise: (schema) => schema.notRequired(),
      }),
    serviceTypes: yup
      .array()
      .min(1, firstStepMessages(t).service_types)
      .required(firstStepMessages(t).service_types),
    industry: yup.array().required(firstStepMessages(t).industry),
    companySize: yup
      .array()
      .min(1, firstStepMessages(t).company_sizes)
      .required(firstStepMessages(t).company_sizes),
    worksFor: yup
      .string()
      .required(firstStepMessages(t).works_for)
      .test(
        'not-empty-html',
        t(firstStepMessages(t).works_for),
        (value) => value !== '<p></p>'
      ),
    goals: yup
      .array()
      .of(goalSchema(t))
      .min(1, firstStepMessages(t).goals)
      .required(firstStepMessages(t).goals),
    shortDescription: yup
      .string()
      .required(firstStepMessages(t).short_description),
    fullDescription: yup
      .string()
      .required(firstStepMessages(t).full_description)
      .test(
        'not-empty-html',
        t(firstStepMessages(t).full_description),
        (value) => value !== '<p></p>'
      ),
    projectDuration: yup
      .string()
      .required(firstStepMessages(t).project_duration),
    ['useCases-2']: fileValidation(t).required(t('Select file')),
  });

export const secondStepSchema = (t: I18next) =>
  yup.object().shape({
    cases: yup.array().of(caseSchema(t)),
  });

export const thirdStepSchema = (t: I18next) =>
  yup.object().shape({
    price: yup.number().when('is_free', {
      is: false,
      then: (schema) =>
        schema
          .min(1, thirdStepMessages(t).price)
          .required(thirdStepMessages(t).price),
      otherwise: (schema) => schema.notRequired(),
    }),
    currency: yup.string().when('is_free', {
      is: false,
      then: (schema) => schema.required(t('Fill in the field')),
      otherwise: (schema) => schema.notRequired(),
    }),
    is_free: yup.boolean(),
    countries: yup
      .array()
      .min(1, thirdStepMessages(t).countries)
      .required(thirdStepMessages(t).countries),
    payment_type: yup
      .array()
      .min(1, thirdStepMessages(t).payment_type)
      .required(thirdStepMessages(t).payment_type),
  });
