import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'contexts/AuthContext';
import { observer } from 'mobx-react-lite';
import { CookieNotice } from './CookieNotice';
import { useCookies } from 'react-cookie';
import { ErrorPageContainer } from './ErrorPageContainer';
import { useTranslation } from 'react-i18next';
import { RUSSIAN_LOCALE } from '../../utils';

export const RedirectAuth = observer(() => {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { isServerError, isAuth, setServerError } = authStore || {};
  const [cookies] = useCookies(['cookie_consent']);
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuth) {
      if (location.pathname === '/kid') {
        const params = new URLSearchParams(location.search);
        if (params.get('token')) {
          setServerError(false);
          return;
        }
      }

      const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
      if (redirectAfterLogin && RUSSIAN_LOCALE) {
        navigate(redirectAfterLogin, { replace: true });
        setTimeout(() => {
          localStorage.removeItem('redirectAfterLogin');
        }, 1000);
      } else if (process.env.REACT_APP_LOGIN_SUCCESS_URL) {
        navigate(`${process.env.REACT_APP_LOGIN_SUCCESS_URL}`, {
          replace: true,
        });
      }
      setServerError(false);
    }
  }, [isAuth]);

  return (
    <>
      {isServerError && !isAuth ? (
        <ErrorPageContainer title={t('Error 500')} isNotAuth />
      ) : (
        <Outlet />
      )}

      {!isServerError && !cookies.cookie_consent && <CookieNotice />}
    </>
  );
});
